<!-- eslint-disable no-useless-escape -->
<!-- eslint-disable no-unused-vars -->
<script setup>

import { onSnapshot, collection, query, deleteDoc, where, updateDoc, doc } from 'firebase/firestore';
import { getAuth, signOut, updatePassword, updateProfile, deleteUser } from '@firebase/auth';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { useHead } from '@unhead/vue'
import { db } from '@/configs.js';
import bcryptjs from 'bcryptjs';
import { useRouter } from 'vue-router';
import userSubscriptionStatusVue from './userSubscriptionStatus.vue';

const auth = getAuth();
const router = useRouter();

const users = ref([]);
const password = ref("");
const wrongPassword = ref(false);
const currentUser = ref({});
const isTopRowEditable = ref(false);
const isRowEditable = ref(false);
const particularsChangeSuccessful = ref(false);
const passwordChangeSuccessful = ref(false);
const errorParticularsChange = ref(false);
const emptyEditFields = ref(false);
const requestDeleteAccount = ref(false);

useHead({
    link: [
        {
            rel: "canonical",
            href: "https://siftee.io/settings",
        },
    ],
});

const onMountedUserParticulars = ref({
    name: "",
    email: "",
    phoneNumber: "",
    company: "",
    password: "",
});

const liveUpdateUserParticulars = ref({
    name: "",
    email: "",
    phoneNumber: "",
    company: "",
    password: "",
});

const getCurrentUserData = (userID) => {
    return users.value.find((u) => u.userID === userID);
};

const toggleTopRowEdit = () => {
    isTopRowEditable.value = !isTopRowEditable.value;
};

const toggleRowEdit = () => {
    isRowEditable.value = !isRowEditable.value;
};

const cancelParticularsEdit = () => {
    isTopRowEditable.value = !isTopRowEditable.value;
    // Deep copy of original particulars
    liveUpdateUserParticulars.value = { ...onMountedUserParticulars.value };
};

const cancelPasswordEdit = () => {
    isRowEditable.value = !isRowEditable.value;
};

const updateParticulars = () => {
    isTopRowEditable.value = false;
    if (liveUpdateUserParticulars.value.name == "") {
        emptyEditFields.value = true;
    } else {
        const currentUser = auth.currentUser;
        // specific user's document in firestore
        const userID = users.value.find((us) => us.userID == currentUser.uid).id;
        console.log(userID);
        const docRef = doc(db, "oceanUsers", userID);

        let dataToChange = {
            displayName: liveUpdateUserParticulars.value.name,
        };
        updateProfile(currentUser, dataToChange)
            .then(() => {
                console.log("Particulars changed successfully");
            })
            .then(() => {
                dataToChange = {
                    username: liveUpdateUserParticulars.value.name,
                    phoneNumber: liveUpdateUserParticulars.value.phoneNumber,
                    company: liveUpdateUserParticulars.value.company,
                };
                updateDoc(docRef, dataToChange)
                    .then(() => {
                        console.log("Successfully updated name in Firestore");
                        emptyEditFields.value = false;
                        particularsChangeSuccessful.value = true;
                        setTimeout(() => {
                            particularsChangeSuccessful.value = false;
                        }, 2000);
                    })
                    .catch((err) => {
                        console.log(err);
                        console.log("Error updating Firestore database");
                        errorParticularsChange.value = true;
                        setTimeout(() => {
                            errorParticularsChange.value = false;
                        }, 2000);
                    });
            })
            .catch((error) => {
                console.log(error);
                particularsChangeSuccessful.value = false;
            });
    }
};

const deleteAccount = async () => {
    const currentUser = auth.currentUser;

    const isPasswordSame = await bcryptjs.compare(
        password.value,
        users.value.find((u) => u.userID == currentUser.uid).password
    );
    if (isPasswordSame) {
        wrongPassword.value = false;
        deleteUser(currentUser)
            .then(() => {
                console.log(currentUser + "has been deleted.");
            })
            .then(async () => {
                const userID = users.value.find(
                    (us) => us.userID == currentUser.uid
                ).id;
                const docRef = doc(db, "oceanUsers", userID);
                // delete document from database for ocean users
                deleteDoc(docRef)
                    .then(() => {
                        console.log("User has been removed succesfully.");
                        router.go("/");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
                console.log("Password doesn't match.");
            });
    } else {
        wrongPassword.value = true;
    }
};

const toggleDeleteAccount = () => {
    requestDeleteAccount.value = !requestDeleteAccount.value;
};

const isSifteeSubscriber = () => {
    return currentUser.value.planRegistered != 'Free';
}

const isVendorSubscriber = () => {
    return currentUser.value.subscriptionPlan != [] && currentUser.value.subscriptionPlan != null;
}

const checkPremiumStatus = () => {
    // 4 cases:
    // 0. Subscribed to None
    // 1. Subscribed to Siftee
    // 2. Subscribed to Premium Vendors
    // 3. Subscribed to both Siftee + Premium Vendors
    if (isSifteeSubscriber() && isVendorSubscriber()) {
        return 3;
    } else if (isSifteeSubscriber() && !isVendorSubscriber()) {
        return 1;
    } else if (!isSifteeSubscriber() && isVendorSubscriber()) {
        return 2;
    } else {
        return 0
    }
}

const setNewPassword = () => {
    updatePassword(auth.currentUser, liveUpdateUserParticulars.value.password)
        .then(() => {
            console.log("Successfully changed password.");
            passwordChangeSuccessful.value = true;
            isRowEditable.value = !isRowEditable.value;
            setTimeout(() => {
                passwordChangeSuccessful.value = false;
            }, 2000);
            // trigger a prompt to user to indicate password has been changed
        })
        .catch((error) => {
            console.log("Error changing password. Please try again.");
            passwordChangeSuccessful.value = false;
            isRowEditable.value = !isRowEditable.value;
        });
};

onMounted(async () => {
    const userQuery = query(collection(db, "oceanUsers"));
    const liveUsers = onSnapshot(userQuery, (snapshot) => {
        users.value = snapshot.docs.map((doc) => {
            const docID = { id: doc.id };
            const docData = doc.data();
            const data = { ...docData, ...docID };
            return data;
        });
        onUnmounted(liveUsers);
    });
});

watch(users, () => {
    currentUser.value = getCurrentUserData(auth.currentUser.uid);
    liveUpdateUserParticulars.value.name = onMountedUserParticulars.value.name = currentUser.value.username;
    liveUpdateUserParticulars.value.email = onMountedUserParticulars.value.email = currentUser.value.emailRef;
    liveUpdateUserParticulars.value.company = onMountedUserParticulars.value.company = currentUser.value.company ? currentUser.value.company : "None";
    liveUpdateUserParticulars.value.phoneNumber = onMountedUserParticulars.value.phoneNumber = currentUser.value.phoneNumber ? currentUser.value.phoneNumber : "None";
});

// Sign out
const handleSignOut = () => {
    signOut(auth).then(() => {
        this.$router.push("/Login");
    });
};
</script>

<template>
    <div class="profile d-flex">
        <div class="profile-left d-flex">
            <div class="profile-img-wrapper">
                <img v-if="currentUser?.photoURL != null" :src="String(currentUser?.photoURL)" class="profile-img" />
                <img v-else src="../assets/defaultUser.jpg" class="profile-img" />
            </div>

            <div class="profile-log-out-wrapper">
                <button class="wt mh button profileBtn logOut" @click="handleSignOut(), go('/Login')">
                    Log Out <i class="fa fa-sign-out" style="margin-left: 10px"></i>
                </button>
            </div>
        </div>

        <div class="profile-right d-flex">
            <div class="profile-card card">
                <div class="profile-card-top-row profile-card-row d-flex">
                    <p class="qs profile-card-para">Your Data Form</p>
                    <div class="profile-card-edit d-flex">
                        <button @click="toggleTopRowEdit">Edit</button>
                        <i class="fas fa-edit"></i>
                    </div>
                </div>

                <div class="profile-card-row d-flex justify-space-between">
                    <div class="profile-card-input-field d-flex">
                        <label class="it" for="name"> Name </label>
                        <input id="name" type="text" v-model="liveUpdateUserParticulars.name"
                            :disabled="!isTopRowEditable" />
                    </div>
                    <div class="profile-card-input-field d-flex">
                        <label class="it" for="email"> Email </label>
                        <input id="email" type="email" v-model="liveUpdateUserParticulars.email" disabled />
                    </div>
                </div>

                <div class="profile-card-row d-flex justify-space-between">
                    <div class="profile-card-input-field d-flex">
                        <label class="it" for="phoneNumber"> Phone Number </label>
                        <input id="phoneNumber" type="tel" v-model="liveUpdateUserParticulars.phoneNumber"
                            :disabled="!isTopRowEditable" />
                    </div>
                    <div class="profile-card-input-field d-flex">
                        <label class="it" for="company"> Company </label>
                        <input id="company" type="text" v-model="liveUpdateUserParticulars.company"
                            :disabled="!isTopRowEditable" />
                    </div>
                </div>

                <div class="profile-card-row d-flex btn-row" v-if="isTopRowEditable">
                    <button class="button profileBtn cancelBtn" @click="cancelParticularsEdit">
                        Cancel
                    </button>
                    <button class="button profileBtn saveBtn" @click="updateParticulars">
                        Save
                    </button>
                </div>

                <p class="profile-card-para" v-if="emptyEditFields">
                    Name/Email fields cannot be empty. Please edit and try again.
                </p>
                <p class="profile-card-para" v-if="particularsChangeSuccessful">
                    Particulars have been successfully updated.
                </p>

                <p class="profile-card-para" v-if="errorParticularsChange">
                    An error has occurred while changing your particulars. Please try
                    again.
                </p>
            </div>

            <div class="profile-card card">
                <div class="profile-card-top-row profile-card-row d-flex justify-space-between">
                    <p class="qs profile-card-para">Password</p>
                    <div class="profile-card-edit d-flex">
                        <button @click="toggleRowEdit">Edit</button>
                        <i class="fas fa-edit"></i>
                    </div>
                </div>

                <div class="profile-card-row d-flex">
                    <div class="profile-card-input-field d-flex">
                        <label class="it" for="password"> Set new password </label>
                        <input id="password" type="password" v-model="liveUpdateUserParticulars.password"
                            :disabled="!isRowEditable" />
                    </div>
                    <p v-if="passwordChangeSuccessful">Successfully changed password.</p>
                    <div class="profile-card-row d-flex btn-row" v-if="isRowEditable">
                        <button class="button profileBtn cancelBtn" @click="cancelPasswordEdit">
                            Cancel
                        </button>
                        <button class="button profileBtn saveBtn" @click="setNewPassword">
                            Save
                        </button>
                    </div>
                </div>
            </div>

                <div class="profile-card card">
                    <div class="profile-card-top-row profile-card-row d-flex">
                        <p class="qs profile-card-para">My Subscription</p>
                    </div>

                    <p class="subscription-para">Information</p>

                    <div class="profile-card-row profile-subscription d-flex justify-center">
                        <img src="../assets/logos/subscription.png" />
                        <div v-if="checkPremiumStatus()">
                            <userSubscriptionStatusVue :type="checkPremiumStatus()" />
                        </div>
                        <div v-else>
                            <h2 class="profile-sub-heading qs"> You are currently not subscribed to Siftee </h2>
                            <p class="profile-card-para" style="color: #4F4F4F; font-weight: 400;"> Enjoy up to 10 free searches
                                for our content </p>
                    </div>
                    </div>

                    <div class="profile-card-row d-flex last" v-if="isSifteeSubscriber() || isVendorSubscriber()">
                        <a href="https://billing.stripe.com/p/login/3csbL50NR3gcfyo9AA" target="_blank"
                            style="text-decoration: none">
                            <button class="button profileBtn manageSubscriptionBtn">
                                Manage subscription
                            </button>
                        </a>
                    </div>
                </div>

                <div class="profile-card card account-deletion">
                    <div class="delete-wrapper" v-if="requestDeleteAccount">
                        <div class="delete-account-prompt prompt d-flex">
                            <div class="caution-wrapper d-flex">
                                <svg class="caution-svg" xmlns="http://www.w3.org/2000/svg" width="48" height="46"
                                    viewBox="0 0 48 46" fill="none">
                                    <path d="M25.5 15.8125H22.5V31.625H25.5V15.8125Z" fill="white" />
                                    <path d="M25.5 34.5H22.5V37.375H25.5V34.5Z" fill="white" />
                                    <path
                                        d="M25.6888 1.4375H22.3112L1.5 39.9961V44.5625H46.5V39.9961L25.6888 1.4375ZM43.5 41.6875H4.5V40.6955L24 4.5664L43.5 40.6955V41.6875Z"
                                        fill="white" />
                                </svg>
                                <div>
                                    <p class="prompt-para os">
                                        Caution - this will delete your account. This action can not
                                        be undone.
                                    </p>
                                    <p class="prompt-para os">Enter your password to continue</p>
                                </div>

                                <div class="caution-wrapper-last" @click="toggleDeleteAccount">
                                    <i class="fa-solid fa-x"></i>
                                </div>
                            </div>

                            <div class="password-input">
                                <input class="password-input-field" type="password" v-model="password" />
                            </div>
                            <p class="prompt-para text-center" v-if="wrongPassword">
                                Wrong password entered. Please try again.
                            </p>
                            <button class="button deleteBtn" @click="deleteAccount">
                                Delete Account
                            </button>
                        </div>
                    </div>

                    <div class="profile-card-top-row profile-card-row d-flex">
                        <p class="qs profile-card-para">Account Deletion</p>
                    </div>

                    <div class="profile-card-row d-flex">
                        <p class="qs" style="margin-top: 10px">
                            Delete your account from Siftee entirely. Note: this does not cancel
                            any subscription
                        </p>
                    </div>

                    <div class="profile-card-row d-flex last">
                        <button class="button profileBtn deleteBtn" @click="toggleDeleteAccount">
                            Delete my account
                        </button>
                    </div>
                </div>
            </div>
        </div>
</template>
