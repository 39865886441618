import { createApp } from "vue";
import { createHead } from "@unhead/vue";
import "./gtag.js";
import { createRouter, createWebHistory } from "vue-router";

import './assets/styles/userSettings.css'
import './assets/styles/headerControl.css'
import './assets/styles/datasetsSearch.css'
import './assets/styles/pricingPlans.css'
import './assets/styles/loginPage.css'
import './assets/styles/indexPage.css'
import './assets/styles/datasetView.css'
import './assets/styles/contactUs.css'
import './assets/styles/paymentSuccess.css'
import './assets/styles/shared.css'
import Aos from "aos";
Aos.init();

import VueSplide from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

import { initializeApp } from "firebase/app";
import store from "./store.js";
import { inject } from "@vercel/analytics";

inject();

const firebaseConfig = {
  apiKey: "AIzaSyD8kn26Sj-wvYhB6h-vALgxWoZ2UyujHyI",
  authDomain: "hana-firebase-auth.firebaseapp.com",
  projectId: "hana-firebase-auth",
  storageBucket: "hana-firebase-auth.appspot.com",
  messagingSenderId: "257456893215",
  appId: "1:257456893215:web:7da412256ffcbfeb62142a",
  measurementId: "G-VNEDYMGDTL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

import App from "./App";
import indexPage from "./components/indexPage";
import signUp from "./authentication/signUp.vue";
import loginPage from "./authentication/loginPage";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import reclaimPassword from './authentication/reclaimPassword.vue'
import userSettings from './components/userSettings.vue'
import userProfile from './subComponent/userProfile.vue'
import datasetView from './subComponent/datasetView.vue'
import pricing from './subComponent/pricingPlans.vue'
// import datasetsSearch from './components/datasetsSearch.vue'
import configurationView from './subComponent/configurationView.vue'
import contactUs from './subComponent/contactUs.vue'
import checkoutPage from './subComponent/cartPage.vue'
import paymentSuccess from './subComponent/paymentSuccess.vue'
import purchasedDatasets from './subComponent/purchasedDatasets'
import bookDemo from './subComponent/bookaDemo.vue'
import privacyPolicy from './subComponent/privacyPolicy.vue'
import referredPricingPlans from './subComponent/referredPricingPlans.vue'

// Every import below here should not be pushed to main under any circumstance. They are kept as admin pages to be used on local host
import ais from "./adminPages/aisTemplate.vue";
// import bpGenerator from './adminPages/boilerPlateGenerator.vue'
// import oceanProject from './adminPages/oceanProject.vue'
// import gptBoilerPlate from './adminPages/gptBoilerPlate.vue'
// import managementConsole from './adminPages/managementConsole.vue'
// import dataUpload from './adminPages/dataUpload.vue'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    //General Paths
    {
      path: "/",
      name: "Index",
      component: indexPage,
      meta: { canonical: "https://siftee.io/" },
    },
    {
      path: "/Pricing",
      component: pricing,
      meta: { canonical: "https://siftee.io/pricing" },
    },
    { path: "/Contact", component: contactUs },
    {
      path: "/referred_plans/:referral",
      component: referredPricingPlans,
      props: true,
      meta: { requiresAuth: true },
    },
    {
      path: "/BookADemo",
      component: bookDemo,
      meta: { canonical: "https://siftee.io/bookademo" },
    },
    {
      path: "/PrivacyPolicy",
      component: privacyPolicy,
      meta: { canonical: "https://siftee.io/privacypolicy" },
    },
    { path: "/MyDatasets", component: purchasedDatasets },

    //Auth Paths
    {
      path: "/Register/:referralID?",
      name: "l",
      component: signUp,
      props: true,
      meta: { canonical: "https://siftee.io/register" },
    },
    {
      path: "/Login/:referralID?",
      name: "Login",
      component: loginPage,
      props: true,
      meta: { canonical: "https://siftee.io/login" },
    },
    {
      path: "/ForgotPassword",
      name: "reclaimPW",
      component: reclaimPassword,
      meta: { canonical: "https://siftee.io/forgotpassword" },
    },
    {
      path: "/settings",
      name: "settings",
      component: userSettings,
      props: true,
      meta: { requiresAuth: true, canonical: "https://siftee.io/settings" },
    },
    {
      path: "/settings/profile",
      name: "profile",
      component: userProfile,
      meta: { canonical: "https://siftee.io/settings/profile" },
    },

    // oceanDatasets -> pulling from GCB
    // {
    //   path: "/Search/:searchQuery?",
    //   component: datasetsSearch,
    //   meta: { requiresAuth: true, canonical: "https://siftee.io/search" },
    //   props: true,
    //   name: "Search",
    // },

    // Table preview page
    {
      path: "/:datasetCompany/:datasetID/",
      component: datasetView,
      props: true,
      meta: { canonical: "https://siftee.io/dataset" },
    },
    // Table configure page
    {
      path: "/:datasetCompany/:datasetID/Configure",
      component: configurationView,
      props: true,
      meta: {
        requiresAuth: true,
        canonical: "https://siftee.io/configuration",
      },
    },

    // Stripe pages
    { path: "/Checkout", component: checkoutPage },
    { path: "/payment_success", component: paymentSuccess },
   
    //Every path below here should not be pushed to main under any circumstance. They are kept as admin pages to be used on local host
    // {path: "/BoilerPlateGenerator", component: bpGenerator},
    // {path: "/GCP", component: gcpAdapt},
    { path: "/AIS", component: ais }, //Important! utilize this path to index content from GCS
    // { path: "/GPTBP", component: gptBoilerPlate, meta: { canonical: 'https://siftee.io/gptbp' } },
    // { path: "/Management_Console", component: managementConsole, meta: { requiresAuth: true } },
    // {path: "/Ocean", component: oceanProject},
    // { path: "/DataUpload", component: dataUpload },
  ],
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  const isCurrentUser = await getCurrentUser();

  // Check if the user is trying to access the Login or Register page
  if (to.path === "/Login" || to.path === "/Register") {
    if (isCurrentUser) {
      // Redirect authenticated users trying to access Login or Register
      next("/");
    } else {
      // Allow unauthenticated users to access Login and Register
      next();
    }
  } else {
    // For other routes with requiresAuth meta
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (isCurrentUser) {
        next();
      } else {
        next("/Login");
      }
    } else {
      // For routes without requiresAuth meta
      next();
    }
  }
});

const head = createHead();

createApp(App).use(store).use(head).use(router).use(VueSplide).mount("#app");
export { app };
