<script setup>
import { useHead, useSeoMeta } from "@unhead/vue";
useHead({
  link: [
    {
      rel: "canonical",
      href: "https://siftee.io/PrivacyPolicy",
    },
  ],
});

useSeoMeta({
  title: "Privacy Policy | Siftee | Find Data Faster",
  description:
    "Read Siftee's Privacy Policy to learn how we handle and protect your data. Understand our commitment to privacy and data security. Trust Siftee to keep your information safe.",
  ogDescription:
    "Read Siftee's Privacy Policy to learn how we handle and protect your data. Understand our commitment to privacy and data security. Trust Siftee to keep your information safe.",
  ogTitle: "Privacy Policy | Siftee | Find Data Faster",
  ogImage: window.location.href + "img/sifteeDarkMobile.c964311a.png",
});
</script>

<template>
  <div
    style="width: 80%; margin-left: auto; margin-right: auto; line-height: 1"
  >
    <p>
      At Siftee Pte Ltd ("Siftee.io," "we," "our," or "us"), we are committed to
      protecting your privacy and ensuring the security of your personal
      information.
    </p>
    <br />
    <p>
      This Privacy Policy outlines how we collect, use, disclose, and protect
      the information we gather from users of our website, siftee.io.
    </p>
    <br />
    <p>
      By accessing or using our website, you consent to the practices described
      in this Privacy Policy.
    </p>
    <br />
    <p>
      Please read this document carefully to understand our policies and
      practices regarding your personal data.
    </p>
    <br />
    <p class="bold-line">1. Information We Collect</p>
    <br />
    <p class="bold-line">1.1 Personal Information:</p>
    <br />
    <p>
      We may collect certain personally identifiable information (PII) that you
      provide voluntarily when you interact with our website or services. This
      information may include but is not limited to your name, email address,
      postal address, phone number, and other details you submit through forms
      or registration processes.
    </p>
    <br />
    <p class="bold-line">1.2 Usage Data:</p>
    <br />
    <p>
      We may also collect non-personally identifiable information regarding your
      interactions with our website. This data may include your IP address,
      browser type, operating system, pages visited, and other usage statistics.
    </p>
    <br />
    <p class="bold-line">2. Use of Information</p>
    <br />
    <p class="bold-line">2.1 Providing and Improving Services:</p>
    <br />
    <p>
      We use the information we collect to provide, maintain, and improve our
      website, products, and services. This includes responding to your
      inquiries, fulfilling your requests, and enhancing the user experience.
    </p>
    <br />
    <p class="bold-line">2.2 Communication:</p>
    <br />
    <p>
      We may use your contact information to send you updates, newsletters,
      promotional materials, or information about our services. You can opt-out
      of receiving such communications at any time by following the instructions
      provided in the communication.
    </p>
    <br />
    <p class="bold-line">2.3 Legal Obligations:</p>
    <br />
    <p>
      We may process your personal information to comply with applicable laws,
      regulations, or legal processes.
    </p>
    <br />
    <p class="bold-line">3. Disclosure of Information</p>
    <br />
    <p class="bold-line">3.1 Third-Party Service Providers:</p>
    <br />
    <p>
      We may share your information with third-party service providers who
      assist us in operating our website, conducting our business, or providing
      services to you. These service providers will have access to your
      information only to the extent necessary to perform their functions and
      are obligated to maintain the confidentiality and security of your data.
    </p>
    <br />
    <p class="bold-line">3.2 Business Transfers:</p>
    <br />
    <p>
      In the event of a merger, acquisition, or sale of all or a portion of our
      assets, your personal information may be transferred to the acquiring
      entity.
    </p>
    <br />
    <p class="bold-line">3.3 Legal Compliance:</p>
    <br />
    <p>
      We may disclose your information to comply with legal obligations, enforce
      our policies, respond to claims, or protect the rights, property, or
      safety of Siftee Pte Ltd, our users, or others.
    </p>
    <br />
    <p class="bold-line">4. Cookies and Similar Technologies.</p>
    <br />
    <p>
      We use cookies and similar technologies to enhance your experience on our
      website. Cookies are small files stored on your device that enable us to
      recognize your browser and capture certain information. You can manage
      your cookie preferences through your browser settings.
    </p>
    <br />
    <p class="bold-line">5. Data Security</p>
    <br />
    <p>
      We implement reasonable security measures to protect your personal
      information from unauthorized access, alteration, disclosure, or
      destruction. However, please note that no method of transmission over the
      internet or electronic storage is completely secure, and we cannot
      guarantee absolute security.
    </p>
    <br />
    <p class="bold-line">6. Your Rights</p>
    <br />
    <p>
      You have the right to access, update, or delete your personal information
      held by us. If you wish to exercise any of these rights or have any
      concerns regarding your privacy, please contact us at
      <a href="mailto:info@siftee.io">info@siftee.io</a>
    </p>
    <br />
    <p class="bold-line">7. Children's Privacy</p>
    <br />
    <p>
      Our website is not intended for use by individuals under the age of 16. We
      do not knowingly collect personal information from children without
      parental consent. If you believe we have inadvertently collected
      information from a child, please contact us to have it removed.
    </p>
    <br />
    <p class="bold-line">8. Changes to the Privacy Policy</p>
    <br />
    <p>
      We reserve the right to update this Privacy Policy from time to time. Any
      changes will be posted on this page with the effective date. Please review
      this Privacy Policy periodically to stay informed about how we are
      protecting your information.
    </p>
    <br />
    <p class="bold-line">9. Contact Information</p>
    <br />
    <p>
      If you have any questions, concerns, or requests regarding this Privacy
      Policy or our data practices, you can contact us at:
    </p>
    <br />
    <p>
      Siftee Pte Ltd<br />Address: 128 Prinsep St, #01-01, Singapore 188655<br />Email:
      <a href="mailto:info@siftee.io">info@siftee.io</a>
    </p>
    <br />
    <p>
      By using our website, you agree to the terms of this Privacy Policy. If
      you do not agree with the practices described in this policy, please do
      not use our website or services.
    </p>
  </div>
</template>

<style>
.bold-line {
  font-weight: bold;
}
</style>
