import { createStore } from 'vuex';

export default createStore({
  modules: {
    storeModuleName: {  
      namespaced: true, // enable namespacing
      state: {
        retrievalSuccess: false,
        searchQueryStore: '',
        searchQueryURLHash:'',
        scrollPositionStore: null,
        dataSourceMainBucketStore: null,
        dataSourceTableBucketStore: null,
        dataDetailsFirebaseStore: null,
        cartStore: null
      },
      mutations: {
        // setRetrievalSuccess(state, value) {
        //   state.retrievalSuccess = value;
          
        // },
        setSearchQuery(state, value) {
          state.searchQueryStore = value;
        },
        setScrollPosition(state, value) {
          state.scrollPositionStore = value;
        },
        setdataSourceMainBucket(state, value) {
          state.dataSourceMainBucketStore = value;
        },
        setdataSourceTableBucket(state, value) {        
          state.dataSourceTableBucketStore = value;
        },
        setdataDetailsFirebase(state, value) {
          state.dataDetailsFirebaseStore = value;
        },
        setsearchQueryURLHash(state, value) {
          state.searchQueryURLHash = value;
        },

        setCartStore(state, value) {
          state.cartStore = value
        }
      },
    },
  },
});