<script setup>

</script>

<template>
    <div class="success-container">
        <img src="../assets/banners/payment_success.png" class="success-banner"/>
        <div class="success-inner-container">
            <p class="success-para qs"> Your Payment Is Successful! </p>
            <button class="button backHome"> 
                <router-link to="/">
                    Back To Home 
                </router-link>
            </button>
        </div>
    </div>
 </template>