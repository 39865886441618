import { initializeApp } from "firebase/app";
import api from './api';
import { getFirestore } from "firebase/firestore";
import bcryptjs from 'bcryptjs';



export const firebaseConfig = {
  apiKey: "AIzaSyD8kn26Sj-wvYhB6h-vALgxWoZ2UyujHyI",
  authDomain: "hana-firebase-auth.firebaseapp.com",
  projectId: "hana-firebase-auth",
  storageBucket: "hana-firebase-auth.appspot.com",
  messagingSenderId: "257456893215",
  appId: "1:257456893215:web:7da412256ffcbfeb62142a",
  measurementId: "G-VNEDYMGDTL"
}


export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export function checkEmail(input) {
  const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  if (!regex.test(input) === true) {
    return false;
  }
  else {
    return true;

  }

}



export function checkNRIC(input) {
  const regex = /^[STFG]\d{7}[A-Z]$/;
  if (!regex.test(input) === true) {
    return false
  }
  else {
    return true
  }
}
export function checkPassword(input) {
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,31}$/;
  if (!regex.test(input) === true) {
    return false;
  }
  else {
    return true;

  }
}


export function checkMatch(input, confirmInput) {
  if (input.trim() != confirmInput.trim()) {
    return false;
  }
  else {
    return true
  }
}

// must pass in a vue ref
export async function hashPasswordWithRef(password) {
  const saltRounds = 4;
  return await bcryptjs.hash(password.value, saltRounds);
}

export async function hashPassword(password) {
  const saltRounds = 4;
  return await bcryptjs.hash(password, saltRounds);
}


export function rQuotes(input) {
  // regex will read string with the quotes, use this to remove them, works for ref const specifically... to alter for others, remove .value
  return input.value.replace(/['"]+/g, '')
}

//both functions below are for converting epoch time to readable human datetime format
export function timestampFormat(timestamp) { //returns yyyy-mm-dd hh:mm:ss
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  // const hours = date.getHours();
  // const minutes = date.getMinutes();
  // const seconds = date.getSeconds();

  return `${year}-${month}-${day}`;
}

export function timestampDateOnly(timestamp) { //returns yyyy-mm-dd
  const date = new Date(timestamp);
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return date.toLocaleDateString('en-GB', options);
}


export function getAge (timestamp) { //receive epoch time as parameter, return difference in years

  const timeDifference = Date.now() - timestamp;
  const seconds = timeDifference / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  return days/365
}

export function returnUSName(usID) {
  for (let i = 0 ; i < this.users.length; i++) {
    if (this.users[i].userID === usID) {
      return this.users[i].username
    }
  }
}

export function epochToHumanReadableDate(epoch) {
  // Create a new Date object with the epoch timestamp in milliseconds
  const date = new Date(epoch * 1000);

  // Get the individual date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  // Construct the human-readable date string
  const humanReadableDate = `${year}/${month}/${day}`;

  return humanReadableDate;
}



export const calculateAverage = data => 
  (data?.reduce((acc, {uScore}) => uScore ? acc + Number(uScore) : acc, 0) / data?.length || 0).toFixed(0);

export function calculateAge(dateString) {
  const birthDate = new Date(dateString);
  const currentDate = new Date();
  
  let age = currentDate.getFullYear() - birthDate.getFullYear();
  
  const monthDifference = currentDate.getMonth() - birthDate.getMonth();
  
  if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birthDate.getDate())) {
    age--;
  }
  
  return age;
}

export function go(val) {
  this.$router.push({ path: val })
}

export function truncateString(str) {
  if (str.length > 200) {
    return str.substring(0, 200) + "...";
  }
  else {
    return str;
  }
}

export function truncateCustom(str, int) {
  if (str?.length > int) {
    return str?.substring(0, int) + "...";
  }
  else {
    return str;
  }
}

export function timeDifference (timestamp) {

  const timeDifference = Date.now() - timestamp;
  const seconds = timeDifference / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;
  const years = days/365

  if (minutes < 60) {
    return Math.round(minutes) + ' minute(s) ago';
  } else if (hours < 24) {
    return Math.round(hours) + ' hour(s) ago';
  } else if (days < 365) {
    return Math.round(days) + ' day(s) ago';
  }
  else {
    return Math.round(years) + ' year(s) ago'
  }
}

export function checkScore(score) {
  if (score >= 8) return '#01A08C';
  if (score >= 5) return '#87cc3e';
  if (score >= 3) return '#F55921';
  return '#c2191f'

}

export function strTruncate(str, val) {
  if (str.length > val) {
    return str.substring(0, val) + "...";
  }
  else {
    return str;
  }

}
export function getDuration(startDate, endDate) {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const duration = Math.abs(end - start);

  // Convert the duration to days, hours, minutes, and seconds
  const days = Math.floor(duration / (24 * 60 * 60 * 1000));

  return {
    days,
  };
}

export function countIteration(arr, toFind) {
  let iterations = 0;
  for (let i = 0 ; i < arr.length; i ++) {
    if (arr[i] === toFind) {
      iterations++;
    }
  }
  return iterations
}

export function getUniqueValues(arr) {
  return arr.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
}

export function getUniqueKeys(objects) {
  const keys = new Set();

  for (let i = 0; i < objects.length; i++) {
    const objectKeys = Object.keys(objects[i]);

    for (let j = 0; j < objectKeys.length; j++) {
      keys.add(objectKeys[j]);
    }
  }

  return Array.from(keys);
}

export function brandImg(brandString) {
  brandString = String(brandString).replaceAll(' ','').toLowerCase();
  if (brandString.includes('fairprice')) {
    return 'https://spcprd-c90a5f37d0e5057f9e41-endpoint.azureedge.net/blobspcprd5b03176540/wp-content/uploads/2023/07/ntuc-fairprice.jpg'
  }
  if (brandString.includes('foodpanda')) {
    return 'https://i.pinimg.com/originals/5f/b2/1a/5fb21aefc0aa94d8a84531611d8fe708.png'
  }
  if (brandString.includes('singapore')) {
    return 'https://www.nhb.gov.sg/-/media/nhb/images/resources/national-symbols/resources_lionhead_hires.gif'
  }
  if (brandString.includes('weathersource')) {
    return 'https://media.glassdoor.com/sql/3282238/weather-source-squarelogo-1582849236527.png'
  }
  if (brandString.includes('europeancenter')) {
    return 'https://pbs.twimg.com/profile_images/636530960402677760/wyv2yqmr_400x400.png'
  }
  if (brandString.includes('kaggle')) {
    return 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Kaggle_logo.png?20140912155123'
  }
  if (brandString.includes('worldbank')) {
    return 'https://seeklogo.com/images/W/world-bank-logo-50FE63E4E3-seeklogo.com.png'
  }
  if (brandString.includes('focuseconomics')) {
    return 'https://static.focus-economics.com/app/uploads/2022/08/logo.svg'
  }
  if (brandString.includes('ipinfo')) {
    return 'https://remoteok.com/assets/img/jobs//87f4a389593c693fe488a767237724c11630533559.png'
  }
  if (brandString.includes('sephora')) {
    return 'https://logos-world.net/wp-content/uploads/2022/02/Sephora-Logo.png'
  }
  if (brandString.includes('shipsdna')) {
    return 'https://awsmp-logos.s3.amazonaws.com/7bf40cc0-fa54-492a-aa8c-4dcc90fd11ff/5682307bf2e6b5d6c16c5b4b5b0cf3dd.png'
  }
  if (brandString.includes('apec')) {
    return 'https://pbs.twimg.com/profile_images/1382605453994053635/E_xq-iqm_400x400.jpg'
  }
  if (brandString.includes('michelin')) {
    return 'https://upload.wikimedia.org/wikipedia/commons/9/9f/Michelin_guide_logo.png'
  }
  if (brandString.includes('Organisation for Economic Co-operation and Development')) {
    return 'https://diplo-media.s3.eu-central-1.amazonaws.com/OECD_1.jpg'
  }
  if (brandString.includes('rakuten')) {
    return 'https://download.logo.wine/logo/Rakuten/Rakuten-Logo.wine.png'
  }
  if (brandString.includes('numbeo')) {
    return 'https://www.numbeo.com/images/numbeo_text_1B3B6F.svg'
  }
  else {
    return 'https://uncody.b-cdn.net/customers/64b0df7e714f4f1d9c08c7c1/siftee%20banner%20dark%20on%20white.png'
  }

}


export function inferDataType(input) {
  // Check if it's a boolean
  if (input === 'true' || input === 'false') {
    return 'boolean';
  }
  
  // Check if it's an integer
  if (/^-?\d+$/.test(input)) {
    return 'integer';
  }
  
  // Check if it's a float
  if (/^-?\d+\.\d+$/.test(input)) {
    return 'float';
  }
  
  // Check if it's a valid date
  if (!isNaN(Date.parse(input))) {
    return 'date';
  }
  
  // Default to string
  return 'string';
}


export async function downloadObjectAvoidTable(exportObj, exportName) {

  exportObj['costPerRow'] = '0.02'
  // exportObj['promptUsed'] = firstObject
  // console.log("Table: " + firstObject)
  // console.log(JSON.stringify(exportObj))

  // download section below
  var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(Object(JSON.stringify(exportObj)));
  var downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}


export async function downloadObjectAsJson(exportObj, exportName, description) {
  if (description) {
    exportObj['description'] = description
  }
  var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj, function (key, value) {
      if (key !== 'dataDictionary') {
          return value;
      }
      return value; // Return the 'dataDictionary' property as is, without any modification.
  }));

  exportName = exportName.replaceAll('_', ' ').replaceAll('-',' ').replaceAll('.json','')

  var downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode);
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}


export function fetchDataFromBackend() {
  // Assuming this is a POST request, you can send a sample payload
  const requestData = {
    product: {
      name: this.dsName,
      price: this.noRows * this.cpRow,
      quantity: 1,
      // description: `You are purchasing ${this.noRows} rows from ${this.dsName}.`,
    },
  };

  // Send the POST request to the backend endpoint
  api.post('/stripe/checkout', requestData)
  .then(response => {
      // Handle the response from the backend here
      console.log('Response from server:', response);
      console.log('Response data:', response?.data);

      this.checkoutSessionId = response?.data?.url;
      window.location.href = this.checkoutSessionId

      // You can update your Vue component's data with the response if needed
      // this.myData = response.data;
    })
    .catch(error => {
      // Handle errors here
      console.error('Error:', error.response.data);
    });
}
// redirectToStripe() {
//   window.location.href = this.checkoutSessionId
//   // window.location.href = `https://checkout.stripe.com/pay/${this.checkoutSessionId}`
// }

export function SEOAdjust(str) {
  let clean = String(str).replaceAll('-','_').replaceAll(' ','-') 
  return clean
}


export function formatDate(timestamp) {
  var date = new Date(timestamp * 1000);
  var day = ("0" + date.getDate()).slice(-2);
  var month = ("0" + (date.getMonth() + 1)).slice(-2);
  var year = date.getFullYear().toString().substr(-2);
  return day + '/' + month + '/' + year;
}