<script setup>
import { useHead, useSeoMeta } from "@unhead/vue";
useHead({
  link: [
    {
      rel: "canonical",
      href: "https://siftee.io/Pricing",
    },
  ],
});

useSeoMeta({
  title: "Pricing Plans - Find Data Faster | Siftee.io",
  description:
    "Find the right Siftee pricing plans to help you and your business to discover data more easily. Get unlimited searches.",
  ogDescription:
    "Find the right Siftee pricing plans to help you and your business to discover data more easily. Get unlimited searches.",
  ogTitle: "Pricing Plans - Find Data Faster | Siftee.io",
  ogImage: window.location.href + "img/sifteeDarkMobile.c964311a.png",
});

var usID = ref("");
var usEmail = ref("");
let auth;

const isLoggedin = ref(false);
onMounted(() => {
  auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      isLoggedin.value = true;
      usID.value = user.uid;
      usEmail.value = user.email;
    } else {
      isLoggedin.value = false;
    }
    return {
      usID,
    };
  });
});
</script>

<script>
import { onAuthStateChanged, getAuth } from "@firebase/auth";
import { ref, onMounted, onUnmounted } from "vue";
import {
  getFirestore,
  onSnapshot,
  collection,
  query,
  doc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { app } from "@/configs.js";

const db = getFirestore(app);

export default {
  name: "indexPage",
  data() {
    return {
      users: ref([]),
      pricingPlans: [
        // { id: 1, name: "Free (Not Logged In)", fieldName: "clickedSubscribe_Free", discount: "None", price: "0", filters: "A few free searches", features: ["Search", "5 Filters"] },
        // { id: 2, name: "Free (Logged In)", fieldName: "clickedSubscribe_Basic", discount: "None", price: "0", filters: "A few free searches", features: ["Search", "5 Filters", "Download Public Data"] },
        {
          id: 3,
          name: "Individual",
          fieldName: "clickedSubscribe_Individual",
          discount: "Or $289 / year at 70% off",
          price: 79.99,
          filters: "Unlimited Filters",
          promoted: true,
          features: [
            "Search",
            "Unlimited Filters",
            "Download Public Data",
            "Download Paid Data",
          ],
        },
        {
          id: 4,
          name: "Enterprise",
          fieldName: "clickedSubscribe_Enterprise",
          discount: "Or $1,800 / year at 70% off",
          price: 499.99,
          filters: "Unlimited Filters",
          features: [
            "Search",
            "Unlimited Filters",
            "Download Public Data",
            "Download Paid Data (5%  Discount)",
            "Save Views",
            "New Data Notifications",
            "API Connections",
          ],
        },
      ],
    };
  },
  methods: {
    getPlan(usid) {
      if (usid != undefined) {
        if (
          Object(this.users.find((u) => u.userID == usid)).planRegistered ==
          "Free"
        ) {
          return "Free (Logged In)";
        }
        return Object(this.users.find((u) => u.userID == usid)).planRegistered;
      } else {
        return "Free (Not Logged In)";
      }
    },
    go(val) {
      this.$router.push({ path: val });
    },
    async addSubscribeCount(type) {
      console.log(type);
      let user = this.users.find(
        (u) => u.emailRef === "sngjunhan2001@gmail.com"
      );
      if (user) {
        await updateDoc(doc(db, "oceanUsers", user.id), {
          [type]: increment(1),
        });
      }
      window.location.href = "https://oceanofdata.co/#ContactSection";
    },
  },
  mounted() {
    const userQuery = query(collection(db, "oceanUsers"));
    const liveUsers = onSnapshot(userQuery, (snapshot) => {
      this.users = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          emailRef: doc.data().emailRef,
          userID: doc.data().userID,
          username: doc.data().username,
          planRegistered: doc.data().planRegistered,
          referralKey: doc.data().referralKey,
        };
      });
    });

    onUnmounted(liveUsers);
  },
};
</script>

<template>
  <div class="tabletView">
    <div
      style="
        width: 99vw;
        height: 110vh;
        min-height: 700px;
        margin-bottom: 11vh;
        margin-top: 11vh;
        min-width: 1100px;
      "
    >
      <stripe-pricing-table
        pricing-table-id="prctbl_1Nfwv9A1UYuDWXkQfJPaaCT2"
        publishable-key="pk_live_51NfZuVA1UYuDWXkQLHgpuL8MgmXkue6nXNZR2EtXbSRVvu2fbCKzvN08Qa4WxO5G4a7RH7XxnGnZRtURvbFB0Fnd00uZqWwFOu"
      >
      </stripe-pricing-table>
    </div>
  </div>

  <div class="mobileView">
    <div style="margin-top: 70px">
      <stripe-pricing-table
        pricing-table-id="prctbl_1Nfwv9A1UYuDWXkQfJPaaCT2"
        publishable-key="pk_live_51NfZuVA1UYuDWXkQLHgpuL8MgmXkue6nXNZR2EtXbSRVvu2fbCKzvN08Qa4WxO5G4a7RH7XxnGnZRtURvbFB0Fnd00uZqWwFOu"
      >
      </stripe-pricing-table>
    </div>
  </div>

  <div style="width: 100%; height: fit-content; min-height: 10vh"></div>
</template>
