<!-- Settings page  -->
<!-- eslint-disable no-unused-vars -->
<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import {
  doc,
  updateDoc,
  increment,
  onSnapshot,
  collection,
  query,
} from "firebase/firestore";
import { useHead } from "@unhead/vue";
import { db } from "@/configs.js";
import userProfile from "@/subComponent/userProfile.vue";
import { getAuth } from "firebase/auth";
import { useRouter } from "vue-router";

const auth = getAuth();
const router = useRouter();

useHead({
  link: [
    {
      rel: "canonical",
      href: "https://siftee.io/settings",
    },
  ],
});

const settingSelected = ref(1);
const searchBarMain = ref();
const users = ref([]);

// For future, when more tabs are added to User Profile page
const updateSettingSelected = (setting) => {
  settingSelected.value = setting;
};

const searchUseIncrement = (usid) => {
  const user = Object(users.value.find((u) => u?.userID == usid));
  if (user?.searchUse <= 10) {
    updateDoc(doc(db, "oceanUsers", user?.id), {
      searchUse: increment(1),
    });
  }
};

const redirectSearch = () => {
  router.push("/Search/" + searchBarMain.value);
  searchUseIncrement(auth.currentUser.uid);
};

onMounted(async () => {
  const userQuery = query(collection(db, "oceanUsers"));
  const liveUsers = onSnapshot(userQuery, (snapshot) => {
    users.value = snapshot.docs.map((doc) => {
      const docID = { id: doc.id };
      const docData = doc.data();
      const data = { ...docData, ...docID };
      return data;
    });
  });
  onUnmounted(liveUsers);
});
</script>

<template>
  <div class="settings">
    <div style="width: 88%; margin-inline: auto; margin-top: 15px">
      <div class="search-container">
        <div style="width: 136px; height: 40px">
          <img
            src="../assets/logos/sifteeDarkMobile.png"
            style="width: 100%; height: 100%"
            draggable="false"
          />
        </div>
        <input
          id="searchBarMain"
          class="it"
          autocomplete="off"
          spellcheck="false"
          placeholder="Search For Data"
          @keyup.enter="redirectSearch()"
          v-model="searchBarMain"
        />
        <i class="fas fa-search search-icon" @click="redirectSearch()"></i>
      </div>
    </div>
    <!-- Breadcrumbs -->
    <div class="settings-container">
      <div class="settings-inner-container">
        <div class="settings-wrapper">
          <button
            class="setting"
            :class="{ 'selected-setting': settingSelected == 1 }"
            @click="updateSettingSelected(1)"
          >
            Your Profile
          </button>
        </div>

        <div v-if="settingSelected == 1">
          <userProfile />
        </div>
      </div>
    </div>
  </div>
</template>
