<template>
  <div style="width:100%;min-height:100vh;height:fit-content;display:flex;justify-content:center;flex-direction: column;">
    <div style="width:80%;margin-left: auto;margin-right: auto;height:fit-content">
      <!-- <button @click=""></button> -->
      <h1>Algolia Example : {{ dataSources.length }}</h1>
      <h4>{{ Object.keys(Object(dataSources[0])) }}</h4>
      <ul style="font-size:.8em" v-for="(i, index) in allObjectArray" :key="index">
        <li>{{index + 1 }} | {{ allObjectArray[index]?.name }}</li>
      </ul>


      <!-- <p>{{ listAllObjects() }}</p> -->
      <input v-model="searchQuery" @input="search" placeholder="Search..." style="width:100%" />

      <!-- <ul>
        <li v-for="hit in searchResults.slice(0, 5)" :key="hit.objectID">{{ hit?.content?.sourceHeader }} | {{
          hit?.content?.id }} </li>
      </ul> -->
    </div>

  </div>
</template>



<script>
import algoliasearch from 'algoliasearch';

// HIDE THE KEY BY ONLY PULLING INFORMATION FROM BACKEND '8CADECE'
const searchClient = algoliasearch('LWN0BL63VH', '9d4067ba7daabdd1b9e1eefe06213f1b');

const index = searchClient.initIndex('datasets')

export default {
  data() {
    return {
      searchQuery: '',
      searchResults: [],
      dataSources: [], // Initialize an empty array to store your fetched data
      allObjectArray: []
    };
  },
  methods: {
    async listAllObjects() {
  // let nextPageToken = "CrwBRmluYW5jaWFsIEFjY291bnQsIEZpbmFuY2lhbCBEZXJpdmF0aXZlcyAoT3RoZXIgVGhhbiBSZXNlcnZlcykgYW5kIEVtcGxveWVlIFN0b2NrIE9wdGlvbnMsIE5ldCBJbmN1cnJlbmNlIG9mIExpYWJpbGl0aWVzLCBOYXRpb25hbCBDdXJyZW5jeSBmb3IgU2FpbnQgTHVjaWEgY29sbGVjdGVkIG9uIGEgQW5udWFsIGJhc2lzLmpzb24=";
  // let nextPageToken = "CnFTb2NpYWwgQ29udHJpYnV0aW9ucyBpbiBQZXJjZW50IG9mIEdEUCBmb3IgQ29uZ28sIHRoZSBEZW1vY3JhdGljIFJlcHVibGljIG9mIHRoZSBjb2xsZWN0ZWQgb24gYSBBbm51YWwgYmFzaXMuanNvbg=="
  let nextPageToken = null
  const allObjects = [];

  do {
    const queryParams = {
      maxResults: 1000,
    };

    // Only include the pageToken parameter when it's not null
    if (nextPageToken) {
      queryParams.pageToken = nextPageToken;
    }

    const bucketName = process.env.VUE_APP_SIFTEE_DATASETS;
    const apiEndpoint = `https://www.googleapis.com/storage/v1/b/${bucketName}/o`;
    const response = await fetch(`${apiEndpoint}?${new URLSearchParams(queryParams)}`);
    const data = await response.json();
    // const filteredData = data.items.filter((item) => !item.name.includes('table'));

    const promises = data.items.map(async (item) => {
          const fileResponse = await fetch(item.mediaLink);
          const fileContent = await fileResponse.json();
          return {
            name: item.name,
            content: fileContent,
          };
    });

    const results = await Promise.all(promises);
    allObjects.push(...results);
      
    // if (data.items) {
    //   allObjects.push(...data.items);
    // }

    nextPageToken = data.nextPageToken;
    console.log("Next Page Token: " + nextPageToken);

    // Add a delay to respect rate limits (e.g., 1 request per second)
    await new Promise((resolve) => setTimeout(resolve, 1000));

      // After fetching all objects, perform indexing

    const dataToIndex = allObjects.map((item, index) => ({
      objectID: index,
      content: { ...item.content,id: undefined, table: undefined, premiumColumns: undefined, dataDictionary: undefined },
    }));
    
    index
      .saveObjects(dataToIndex)
      .then(({ objectIDs }) => {
        console.log('Data indexed successfully:', objectIDs);
      })
      .catch((err) => {
        console.error('Error indexing data:', err);
      });
    this.allObjectArray = allObjects

  } while (nextPageToken);

  // console.log("All objects length: " + allObjects.length);
},


    search() {
      // console.log("Index looks like: " + JSON.stringify(index))
      index
        .search(this.searchQuery)
        .then(({ hits }) => {
          this.searchResults = hits;
        })
        .catch(err => {
          console.error(err);
        });
    },





    async fetchDataFromCloudStorage() {
      this.dataSources = [];
      try {
        const bucketName = process.env.VUE_APP_SIFTEE_DATASETS;
        const apiEndpoint = `https://www.googleapis.com/storage/v1/b/${bucketName}/o?maxResults=1000`;

        const response = await fetch(apiEndpoint);
        const data = await response.json();

        // Filter out objects with the "table" key
        const filteredData = data.items.filter((item) => !item.name.includes('table'));

        const promises = filteredData.map(async (item) => {
          const fileResponse = await fetch(item.mediaLink);
          const fileContent = await fileResponse.json();
          return {
            name: item.name,
            content: fileContent,
          };
        });

        const results = await Promise.all(promises);
        this.dataSources.push(...results);

      //   const dataToIndex = this.dataSources.map((item, index) => ({
      //     objectID: index,
      //     content: { ...item.content, table: undefined, description: undefined, dataDictionary: undefined },
      //   }));

      //   index
      //     .saveObjects(dataToIndex)
      //     .then(({ objectIDs }) => {
      //       console.log('Data indexed successfully:', objectIDs);
      //     })
      //     .catch((err) => {
      //       console.error('Error indexing data:', err);
      //     });
      } catch (error) {
        console.log('Error fetching files:', error);
      }


    }
  },
  async mounted() {
    // Fetch data from your cloud storage and populate this.dataSources
    // await this.fetchDataFromCloudStorage();
    await this.listAllObjects();
  },
}

</script>