<template>
  <div class="tabletView">
    <div
      class="os"
      style="
        width: 99vw;
        height: fit-content;
        display: flex;
        flex-direction: column;
      "
    >
      <div
        style="
          width: 100%;
          padding: 9vh;
          padding-left: 4vw;
          height: fit-content;
          background-color: #35609f;
          color: whitesmoke;
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            width: 68vw;
            margin-left: auto;
            margin-right: auto;
          "
        >
          <p style="font-size: 3em; font-weight: 450">Contact Us</p>
          <p style="color: rgba(245, 245, 245, 0.9)">
            Get in touch with us! We would love to hear from you. Please fill
            out the contact form below with your name, email address, and
            message, and we will get back to you as soon as possible. Thank you
            for your interest in our company!
          </p>
        </div>
      </div>
      <div
        id="holdSections"
        style="
          display: flex;
          justify-content: space-between;
          margin-left: auto;
          margin-right: auto;
          width: 68vw;
          height: fit-content;
          margin-top: 5vh;
        "
      >
        <div
          class="individualControl sd"
          id="salesSection"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 1vh;
          "
        >
          <div id="flexControl" style="display: flex; flex-direction: column">
            <div style="display: flex">
              <i class="fa-solid fa-handshake"></i>
              <p>Work with Us</p>
            </div>

            <p style="font-size: 1em; padding-left: 0vw">
              Have data you want to sell on our platform?<br />
              We'd love to have you.
            </p>
          </div>
          <button class="download-button">Contact Sales</button>
        </div>

        <div
          class="individualControl sd"
          id="salesSection"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          "
        >
          <div id="flexControl" style="display: flex; flex-direction: column">
            <div style="display: flex">
              <i class="fa-solid fa-phone"></i>
              <p>Help & Support</p>
            </div>

            <p style="font-size: 1em; padding-left: 0vw">
              Get in touch and let us know how we can help.
            </p>
          </div>
          <button class="download-button">Contact Support</button>
        </div>

        <div
          class="individualControl sd"
          id="salesSection"
          style="
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          "
        >
          <div id="flexControl" style="display: flex; flex-direction: column">
            <div style="display: flex">
              <i class="fa-solid fa-envelope"></i>
              <p>Learn More</p>
            </div>

            <p style="font-size: 1em; padding-left: 0vw">
              Learn more about Ocean from us and our press releases.
            </p>
          </div>
          <button class="download-button">Contact Sales</button>
        </div>
      </div>

      <div
        class="os"
        style="
          margin-top: 6vh;
          margin-bottom: 6vh;
          display: flex;
          flex-direction: column;
          width: 68vw;
          margin-left: auto;
          margin-right: auto;
        "
      >
        <p>General Enquiries</p>
        <p class="second">
          For general enquiries, contact us at
          <span><a href="mailto:info@siftee.co">info@siftee.co</a> </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
</script>