<template>
  <div
    class="os"
    style="
      width: 100%;
      min-height: 100vh;
      height: fit-content;
      background-color: #ffffff;
    "
  >
    <div style="display: flex; width: 100%; padding: 2vw">
      <div
        style="
          margin-left: auto;
          margin-right: auto;
          width: 40%;
          height: 100%;
          border-radius: 4px;
          display: flex;
          flex-direction: column;
        "
      >
        <p
          style="
            padding: 1vw;
            font-size: 1.5em;
            font-weight: lighter;
            height: fit-content;
            margin-top: auto;
            margin-bottom: auto;
          "
        >
          <i
            class="fa-solid fa-cart-shopping"
            style="width: 24px; height: auto; padding-right: 2vw"
          ></i>
          Checkout
        </p>
        <div
          style="
            width: 100%;
            height: 50vh;
            overflow-y: scroll;
            border-bottom: 2px solid rgb(128, 128, 128, 0.3);
            display: flex;
            flex-direction: column;
            gap: 1vh;
          "
        >
          <div
            v-for="(checkoutObj, index) in cartStore"
            :key="index"
            style="
              width: 100%;
              display: flex;
              align-items: center;
              padding: 1vw;
              gap: ;
              background-color: hsla(192, 11%, 91%, 0.3);
            "
          >
            <img
              :src="brandImg(checkoutObj?.sourceCompany)"
              style="width: 16%; height: auto; padding-right: 2vw"
            />
            <div
              style="display: flex; flex-direction: column; line-height: 0.5"
            >
              <p class="b">{{ checkoutObj?.sourceHeader }}</p>
              <p style="font-size: 0.8em; font-weight: lighter">
                {{ checkoutObj?.sourceCompany }}
              </p>
              <p style="font-size: 0.8em; font-weight: lighter">
                Filters {{ Object.keys(checkoutObj?.filtersApplied) }}
              </p>
              <p class="b nw">
                Purchasing ({{ checkoutObj?.rowsPurchased }}) rows at ${{
                  checkoutObj?.costPerRow
                }}/ea.
              </p>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: auto; padding: 1vw; text-align: right">
          <label
            style="
              margin-top: auto;
              margin-bottom: auto;
              width: 100%;
              text-align: right;
            "
            >Total Cost ${{ totalSum }}</label
          >
          <label
            v-if="smallOrderFee > 0"
            style="
              margin-top: auto;
              margin-bottom: auto;
              width: 100%;
              text-align: right;
            "
            >Small Order Fee ${{ smallOrderFee }}</label
          >
          <p style="color: red" v-if="smallOrderFee > 0">
            Total cost must be $1 or more.<br />Purchase more datasets or rows
            to have no small order fee.
          </p>
          <button
            class="download-button mh"
            style="margin-top: 1vh"
            @click="fetchDataFromBackend(usID)"
            :disabled="cartStore.length <= 0"
          >
            Buy Now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { brandImg } from "@/configs.js";
import api from "../api"; // Import the Axios instance
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { onMounted } from "vue";
import { ref } from "vue";

var usID = ref("");
var usEmail = ref("");
let auth;
const isLoggedin = ref(false);
var emailVerified = ref("");
onMounted(() => {
  auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      isLoggedin.value = true;
      usID.value = user.uid;
      usEmail.value = user.email;
      emailVerified.value = user.emailVerified;
    } else {
      isLoggedin.value = false;
    }
    return {
      usID,
    };
  });
});
</script>

<script>
// import { computed } from 'vue';
// import { useStore } from 'vuex';

export default {
  setup() {},

  data() {
    return {
      datasetObjects: [
        {
          datasetID: "hSFCOxOIV8YtYOlJg3l5",
          sourceCompany: "Sephora",
          sourceHeader: "Sephora Face Treatments",
          filtersApplied: { brand: ["Kiehl's Since 1851"] },
          costPerRow: "0.02",
          rowsPurchased: 322,
        },
      ],
    };
  },
  methods: {
    fetchDataFromBackend(usid) {
      const datasetObjects = this.cartStore;
      const line_items = datasetObjects.map((dataset) => ({
        price_data: {
          currency: "sgd",
          product_data: {
            name: dataset.sourceHeader + "|" + dataset.sourceCompany, // Use any suitable field as the product name
            metadata: {
              filtersApplied: JSON.stringify(dataset.filtersApplied),
              buyerID: usid,
              datasetID: dataset.datasetID,
            },
          },
          unit_amount: dataset.costPerRow * 100,
        },
        quantity: dataset.rowsPurchased,
      }));

      // Send the POST request to the backend endpoint
      api
        .post("/stripe/checkout", { line_items })
        .then((response) => {
          // Handle the response from the backend here
          console.log("Response from server:", response);
          console.log("Response data:", response?.data);

          this.checkoutSessionId = response?.data?.url;
          this.redirectToStripe();
        })
        .catch((error) => {
          // Handle errors here
          console.error("Error:", error.response.data);
        });
    },
    redirectToStripe() {
      window.location.href = this.checkoutSessionId;
      // window.location.href = `https://checkout.stripe.com/pay/${this.checkoutSessionId}`
    },
  },
  computed: {
    cartStore() {
      if (this.$store.state.storeModuleName.cartStore) {
        return [this.$store.state.storeModuleName.cartStore];
      } else {
        return [];
      }
    },
    totalSum() {
      let sumTotal = 0;
      if (this.cartStore) {
        for (let i = 0; i < this.cartStore.length; i++) {
          sumTotal =
            sumTotal +
            this.cartStore[i]?.costPerRow * this.cartStore[i]?.rowsPurchased;
        }
        return sumTotal;
      } else {
        return 0;
      }
    },
    smallOrderFee() {
      return 1 - this.totalSum;
    },
  },
};
</script>
