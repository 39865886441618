<script setup>
import { ref } from "vue";
import {
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  sendEmailVerification,
} from "@firebase/auth";
import { useRouter } from "vue-router";
import { getFirestore, addDoc, collection } from "@firebase/firestore";
import { app, hashPasswordWithRef } from "@/configs";
import { useHead, useSeoMeta } from "@unhead/vue";

useHead({
  link: [
    {
      rel: "canonical",
      href: "https://siftee.io/login",
    },
  ],
});

useSeoMeta({
  title:
    "Unlock the Power of Data with Siftee | Sign Up Now | Find Data Faster | Siftee.io",
  description:
    "Join Siftee and revolutionize the way you access and manage data. Streamline your search process in seconds. Get started today!",
  ogDescription:
    "Join Siftee and revolutionize the way you access and manage data. Streamline your search process in seconds. Get started today!",
  ogTitle:
    "Unlock the Power of Data with Siftee | Sign Up Now | Find Data Faster | Siftee.io",
  ogImage: window.location.href + "img/sifteeDarkMobile.c964311a.png",
});

const email = ref("");
const password = ref("");
const router = useRouter();
var errMsg = ref("");
const firstName = ref("");
const lastName = ref("");
// const username = ref('');
// const nric = ref('')

function checkPassword(input) {
  const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,31}$/;
  if (!regex.test(input) === true) {
    return false;
  } else {
    return true;
  }
}

function checkEmail(input) {
  const regex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/;
  if (!regex.test(input) === true) {
    //invalid email
    return false;
  } else {
    return true;
  }
}
function rQuotes(input) {
  // regex will read string with the quotes, use this to remove them, works for ref const specifically... to alter for others, remove .value
  return input.value.replace(/['"]+/g, "");
}

const checkInput = () => {
  const emailPass = checkEmail(rQuotes(email));
  const passwordPass = checkPassword(rQuotes(password));
  // const usernameInputted = String(firstName)?.length >= 1 && String(lastName)?.length >= 1
  return emailPass + passwordPass;
};

const signInWithGoogle = (users, referralID, pass) => {
  const provider = new GoogleAuthProvider();
  if (!pass) {
    referralID = ""; //if verification code inputted does not exist in database, set the code ot nothing.
  }
  signInWithPopup(getAuth(), provider)
    .then(async (userCredentials) => {
      console.log(userCredentials.user);
      console.log("Connection Established");
      const user = userCredentials.user;
      const UCuserID = user.uid;
      const UCemail = user.email;
      const UCusername = user.displayName;
      const UCpassword = await hashPasswordWithRef(password);
      if (users.find((u) => u.emailRef === UCemail) === undefined) {
        addDoc(collection(db, "oceanUsers"), {
          emailRef: UCemail,
          userID: UCuserID,
          username: UCusername,
          referralKey: referralID,
          planRegistered: "Free",
          password: UCpassword,
        });
      }
      router.push("/Search");
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 422) {
        this.$router.push("/");
      }
    });
};

const register = (referralID, pass) => {
  if (checkInput() == 2) {
    createUserWithEmailAndPassword(
      getAuth(),
      email.value.trim(),
      password.value.trim()
    )
      .then(async (userCredentials) => {
        if (!pass) {
          referralID = ""; //if verification code inputted does not exist in database, set the code ot nothing.
        }
        console.log(firstName.value + lastName.value);
        const user = userCredentials.user;
        sendEmailVerification(user);
        const UCuserID = user.uid;
        const UCpassword = await hashPasswordWithRef(password);
        addDoc(collection(db, "oceanUsers"), {
          emailRef: email.value,
          userID: UCuserID,
          username: firstName.value + " " + lastName.value,
          referralKey: referralID,
          planRegistered: "Free",
          photoURL: userCredentials.user.photoURL,
          searchUse: 1,
          password: UCpassword,
        });
        console.log("Successful");
        router.push("/Search");
      })
      .catch((error) => {
        console.log(error.code);
        switch (error.code) {
          case "auth/email-already-exists":
            errMsg.value =
              "The provided email is already in use by an existing user.";
            break;
          case "auth/invalid-email":
            errMsg.value = "The provided email is invalid.";
            break;
          case "auth/missing-email":
            errMsg.value = "You have not entered an email.";
            break;
          case "auth/email-already-in-use":
            errMsg.value =
              "The provided email is already in use by an existing user.";
            break;
          default:
            // errMsg.value = error.code;
            errMsg.value = error;

            break;
        }
      });
  } else {
    errMsg.value = "One or more fields are invalid";
  }

  setTimeout(() => {
    errMsg.value = "";
  }, 7000);
};
</script>

<script>
import { onSnapshot } from "firebase/firestore";
import { query } from "firebase/firestore";
import { onUnmounted } from "vue";
const db = getFirestore(app);

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}
gtag("js", new Date());

gtag("config", "G-1YCCRVSJTM");

export default {
  data() {
    return {
      users: ref([]),
      verificationCodes: ref([]),
      nricValid: "False",
      nric: "",
      email: "",
      password: "",
      confirmPassword: "",
      mobile: "",
      selectedOption: 0,
      isChecked: false,
    };
  },
  computed: {
    isTabletView() {
      return window.innerWidth >= 1024;
    },
  },
  methods: {
    validateUsername(input) {
      var availableUsername = true;
      const regex = /^(?=[a-zA-Z0-9._]{5,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
      if (!regex.test(input) === true) {
        return false;
      } else {
        for (let i = 0; i < this.users.length; i++) {
          if (
            String(input).toLowerCase() === this.users[i].username.toLowerCase()
          ) {
            availableUsername = false;
          }
        }
        return availableUsername;
      }
    },

    submitForm() {
      this.isSubmitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      alert("Success" + JSON.stringify(this.userForm));
    },

    setOptionSelected(val) {
      this.selectedOption = val;
    },

    findEmail(email) {
      if (this.users.find((u) => u.emailRef === email) === undefined) {
        return false;
      } else {
        return true;
      }
    },
    returnPositiveCode() {
      return this.verificationCodes
        .filter((obj) => Object.prototype.hasOwnProperty.call(obj, "code"))
        .map((obj) => obj.code);
    },
  },
  mounted() {
    const userQuery = query(collection(db, "oceanUsers"));
    const liveOceanUsers = onSnapshot(userQuery, (snapshot) => {
      this.users = snapshot.docs.map((doc) => {
        const docID = { id: doc.id };
        const docData = doc.data();
        const data = { ...docData, ...docID };
        return data;
      });
    });

    const codesQuery = query(collection(db, "oceanVerificationCodes"));
    const liveCodes = onSnapshot(codesQuery, (snapshot) => {
      this.verificationCodes = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          code: doc.data().code,
          description: doc.data().description,
          usageCount: doc.data().usageCount,
        };
      });
    });

    onUnmounted(liveCodes, liveOceanUsers);
  },
  props: ["referralID"],
};
</script>

<template>
  <div v-if="isTabletView" style="overflow-x: hidden">
    <div
      class="qs"
      style="
        display: flex;
        width: 100%;
        height: fit-content;
        min-height: 300px;
        overflow: hidden;
        justify-content: center;
      "
    >
      <div style="width: 40%; padding: 1vw">
        <img
          src="../assets/homeImg/signUp.png"
          style="width: 100%; margin: auto"
        />
        <div
          style="
            width: 0%;
            display: flex;
            flex-direction: column;
            display: none;
          "
        >
          <div
            style="
              height: 100%;
              display: flex;
              flex-direction: column;
              text-align: left;
            "
          >
            <p class="nw primary" style="color: gray; font-weight: 400">
              Referral Link
            </p>
            <input
              class=""
              type="text"
              placeholder="Verification Code"
              name=""
              :disabled="true"
              :value="referralID"
            />
          </div>
        </div>
      </div>
      <div style="width: 50%; display: flex; justify-content: center">
        <form
          style="height: fit-content; padding-top: 1vw"
          @submit.prevent="login"
        >
          <div
            style="
              height: 80%;
              margin: auto;
              text-align: center;
              border-radius: 4px;
            "
          >
            <p style="font-weight: 700; font-size: 30px" class="qs">Sign Up</p>
            <div
              style="
                height: fit-content;
                margin: auto;
                display: flex;
                flex-direction: column;
              "
            >
              <div
                style="
                  width: 100%;
                  height: fit-content;
                  display: flex;
                  justify-content: space-between;
                  padding-top: 2vh;
                "
              >
                <div
                  style="
                    width: 48%;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    line-height: 0;
                  "
                >
                  <p class="qs nw" style="color: #0f0f0f; font-weight: 700">
                    First Name
                  </p>
                  <input
                    type="text"
                    placeholder="First Name"
                    name="firstname"
                    v-model="firstName"
                  />
                </div>
                <div
                  style="
                    width: 48%;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    line-height: 0;
                  "
                >
                  <p class="qs nw" style="color: #0f0f0f; font-weight: 700">
                    Last Name
                  </p>
                  <input
                    type="text"
                    placeholder="Last Name"
                    name="lastname"
                    v-model="lastName"
                  />
                </div>
              </div>
              <div
                style="
                  width: 100%;
                  height: 30%;
                  display: flex;
                  flex-direction: column;
                  text-align: left;
                  margin-left: auto;
                  margin-right: auto;
                  padding-top: 2vh;
                  line-height: 0;
                  margin-top: 3vh;
                "
              >
                <p class="primary" style="color: #0f0f0f; font-weight: 700">
                  Work Email
                </p>
                <input
                  class=""
                  type="text"
                  placeholder="Email"
                  name="email"
                  v-model="email"
                />
                <div
                  style="
                    min-height: 24px;
                    width: 100%;
                    text-align: right;
                    margin-top: 2vh;
                  "
                >
                  <div
                    v-if="checkEmail(email)"
                    style="color: green; font-weight: lighter"
                  >
                    Email is valid
                  </div>
                  <div
                    v-else-if="checkEmail(email) != true && email.length >= 5"
                    style="color: red; font-weight: lighter"
                  >
                    Invalid Email
                  </div>
                </div>
              </div>

              <div style="width: 100%">
                <div
                  style="
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                  "
                >
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      text-align: left;
                      margin-left: auto;
                      margin-right: auto;
                      line-height: 0;
                      margin-top: 1vh;
                    "
                  >
                    <p class="primary" style="color: #0f0f0f; font-weight: 700">
                      Password
                    </p>
                    <input
                      class=""
                      type="password"
                      placeholder="Password"
                      name="password"
                      v-model="password"
                    />
                    <div
                      style="
                        min-height: 40px;
                        width: 100%;
                        text-align: right;
                        margin-top: 2vh;
                      "
                    >
                      <div
                        v-if="password.length === 0"
                        style="color: black; font-size: 0.7em"
                      >
                        Must contain at least 10 characters, 1 uppercase & 1
                        lowercase
                      </div>
                      <div
                        v-if="checkPassword(password) === true"
                        style="color: green; font-weight: lighter"
                      >
                        Password is valid
                      </div>
                      <div
                        v-else-if="
                          checkPassword(password) === false &&
                          password.length >= 8
                        "
                        style="color: red; font-weight: lighter"
                      >
                        Password does not fit requirements
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: fit-content">
                <button
                  v-on:click="
                    register(
                      referralID,
                      returnPositiveCode().includes(referralID)
                    )
                  "
                  class="qs b"
                  style="
                    width: 100%;
                    color: white;
                    text-decoration: none;
                    border-radius: 10px;
                    background-color: #709dd8;
                    padding: 1vh;
                    border: none;
                  "
                >
                  Create Account
                </button>
              </div>
              <div
                style="
                  width: 100%;
                  height: 5vh;
                  min-height: 20px;
                  padding-top: 1vh;
                "
              >
                <p
                  class="errMsg l animate__animated animate__fadeIn"
                  style="animation-duration: 0.2s"
                  v-if="errMsg"
                >
                  {{ errMsg }}
                </p>
              </div>
              <div
                style="
                  width: 100%;
                  height: fit-content;
                  margin: auto;
                  display: flex;
                  flex-direction: column;
                "
              >
                <p><span style="color: #4f4f4f">Or Sign Up with</span></p>
                <a
                  href="#"
                  class="google-login-button"
                  @click="
                    signInWithGoogle(
                      users,
                      referralID,
                      returnPositiveCode().includes(referralID)
                    )
                  "
                >
                  <img
                    src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                    alt="Google Logo"
                    width="18"
                    height="18"
                  />
                  <span>Sign in with Google</span>
                </a>
                <p class="it" style="padding-top: 2vw">
                  <span style="font-weight: 400">Already have an account? </span
                  ><span style="color: #709dd8; font-weight: 700"
                    ><router-link to="/Login">Sign In</router-link></span
                  >
                </p>
              </div>

              <img src="../assets/homeImg/signUpBrands.svg" />
              <div
                style="
                  text-align: center;
                  margin-left: auto;
                  margin-right: auto;
                  width: 70%;
                  padding-top: 2vw;
                "
              >
                <p>
                  By creating an account you agree to our Privacy Policy and
                  Terms of Service.
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div v-else style="padding-top: 6vh">
    <div
      style="
        width: 90%;
        min-height: 100vh;
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
      "
    >
      <p
        class="qs"
        style="
          font-size: 2em;
          color: #0f0f0f;
          font-weight: 700;
          text-align: center;
          padding-top: 6vh;
        "
      >
        Sign Up
      </p>
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-top: 4vh;
          width: 100%;
        "
      >
        <div style="width: 48%">
          <p class="qs" style="font-weight: 700">First Name</p>
          <input
            placeholder="First Name"
            name="fname"
            type="text"
            style="width: 100%"
            v-model="firstName"
          />
        </div>
        <div style="width: 48%">
          <p class="qs" style="font-weight: 700">Last Name</p>
          <input
            placeholder="Last Name"
            name="lname"
            type="text"
            style="width: 100%"
            v-model="lastName"
          />
        </div>
      </div>

      <div style="margin-top: 4vh">
        <p class="qs" style="font-weight: 700">Work Email</p>
        <div style="display: flex; align-items: center">
          <input
            style="width: 100%; padding: 2vh 0 2vh 2vw; height: 6vh"
            placeholder="your@email.com"
            name="email"
            v-model="email"
            type="email"
          />
        </div>
      </div>

      <div style="margin-top: 4vh">
        <p class="qs" style="font-weight: 700">
          Password
          <span class="it" style="font-weight: 100">(min. 10 characters)</span>
        </p>
        <div style="display: flex; align-items: center">
          <input
            style="width: 100%; padding: 2vh 0 2vh 2vw; height: 6vh"
            placeholder="**********"
            name="password"
            v-model="password"
            type="password"
          />
        </div>
      </div>

      <div style="margin-top: 6vh">
        <button
          class="qs"
          v-on:click="register()"
          style="
            width: 100%;
            padding: 1vh 0 1vh 0;
            height: fit-content;
            background-color: #709dd8;
            color: white;
            font-weight: 700;
            border: none;
            border-radius: 10px;
          "
        >
          Create Account
        </button>
      </div>
      <p
        class="it"
        style="
          text-align: center;
          color: #0f0f0f;
          font-size: 0.8em;
          margin-top: 2vh;
        "
      >
        Or Sign in with
      </p>
      <div style="width: fit-content; margin-left: auto; margin-right: auto">
        <a
          href="#"
          class="google-login-button"
          style="width: fit-content; margin-left: auto; margin-right: auto"
          @click="
            signInWithGoogle(
              users,
              referralID,
              returnPositiveCode().includes(referralID)
            )
          "
        >
          <img
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            alt="Google Logo"
            width="18"
            height="18"
          />
          <span>Sign in with Google</span>
        </a>
      </div>

      <div style="text-align: center; margin-top: 6vh">
        <p class="it" style="font-size: 0.8em; color: #0f0f0f">
          Already have an account? <span><a href="/Login">Sign In</a></span>
        </p>
      </div>
      <div
        style="
          width: 100%;
          margin-left: auto;
          margin-right: auto;
          margin-top: 2vh;
        "
      >
        <img
          src="../assets/homeImg/signUpBrands.svg"
          style="width: 100%; height: auto"
        />
      </div>
    </div>
  </div>
</template>
