<script setup>
import { useHead, useSeoMeta } from "@unhead/vue";
useHead({
  link: [
    {
      rel: "canonical",
      href: "https://siftee.io/BookADemo",
    },
  ],
});

useSeoMeta({
  title: "Book a demo - Find Data Faster | Siftee.io",
  description:
    "Learn how you can find market sizing, macro statistics, ESG data and more, by setting up a demo with Siftee.",
  ogDescription:
    "Learn how you can find market sizing, macro statistics, ESG data and more, by setting up a demo with Siftee   .",
  ogTitle: "Book a demo - Find Data Faster | Siftee.io",
  ogImage: window.location.href + "img/sifteeDarkMobile.c964311a.png",
});
</script>

<template>
  <div
    style="
      width: 100%;
      min-height: 100vh;
      height: fit-content;
      padding: 0 0 6vh 0;
    "
  >
    <!-- Calendly inline widget begin -->
    <div
      class="calendly-inline-widget"
      data-url="https://calendly.com/siftee/30min?hide_event_type_details=1&hide_gdpr_banner=1"
      style="min-width: 320px; height: 700px"
    ></div>
    <component
      :is="'script'"
      type="text/javascript"
      src="https://assets.calendly.com/assets/external/widget.js"
      async
    >
    </component>
    <!-- Calendly inline widget end -->
  </div>
</template>
