<template>
  <br />
  <div
    class="os animate__animated animate__fadeIn"
    style="
      width: 30vw;
      height: fit-content;
      margin: auto;
      border: 2px solid lightgray;
      padding: 1vh;
      border-radius: 4px;
    "
  >
    <div class="w100" style="overflow: hidden">
      <!-- <img id = "logo"  src="../assets/banners/oia.jpg" style="vertical-align: middle;max-height:100%;max-width:100% ; width:100%;height:100%"/> -->
    </div>
    <p class="b" style="font-size: 1.5em">Reclaim Password</p>
    <p style="font-size: 2vh">
      Forgot / changing your password? Enter the email you used to sign up to
      the website to reset the password.
    </p>
    <p class="mt10" style="font-size: 2vh">
      We will send you a recovery email if the email exists for an account.
    </p>
    <p>
      Check your <span class="b">spam folder</span> if you don't see the email.
    </p>
    <form style="height: fit-content" @submit.prevent="sendEmail">
      <div class="w100">
        <input
          class="inpClear pd5"
          v-model="recoveryemail"
          type="email"
          style="width: 100%"
          placeholder="Enter your email..."
        />
        <div style="min-height: 24px">
          <p style="color: red">{{ errMsg }}</p>
        </div>
        <button
          type="submit"
          v-on:click="login()"
          class="download-button mh"
          style="margin-top: 2vh; margin-bottom: 2vh"
        >
          Submit
        </button>
        <div class="" style="color: gray; font-size: 2vh">
          <router-link to="/Login">Go back to login page</router-link>
        </div>
      </div>
    </form>
    <br />
  </div>
  <br />
</template>

<script setup>
import { ref } from "vue";
import { getAuth, sendPasswordResetEmail } from "@firebase/auth";
import { checkEmail, rQuotes } from "@/configs";
import { useHead, useSeoMeta } from "@unhead/vue";
useHead({
  link: [
    {
      rel: "canonical",
      href: "https://siftee.io/ForgotPassword",
    },
  ],
});

useSeoMeta({
  title: "Password reset - Find Data Faster | Siftee.io",
  description:
    "Forgot your password? Reset your login credentials to regain access to Siftee.",
  ogDescription:
    "Forgot your password? Reset your login credentials to regain access to Siftee.",
  ogTitle: "Password reset - Find Data Faster | Siftee.io",
  ogImage: window.location.href + "img/sifteeDarkMobile.c964311a.png",
});

const errMsg = ref("");
const recoveryemail = ref("");

const sendEmail = () => {
  if (!checkEmail(rQuotes(recoveryemail))) {
    //if the input email is formatted like one
    errMsg.value = "Please type in a valid email address.";
  } else {
    sendPasswordResetEmail(getAuth(), recoveryemail.value);
    errMsg.value =
      "A link to reset your password has been sent to your email if you are registered with us.";
  }
};
</script>
