<script setup>
import { useHead, useSeoMeta } from "@unhead/vue";
import { onMounted, ref, computed } from "vue";

useHead({
  link: [
    {
      rel: "canonical",
      href: "https://siftee.io/",
    },
  ],
});

useSeoMeta({
  title: "Find Data Faster | Siftee.io",
  description:
    "Market sizing data, macro economic data, easily filter data by the contents and column headers, not just the description.",
  ogDescription:
    "Market sizing data, macro economic data, easily filter data by the contents and column headers, not just the description.",
  ogTitle: "Find Data Faster | Siftee.io",
  ogImage: window.location.href + "img/sifteeDarkMobile.c964311a.png",
});

const researcherState = ref(1);
const homeSearch = ref("");
const qsThrough = ref([
  {
    name: "Search for Data",
    description: "Effortlessly find the data you need",
    img: "Search.webp",
  },
  {
    name: "Filter Results",
    description: "Refine your search with powerful filters.",
    img: "filterResults.svg",
  },
  {
    name: "Preview your Data",
    description: "Get a sneak peek before downloading.",
    img: "Preview.webp",
  },
  {
    name: "Download Data",
    description: "Download data as a .csv for you to analyze.",
    img: "Download.webp",
  },
]);

const isTabletView = computed(() => window.innerWidth >= 1024);

const researcherStateModify = (bool) => {
  if (bool) {
    researcherState.value = (researcherState.value % 3) + 1;
  } else {
    researcherState.value = ((researcherState.value - 2 + 3) % 3) + 1;
  }
};

const redirectSearch = (userSearchInput) => {
  const trimmedContent = homeSearch.value.trim().length;
  if (trimmedContent >= 1) {
    window.location.href = "/Search/" + String(userSearchInput);
  }
};

const addStructuredData = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        name: "Siftee",
        url: "https://siftee.io/",
        logo: "https://siftee.io/img/sifteeDarkMobile.c964311a.png",
        sameAs: [
          "https://www.youtube.com/@Siftee",
          "https://www.linkedin.com/company/siftee",
          "https://twitter.com/Siftee_io",
        ],
        contactPoint: {
          "@type": "ContactPoint",
          telephone: "+65 9772 3852",
          contactType: "sales",
        },
      },
      {
        "@type": "WebSite",
        name: "Siftee",
        url: "https://siftee.io/",
        potentialAction: {
          "@type": "SearchAction",
          target: "https://siftee.io/Search/{search_term_string}",
          "query-input": "required name=search_term_string",
        },
      },
      {
        "@type": "WebPage",
        name: "Siftee",
        url: "https://siftee.io/",
        description:
          "Siftee has 0.5m data sets that cover Macro insight, demographic intel, environmental and sustainability data, business information, and many more.",
      },
    ],
  };
  const script = document.createElement("script");
  script.type = "application/ld+json";
  script.innerHTML = JSON.stringify(structuredData);
  document.head.appendChild(script);
};

onMounted(() => {
  document.getElementById("homeSearch")?.focus();
  addStructuredData();
});

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}
gtag("js", new Date());

gtag("config", "G-1YCCRVSJTM");
</script>

<template>
  <body>
    <div
      v-if="isTabletView"
      style="
        width: 100%;
        min-height: 100vh;
        height: fit-content;
        padding-top: 6vh;
        padding-bottom: 11vh;
        overflow-x: hidden;
      "
    >
      <div
        style="
          display: flex;
          padding-left: 6vw;
          padding-right: 6vw;
          margin-left: auto;
          margin-right: auto;
          width: fit-content;
          flex-wrap: wrap;
          justify-content: center;
        "
        id="startBannerSection"
      >
        <div style="width: 40vw">
          <div style="width: 80%; display: flex; flex-direction: column">
            <h1
              style="font-size: 45px; font-weight: 700"
              class="qs genText animate__animated animate__fadeInUp"
            >
              Find Data for <span class="sftBlue"> Any Analysis</span> in
              Seconds
            </h1>
            <div class="search-container" style="margin-top: 3vh">
              <input
                id="homeSearch"
                name="search"
                type="text"
                class="search it"
                v-model="homeSearch"
                style="
                  outline: none;
                  width: 100%;
                  border-radius: 20px;
                  height: 47px;
                  border: 2px solid #709dd8;
                  padding: 1vw;
                "
                placeholder="Search For Data"
                @keyup.enter="redirectSearch(homeSearch)"
              />
              <i
                class="fas fa-search search-icon"
                @click="redirectSearch(homeSearch)"
                style="cursor: pointer"
              ></i>
            </div>
            <div
              class="it"
              style="
                font-weight: 450;
                font-size: 19px;
                line-height: 24.7px;
                padding-top: 2em;
              "
            >
              <p>
                Searching for free data can be a time-consuming hassle. Juggling
                multiple messy CSV files and sorting through them is no one's
                idea of fun.
              </p>
              <p>
                That's where Siftee steps in. With a treasure trove of over
                50,000 preloaded open datasets, Siftee makes it easy for
                Analysts and Researchers to searches by specific fields
              </p>
            </div>
          </div>
        </div>
        <div style="width: 35vw; display: flex; align-items: center">
          <img
            src="../assets/homeImg/productHero.svg"
            style="width: 499; height: 411; border-radius: 10px"
            alt="Hero Section Logo"
          />
        </div>
      </div>

      <div
        style="
          display: flex;
          flex-direction: column;
          margin-top: 11vh;
          width: 100%;
        "
        id="creditSection;"
      >
        <img
          src="../assets/homeImg/Group6602.svg"
          alt="Credits Section Banner"
        />
        <div
          style="
            background-color: #77abff;
            margin-left: auto;
            margin-right: auto;
            width: 88vw;
            height: fit-content;
            min-height: 25vh;
            border-radius: 20px;
            position: relative;
            top: -115px;
            padding: 3vw;
            display: flex;
            align-items: center;
            gap: 7vw;
          "
        >
          <div
            style="
              width: 100px;
              height: 100px;
              border-radius: 50%;
              background-color: white;
              overflow: hidden;
            "
          >
            <img
              src="../assets/homeImg/Group1000002491.svg"
              alt="Consultant Profile Picture"
              width="101"
              height="95"
            />
          </div>
          <div>
            <h2
              class="qs"
              style="font-weight: 700; font-size: 33px; color: white"
            >
              “Before Siftee I would spend hours looking for free data”
            </h2>
            <div style="line-height: 1">
              <p
                class="it"
                style="
                  font-size: 22px;
                  font-weight: 400;
                  color: white;
                  padding-left: 1vw;
                "
              >
                Anneke Maritz
              </p>
              <p
                class="it"
                style="
                  font-size: 22px;
                  font-weight: 700;
                  color: white;
                  padding-left: 1vw;
                "
              >
                Management Consultant
              </p>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 100%; margin-top: 12vh" id="infoSection">
        <h2
          style="font-size: 2vw; font-weight: 700; text-align: center"
          class="qs"
        >
          Quickly search through 50,000+ data sources
        </h2>
        <p
          class="it"
          style="font-weight: 400; text-align: center; color: #797979"
        >
          Find external open data and public data sources for your analysis.
        </p>

        <div
          style="
            width: fit-content;
            display: flex;
            gap: 1vh;
            margin-left: auto;
            margin-right: auto;
            margin-top: 12vh;
          "
        >
          <div class="flexRow">
            <div
              class="flexItem"
              style="
                border: 1px solid #eaeaea;
                border-radius: 20px;
                width: 22vw;
                padding: 2vw;
              "
              data-aos="fade-right"
            >
              <div
                style="
                  width: 100%;
                  height: 70%;
                  display: flex;
                  justify-content: center;
                "
              >
                <img
                  loading="lazy"
                  src="../assets/homeImg/Search.webp"
                  style="
                    width: 377
                    height:426
                  "
                  alt="Search Logo"
                />
              </div>
              <div class="qsT" style="line-height: 1">
                <p class="qs" style="font-weight: 600; font-size: 24px">
                  Search for data
                </p>
                <p class="it" style="font-weight: 400">
                  Effortlessly find the data you need
                </p>
              </div>
            </div>
            <div
              class="qsT flexItem"
              style="
                border: 1px solid #eaeaea;
                border-radius: 20px;
                height: 100%;
                width: 22vw;
                padding: 2vw;
              "
              data-aos="fade-right"
              data-aos-delay="200"
            >
              <div
                style="
                  width: 100%;
                  height: 70%;
                  display: flex;
                  justify-content: center;
                "
              >
                <img
                  loading="lazy"
                  src="../assets/homeImg/Filter.webp"
                  style="
                    width: 377
                    height:426
                  "
                  alt="Filter Logo"
                />
              </div>
              <div style="line-height: 1">
                <p class="qs" style="font-weight: 600; font-size: 24px">
                  Filter results
                </p>
                <p class="it" style="font-weight: 400">
                  Download data as a .csv for you to analyze
                </p>
              </div>
            </div>
            <div
              class="qsT flexItem"
              style="
                border: 1px solid #eaeaea;
                border-radius: 20px;
                height: 100%;
                width: 22vw;
                padding: 2vw;
              "
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <div
                style="
                  width: 100%;
                  height: 70%;
                  display: flex;
                  justify-content: center;
                "
              >
                <img
                  loading="lazy"
                  src="../assets/homeImg/Preview.webp"
                  style="
                    width: 377
                    height:426
                  "
                  alt="Preview Logo"
                />
              </div>
              <div style="line-height: 1">
                <p class="qs" style="font-weight: 600; font-size: 24px">
                  Preview your data
                </p>
                <p class="it" style="font-weight: 400">
                  Get a sneak peek before downloading
                </p>
              </div>
            </div>
            <div
              class="qsT flexItem"
              style="
                border: 1px solid #eaeaea;
                border-radius: 20px;
                height: 100%;
                width: 22vw;
                padding: 2vw;
              "
              data-aos="fade-right"
              data-aos-delay="600"
            >
              <div
                style="
                  width: 100%;
                  height: 70%;
                  display: flex;
                  justify-content: center;
                "
              >
                <img
                  loading="lazy"
                  src="../assets/homeImg/Downloadwhitetransparent.webp"
                  style="
                    width: 377
                    height:426
                  "
                  alt="Download Logo"
                />
              </div>
              <div style="line-height: 1">
                <p class="qs" style="font-weight: 600; font-size: 24px">
                  Download data
                </p>
                <p class="it" style="font-weight: 400">
                  Download data as a .csv for you to analyze
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          style="
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            margin-top: 6vh;
          "
        >
          <a href="/Search">
            <button
              class="cta qs"
              style="margin-left: auto; margin-right: auto"
            >
              Search For Free!
              <i class="fas fa-search" style="transform: scale(0.8)"></i>
            </button>
          </a>
        </div>
      </div>

      <div
        style="
          width: 80%;
          margin-top: 12vh;
          margin-left: auto;
          margin-right: auto;
        "
        id="graphicSection"
      >
        <h2
          class="qs"
          style="
            text-align: center;
            font-weight: 700;
            font-size: 46px;
            color: #04060d;
            padding-bottom: 11vh;
          "
        >
          Find Data Faster
        </h2>
        <div>
          <div style="display: flex; height: fit-content">
            <div
              style="width: 50%; display: flex; justify-content: center"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img
                loading="lazy"
                src="../assets/homeImg/50kDS.svg"
                alt="Data Sources Logo"
                width="auto"
                height="100%"
              />
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                width: 50%;
                padding: 2vh;
              "
            >
              <div
                class="it"
                style="
                  font-size: 2em;
                  background-color: #709dd8;
                  color: white;
                  font-weight: 700;
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                  display: flex;
                  text-align: center;
                  justify-content: center;
                  align-items: center;
                "
              >
                1
              </div>
              <h2
                class="qs"
                style="
                  font-weight: 700;
                  font-size: 35px;
                  width: 70%;
                  padding: 2vh 0 2vh 0;
                  line-height: 1;
                "
              >
                Over 50,000 open data sources
              </h2>
              <p
                class="it"
                style="font-weight: 400; color: #797979; width: 70%"
              >
                Our platform aggregates data from thousands of reputable
                sources, offering a diverse selection of datasets. We curate a
                wide range of data, including free, open data and premium
                sources, to ensure you have access to high-quality information.
                Explore our preloaded repository, all readily available for your
                searches.
              </p>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: center; height: 20vh">
          <img
            loading="lazy"
            src="../assets/homeImg/firstLine.svg"
            alt="Leading Line"
            width="auto"
            height="100%"
          />
        </div>

        <div>
          <div
            style="
              display: flex;
              height: fit-content;
              justify-content: flex-end;
            "
          >
            <div style="width: 50%; padding: 2vh">
              <div style="margin-left: auto; width: 80%">
                <div
                  class="it"
                  style="
                    font-size: 2em;
                    background-color: #709dd8;
                    color: white;
                    font-weight: 700;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  2
                </div>
                <h2
                  class="qs"
                  style="
                    font-weight: 700;
                    font-size: 35px;
                    padding: 2vh 0 2vh 0;
                    line-height: 1;
                  "
                >
                  Pre-categorised data
                </h2>
                <p class="it" style="font-weight: 400; color: #797979">
                  Discover your desired datasets effortlessly through
                  intelligent categorisation. Siftee employs advanced algorithms
                  to identify related data across datasets, streamlining the
                  filtering process for you. Unlock the power of smart data
                  classification today!
                </p>
              </div>
            </div>

            <div
              style="width: 50%; display: flex; justify-content: center"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img
                loading="lazy"
                src="../assets/homeImg/preCatData.svg"
                alt="Pre-categorised Data Logo"
                width="auto"
                height="100%"
              />
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: center; height: 20vh">
          <img
            loading="lazy"
            src="../assets/homeImg/secondLine.svg"
            alt="Leading Line"
            width="auto"
            height="100%"
          />
        </div>

        <div>
          <div style="display: flex; height: fit-content">
            <div
              style="width: 50%; display: flex; justify-content: center"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img
                loading="lazy"
                src="../assets/homeImg/filterDataSource.svg"
                alt="Filter Logo"
                width="auto"
                height="100%"
              />
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                width: 50%;
                padding: 2vh;
              "
            >
              <div
                class="it"
                style="
                  font-size: 2em;
                  background-color: #709dd8;
                  color: white;
                  font-weight: 700;
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                  display: flex;
                  text-align: center;
                  justify-content: center;
                  align-items: center;
                "
              >
                3
              </div>
              <h2
                class="qs"
                style="
                  font-weight: 700;
                  font-size: 35px;
                  width: 70%;
                  padding: 2vh 0 2vh 0;
                  line-height: 1;
                "
              >
                Filter on what's in the data
              </h2>
              <p
                class="it"
                style="font-weight: 400; color: #797979; width: 70%"
              >
                Whether your quest is for macroeconomic insights, ESG metrics,
                or any other specific dataset, our robust filtering options
                empower you to precisely locate the data you seek. Tailor your
                search using keywords, categories, and data types to access the
                information that matters most to you.
              </p>
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: center; height: 30vh">
          <img
            loading="lazy"
            src="../assets/homeImg/thirdLine.svg"
            alt="Leading Line"
            width="auto"
            height="100%"
          />
        </div>

        <div>
          <div
            style="
              display: flex;
              height: fit-content;
              justify-content: flex-end;
            "
          >
            <div style="width: 50%; padding: 2vh">
              <div style="margin-left: auto; width: 80%">
                <div
                  class="it"
                  style="
                    font-size: 2em;
                    background-color: #709dd8;
                    color: white;
                    font-weight: 700;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  4
                </div>
                <h2
                  class="qs"
                  style="
                    font-weight: 700;
                    font-size: 35px;
                    padding: 2vh 0 2vh 0;
                    line-height: 1;
                  "
                >
                  <span style="color: #709dd8">Sift</span> out data sources that
                  don't have the fields you want
                </h2>
                <p class="it" style="font-weight: 400; color: #797979">
                  Siftee allows you to eliminate datasets that lack the specific
                  fields you require. Based on your search criteria and filters,
                  our platform intelligently sifts through the available
                  datasets, presenting you only with those that match your data
                  field criteria.
                </p>
              </div>
            </div>

            <div
              style="width: 50%; display: flex; justify-content: center"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img
                loading="lazy"
                src="../assets/homeImg/matchDataToAnalysis.svg"
                alt="Sift Data Logo"
                width="auto"
                height="100%"
              />
            </div>
          </div>
        </div>

        <div style="display: flex; justify-content: center; height: 20vh">
          <img
            loading="lazy"
            src="../assets/homeImg/fourthLine.svg"
            alt="Leading Line"
            width="auto"
            height="100%"
          />
        </div>

        <div>
          <div style="display: flex; height: fit-content">
            <div
              style="width: 50%; display: flex; justify-content: center"
              data-aos="fade-right"
              data-aos-duration="1000"
            >
              <img
                loading="lazy"
                src="../assets/homeImg/dqScore.svg"
                alt="Data Quality Logo"
                width="auto"
                height="100%"
              />
            </div>
            <div
              style="
                display: flex;
                flex-direction: column;
                width: 50%;
                padding: 2vh;
              "
            >
              <div
                class="it"
                style="
                  font-size: 2em;
                  background-color: #709dd8;
                  color: white;
                  font-weight: 700;
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                  display: flex;
                  text-align: center;
                  justify-content: center;
                  align-items: center;
                "
              >
                5
              </div>
              <h2
                class="qs"
                style="
                  font-weight: 700;
                  font-size: 35px;
                  width: 70%;
                  padding: 2vh 0 2vh 0;
                  line-height: 1;
                "
              >
                Data quality score
              </h2>
              <p
                class="it"
                style="font-weight: 400; color: #797979; width: 70%"
              >
                Discover the most reliable datasets with Siftee's data quality
                score, which evaluates data based on criteria such as
                completeness, provenance, and refresh frequency. This score
                ensures you access the highest-quality datasets available,
                making your data search more efficient and trustworthy.
              </p>
            </div>
          </div>
        </div>
        <div style="display: flex; justify-content: center; height: 20vh">
          <img
            loading="lazy"
            src="../assets/homeImg/fifthLine.svg"
            alt="Leading Line"
            width="auto"
            height="100%"
          />
        </div>

        <div>
          <div
            style="
              display: flex;
              height: fit-content;
              justify-content: flex-end;
            "
          >
            <div style="width: 50%; padding: 2vh">
              <div style="margin-left: auto; width: 80%">
                <div
                  class="it"
                  style="
                    font-size: 2em;
                    background-color: #709dd8;
                    color: white;
                    font-weight: 700;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    display: flex;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  6
                </div>
                <h2
                  class="qs"
                  style="
                    font-weight: 700;
                    font-size: 35px;
                    padding: 2vh 0 2vh 0;
                    line-height: 1;
                  "
                >
                  Transparent Data Provenance
                </h2>
                <p class="it" style="font-weight: 400; color: #797979">
                  Curious about the origin of the data? Siftee provides detailed
                  insights into data provenance and collection methodology,
                  sourced directly from the data supplier. Spend less time
                  researching and more time on data analysis with our
                  comprehensive data transparency features.
                </p>
              </div>
            </div>

            <div
              style="width: 50%; display: flex; justify-content: center"
              data-aos="fade-left"
              data-aos-duration="1000"
            >
              <img
                loading="lazy"
                src="../assets/homeImg/collectionMethodology.svg"
                alt="Data Origin Logo"
                width="auto"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style="
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
          margin-top: 6vh;
        "
      >
        <a href="/Search">
          <button class="cta qs" style="margin-left: auto; margin-right: auto">
            Search For Free!
            <i class="fas fa-search" style="transform: scale(0.8)"></i>
          </button>
        </a>
      </div>

      <div
        style="
          width: 88vw;
          height: fit-content;
          margin-left: auto;
          margin-right: auto;
          margin-top: 12vh;
        "
      >
        <div style="width: 100%">
          <img
            loading="lazy"
            src="../assets/homeImg/Group6591.svg"
            style="width: 100%"
            alt="Customers' Review Banner"
          />
        </div>
      </div>
      <!-- <div
      style="width:95vw;margin-left: auto;margin-right: auto;border-radius:20px;height:679px;margin-top:18vh;background-color: #9CC2FF;text-align: center;padding:3vw">
      <p class="qs" style="font-size:35px;font-weight:700;padding-top:1vw">What analysts and researchers are saying about
        Siftee</p>
      <div style="margin-top:6vh;width:fit-content;display:flex;gap:1vw;height:100%;margin-left: auto;margin-right:auto">
        <div style="width:22vw;height:18vw;border-radius:20px">
          <img loading='lazy' src="../assets/homeImg/Group6577.svg">
        </div>
        <div style="width:22vw;height:18vw;border-radius:20px">
          <img loading='lazy' src="../assets/homeImg/Group6578.svg">

        </div>
        <div style="width:22vw;height:18vw;border-radius:20px">
          <img loading='lazy' src="../assets/homeImg/Group6579.svg">

        </div>
      </div>

    </div> -->

      <div
        style="
          width: 100%;
          height: fit-content;
          text-align: center;
          margin-top: 12vh;
        "
        id="frmSection"
      >
        <h2 class="qs" style="font-weight: 700; font-size: 38px">
          Find the external data you need, fast
        </h2>
        <p
          class="it"
          style="
            font-weight: 400;
            font-size: 19px;
            color: #797979;
            width: 30vw;
            margin-left: auto;
            margin-right: auto;
          "
        >
          Search for data, filter search results, preview and download the data
          you need - all in seconds!
        </p>

        <div
          style="
            width: 80vw;
            margin-left: auto;
            margin-right: auto;
            height: fit-content;
            min-height: 20vh;
            display: flex;
            justify-content: center;
            gap: 5vw;
            padding-top: 6vh;
          "
        >
          <div class="stats">
            <div class="stats-inner">
              <img
                loading="lazy"
                class="stats-img"
                src="../assets/homeImg/file50k.svg"
                alt="File Logo"
                width="auto"
                height="100%"
              />
            </div>
            <div class="stats-content">
              <p style="font-weight: 700; font-size: 43px" class="qs">
                >50,000
              </p>
              <p style="font-weight: 400; font-size: 19px; color: #797979">
                Data sources
              </p>
            </div>
          </div>
          <div class="stats">
            <div class="stats-inner">
              <img
                loading="lazy"
                class="stats-img"
                src="../assets/homeImg/checkTime.svg"
                alt="Checklist Logo"
                width="auto"
                height="100%"
              />
            </div>
            <div class="stats-content">
              <p style="font-weight: 700; font-size: 43px" class="qs nw">
                74 Seconds
              </p>
              <p style="font-weight: 400; font-size: 19px; color: #797979">
                Average time from Search to download
              </p>
            </div>
          </div>
          <div class="stats">
            <div class="stats-inner">
              <img
                loading="lazy"
                class="stats-img"
                src="../assets/homeImg/10k.svg"
                alt="Download Logo"
                width="auto"
                height="100%"
              />
            </div>
            <div class="stats-content">
              <p style="font-weight: 700; font-size: 43px" class="qs">10k</p>
              <p style="font-weight: 400; font-size: 19px; color: #797979">
                Global downloads
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="ContactSection"></div>
      <div
        style="
          width: 88vw;
          height: fit-content;
          margin-top: 12vh;
          background-color: #4f4f4f;
          padding: 1.5vw;
          margin-left: auto;
          margin-right: auto;
          border-radius: 20px;
        "
      >
        <form
          style="display: flex; width: 100%; justify-content: right"
          action="https://customerioforms.com/forms/submit_action?site_id=3e918ab22067ac866438&form_id=6cf55b1b47d5461&success_url=https://siftee.io/"
          method="post"
          class="validate"
          target="_blank"
        >
          <div
            style="
              width: 45%;
              text-align: left;
              display: flex;
              flex-direction: column;
              padding-top: 6vh;
            "
            id="mc_embed_signup"
          >
            <p
              class="qs"
              style="
                color: white;
                font-size: 35px;
                font-weight: 700;
                width: 80%;
              "
            >
              Question? Want a demo? Get in touch!
            </p>
            <p style="color: white; font-weight: 400; width: 80%" class="it">
              Have any questions? We are here to help! Fill this Contact Form
              and we will get back to you as soon as possible.
            </p>
          </div>
          <div
            style="
              width: 50%;
              min-height: 55vh;
              height: fit-content;
              background-color: #eef1fd;
              border-radius: 20px;
              padding: 1.5vw;
            "
          >
            <p class="qs" style="font-size: 35px; font-weight: 700">
              Contact Us
            </p>
            <div
              style="
                display: flex;
                flex-direction: column;
                gap: 2vh;
                width: 100%;
              "
            >
              <div
                style="
                  width: 100%;
                  display: flex;
                  margin-left: auto;
                  margin-right: auto;
                  justify-content: space-between;
                "
              >
                <input
                  name="FNAME"
                  type="text"
                  class="mcInput inpContact"
                  placeholder="First Name"
                  style="width: 48%; background-color: white"
                />
                <input
                  name="LNAME"
                  type="text"
                  class="mcInput inpContact"
                  placeholder="Last Name"
                  style="width: 48%; background-color: white"
                />
              </div>
              <input
                name="EMAIL"
                type="email"
                class="required email mcInput inpContact"
                placeholder="Email"
                style="width: 100%; background-color: white"
              />
              <textarea
                name="MESSAGE"
                type="text"
                class="mcInput inpContact"
                placeholder="Message"
                style="width: 100%; height: 20vh; resize: none"
              ></textarea>
              <div style="display: flex; justify-content: right">
                <button class="submitBtn-desktop">Submit</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div
      v-if="!isTabletView"
      style="
        width: 100%;
        height: fit-content;
        min-height: 100vh;
        background-color: white;
        padding-top: 11vh;
      "
    >
      <div style="width: 90vw; margin-left: auto; margin-right: auto">
        <div style="width: 100%; display: flex; flex-direction: column">
          <h1 style="font-size: 45px; font-weight: 700" class="qs genText">
            Find Data for <span class="sftBlue"> Any Analysis</span> in Seconds
          </h1>
          <div class="search-container">
            <input
              id="homeSearch"
              name="search"
              type="text"
              class="search it"
              v-model="homeSearch"
              style="
                outline: none;
                width: 100%;
                border-radius: 20px;
                height: 47px;
                border: 2px solid #709dd8;
                padding: 1vw;
                padding-left: 3vw;
              "
              placeholder="Search For Data"
              @keyup.enter="redirectSearch(homeSearch)"
            />
            <i
              class="fas fa-search search-icon"
              @click="redirectSearch(homeSearch)"
              style="cursor: pointer"
            ></i>
          </div>
          <div
            class="it"
            style="font-weight: 400; line-height: 1; padding-top: 2em"
          >
            <p>
              Searching for free data can be a time-consuming hassle. Juggling
              multiple messy CSV files and sorting through them is no one's idea
              of fun.
            </p>
            <p>
              That's where Siftee steps in. With a treasure trove of over 50,000
              preloaded open datasets, Siftee makes it easy for Analysts and
              Researchers to search by specific fields
            </p>
          </div>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 6vh;
          "
        >
          <img
            src="../assets/homeImg/productHero.svg"
            style="width: 499; height: 411; border-radius: 10px"
            alt="Hero Section Logo"
          />
        </div>
      </div>
      <div
        style="
          display: flex;
          flex-direction: column;
          margin-top: 6vh;
          width: 375;
          height: 360;
        "
        id="creditSection;"
      >
        <img
          loading="lazy"
          src="../assets/homeImg/brandImgMobile.svg"
          alt="Credits Section Banner"
        />
      </div>

      <div style="width: 100%; margin-top: 6vh; text-align: center">
        <h2
          class="qs"
          style="
            font-weight: 700;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            font-size: 1.2em;
            color: #04060d;
          "
        >
          Quickly search through 50,000+ data sources
        </h2>
        <p class="it" style="font-weight: 400; font-size: 12px; color: #797979">
          Find external open data and public data sources for your analysis.
        </p>
      </div>
      <div
        style="margin-top: 2vh; display: flex; flex-direction: column"
        v-for="(qs, index) in qsThrough"
        :key="index"
      >
        <div
          style="
            display: flex;
            justify-content: space-between;
            width: 95%;
            padding: 2vw;
            margin-left: auto;
            margin-right: auto;
            height: fit-content;
            border-radius: 20px;
            border: 1px solid #eaeaea;
          "
        >
          <div style="width: 40%">
            <img
              loading="lazy"
              :src="require(`../assets/homeImg/${qs?.img}`)"
              style="width: 100%; height: auto"
              alt="Search Logo"
            />
          </div>
          <div
            style="
              width: 50%;
              text-align: left;
              line-height: 1;
              display: flex;
              flex-direction: column;
              justify-content: center;
            "
          >
            <h2
              class="qs"
              style="font-weight: 600; font-size: 1.2em; height: fit-content"
            >
              {{ qs?.name }}
            </h2>
            <p
              class="it"
              style="font-weight: 400; color: #797979; height: fit-content"
            >
              {{ qs?.description }}
            </p>
          </div>
        </div>
      </div>

      <div style="margin-top: 6vh" id="graphicSection">
        <!-- FEATURES ANCHOR HERE -->
        <img
          loading="lazy"
          src="../assets/homeImg/Group1000002511.svg"
          style="width: 100%; height: auto"
          alt="Features Banner"
        />
      </div>
      <div
        style="
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
          margin-top: 6vh;
        "
      >
        <a href="/Search">
          <button class="cta qs" style="margin-left: auto; margin-right: auto">
            Search For Free!
            <i class="fas fa-search" style="transform: scale(0.8)"></i>
          </button>
        </a>
      </div>
      <div
        style="
          width: 100%;
          margin-top: 6vh;
          height: fit-content;
          text-align: center;
          background-color: #9cc2ff;
          border-radius: 20px;
          padding: 3vw;
          padding-bottom: 6vh;
        "
      >
        <h2
          style="
            color: black;
            font-weight: 700;
            margin-top: 6vh;
            font-size: 1.2em;
          "
          class="qs"
        >
          What analysts and researchers are saying about Siftee
        </h2>
        <!-- {{ researcherState }} -->

        <img
          loading="lazy"
          :src="require(`../assets/homeImg/researcher${researcherState}.svg`)"
          style="width: 100%"
          alt="Customers' Review Banner"
        />
        <div
          style="
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            gap: 6vw;
          "
        >
          <img
            loading="lazy"
            src="../assets/homeImg/arrowLeft.svg"
            @click="researcherStateModify(false)"
            style="transform: scale(1.5)"
            alt="Left Arrow Logo"
          />
          <img
            loading="lazy"
            src="../assets/homeImg/arrowRight.svg"
            @click="researcherStateModify(true)"
            style="transform: scale(1.5)"
            alt="Right Arrow Logo"
          />
        </div>
      </div>

      <div
        style="
          width: 100%;
          height: fit-content;
          text-align: center;
          margin-top: 12vh;
        "
        id="frmSection"
      >
        <h2 class="qs" style="font-weight: 700; font-size: 1.2em">
          Find the external data you need, fast
        </h2>
        <p
          class="it"
          style="
            font-weight: 400;
            color: #797979;
            margin-left: auto;
            margin-right: auto;
          "
        >
          Search for data, filter search results, preview and download the data
          you need - all in seconds!
        </p>

        <div
          style="
            width: 80vw;
            margin-left: auto;
            margin-right: auto;
            height: fit-content;
            min-height: 20vh;
            display: flex;
            flex-direction: column;
            gap: 5vw;
            padding-top: 6vh;
          "
        >
          <div
            style="width: 100%; display: flex; justify-content: space-between"
          >
            <div style="width: 25%; height: 70%; padding-top: 4vh">
              <img
                loading="lazy"
                src="../assets/homeImg/file50k.svg"
                style="width: 100%"
                alt="File Logo"
              />
            </div>
            <div
              style="
                line-height: 1;
                height: 30%;
                width: 50%;
                display: flex;
                flex-direction: column;
                text-align: left;
                padding-top: 6vh;
              "
            >
              <h2 style="font-weight: 700; font-size: 1.5em" class="qs">
                >50,000
              </h2>
              <p style="font-weight: 400; color: #797979">Data sources</p>
            </div>
          </div>

          <div
            style="width: 100%; display: flex; justify-content: space-between"
          >
            <div style="width: 25%; height: 70%; padding-top: 4vh">
              <img
                loading="lazy"
                src="../assets/homeImg/checkTime.svg"
                style="width: 100%"
                alt="Checklist Logo"
              />
            </div>
            <div
              style="
                line-height: 1;
                height: 30%;
                width: 50%;
                display: flex;
                flex-direction: column;
                text-align: left;
                padding-top: 6vh;
              "
            >
              <h2 style="font-weight: 700; font-size: 1.5em" class="qs nw">
                74 Seconds
              </h2>
              <p style="font-weight: 400; color: #797979">
                Average time from Search to download
              </p>
            </div>
          </div>
          <div
            style="width: 100%; display: flex; justify-content: space-between"
          >
            <div style="width: 25%; height: 70%; padding-top: 4vh">
              <img
                loading="lazy"
                src="../assets/homeImg/10k.svg"
                style="width: 100%"
                alt="Download Logo"
              />
            </div>
            <div
              style="
                line-height: 1;
                height: 30%;
                width: 50%;
                display: flex;
                flex-direction: column;
                text-align: left;
                padding-top: 6vh;
              "
            >
              <h2 style="font-weight: 700; font-size: 1.5em" class="qs">10k</h2>
              <p style="font-weight: 400; color: #797979">Global downloads</p>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          width: 100%;
          height: fit-content;
          padding: 3vw 3vw 10vw 3vw;
          border-radius: 20px;
          background-color: #4f4f4f;
          text-align: center;
          margin-top: 11vh;
          margin-bottom: 11vh;
        "
      >
        <div style="text-align: center; padding: 6vh 0 6vh 0">
          <p
            class="qs"
            style="
              font-weight: 700;
              color: white;
              font-size: 1.5em;
              margin-left: auto;
              margin-right: auto;
              width: 70%;
            "
            id="mc_embed_signup"
          >
            Question? Want a demo? Get in touch!
          </p>
          <p
            class="it"
            style="
              font-weight: 400;
              color: white;
              font-size: 0.8em;
              margin-left: auto;
              margin-right: auto;
              width: 80%;
            "
          >
            Have any questions? We are here to help! Fill this Contact Form and
            we will get back to you as soon as possible.
          </p>
        </div>

        <div
          style="
            width: 100%;
            min-height: 55vh;
            height: fit-content;
            background-color: #eef1fd;
            border-radius: 20px;
            padding: 30px;
          "
        >
          <p
            class="qs"
            style="font-size: 1.2em; font-weight: 700; text-align: left"
          >
            Contact Us
          </p>

          <form
            style="display: flex; width: 100%; justify-content: right"
            action="https://customerioforms.com/forms/submit_action?site_id=3e918ab22067ac866438&form_id=6cf55b1b47d5461&success_url=https://siftee.io/"
            method="post"
            class="validate"
            target="_blank"
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                gap: 2vh;
                width: 100%;
              "
            >
              <div
                style="
                  width: 100%;
                  display: flex;
                  margin-left: auto;
                  margin-right: auto;
                  justify-content: space-between;
                "
              >
                <input
                  name="FNAME"
                  type="text"
                  class="mcInput inpContact"
                  placeholder="First Name"
                  style="width: 48%; background-color: white; padding: 1vh"
                />
                <input
                  name="LNAME"
                  type="text"
                  class="mcInput inpContact"
                  placeholder="Last Name"
                  style="width: 48%; background-color: white; padding: 1vh"
                />
              </div>
              <input
                name="EMAIL"
                type="email"
                class="required email mcInput inpContact"
                placeholder="Email"
                style="width: 100%; background-color: white; padding: 1vh"
              />
              <textarea
                name="MESSAGE"
                type="text"
                class="mcInput inpContact"
                placeholder="Message"
                style="width: 100%; height: 30vh; resize: none; padding: 1vh"
              ></textarea>

              <button class="submitBtn-mobile">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </body>
</template>
