<script setup>
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { onMounted } from "vue";
var usID = ref("");
var usEmail = ref("");
let auth;
const isLoggedin = ref(false);
var emailVerified = ref("");
onMounted(() => {
  auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      isLoggedin.value = true;
      usID.value = user.uid;
      usEmail.value = user.email;
      emailVerified.value = user.emailVerified;
    } else {
      isLoggedin.value = false;
    }
    return {
      usID,
    };
  });
});
</script>

<script>
import {
  getFirestore,
  collection,
  query,
  updateDoc,
  arrayUnion,
  doc,
  increment,
  onSnapshot,
} from "firebase/firestore";
import { ref, onUnmounted } from "vue";
import { app, brandImg, formatDate } from "@/configs.js";
import { computed } from "vue";
import { useStore } from "vuex";
import { useSeoMeta } from "@unhead/vue";

const db = getFirestore(app);

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", "G-1YCCRVSJTM");

//make the div with id removalArrContainerDiv being scrolled while the mouse hovers inside result in the div being scrolled horizontally instead of vertically using pure javascript

// document.addEventListener("DOMContentLoaded", function () {
//   var yourDiv = document.getElementById("removalArrContainerDiv");
//   if (yourDiv) {
//     // Check if the div exists
//     yourDiv.addEventListener("wheel", function (e) {
//       e.preventDefault();
//       yourDiv.scrollLeft += e.deltaY;
//     });
//   }
// });

export default {
  setup() {
    const store = useStore();
    const dataSourceTableBucketStore = computed(
      () => store.state.storeModuleName.dataSourceTableBucket
    );

    const setdataSourceTableBucket = (value) => {
      store.commit("storeModuleName/setdataSourceTableBucket", value);
    };
    return {
      dataSourceTableBucketStore,
      setdataSourceTableBucket,
    };
  },
  data() {
    return {
      listOfContents: [
        { name: "Data Preview", idAttributed: "dataPreview" },
        { name: "Description", idAttributed: "description" },
        { name: "Data Dictionary", idAttributed: "dataDictionary" },
        // { name: "Geography", idAttributed: "geography" },
        // { name: "History", idAttributed: "history" },
        // { name: "Delivery", idAttributed: "delivery" },
        // { name: "Use Case", idAttributed: "useCase" },
        // { name: "Categories", idAttributed: "categories" },
        // { name: "Similar Searches", idAttributed: "similarSearches" },
        // { name: "Similar Products", idAttributed: "similarProducts" },
        // { name: "FAQs", idAttributed: "faqs" }
      ],
      downloadURL: ref(""),
      dataSources: ref([]),
      dataDetails: ref([]),
      users: ref([]),
      menuVisible: "none",
      removalArr: [],
      userScore: ref(),
      errMsg: ref(""),
      filterArray: {},
      storeFilterArray: {},
      visibleBlueBoxes: {},
      checkboxStates: {},
      previewVisible: false,
      isTableVisible: false,
      //below are temporary variables to be removed later
      description: "",
      inputText: "",
      outputText: "",
      expandedView: false,
    };
  },

  methods: {
    expandData() {
      this.expandedView = !this.expandedView;
    },
    async fetchDownload(link) {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `${link}`,
        },
      };

      try {
        const response = await fetch(
          "https://siftee-backend-cloudrun-aege67uwwa-uc.a.run.app/gcs/download-csv",
          requestOptions
        );

        if (response.ok) {
          // Create a Blob from the response data
          const blob = await response.blob();

          // Create a temporary URL for the Blob
          const url = window.URL.createObjectURL(blob);

          // Create an anchor element to trigger the download
          const a = document.createElement("a");
          a.href = url;
          a.download = `${this.dataset.content?.sourceHeader}.csv`;

          // Trigger a click event on the anchor element to start the download
          a.click();

          // Clean up by revoking the URL
          window.URL.revokeObjectURL(url);
        } else {
          console.error("Error fetching download");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    scrollToID(content) {
      document.getElementById(String(content)).scrollIntoView({
        behavior: "smooth",
      });
    },
    async downloadedFiles() {
      var name = this.dataset.name;
      // console.log("Name of Dataset: " + name);

      const bucketName = process.env.VUE_APP_SIFTEE_DATASETS;
      const url = `https://storage.googleapis.com/${bucketName}/${name}`;
      // console.log("Url: " + url)
      try {
        // Fetch the file from Google Cloud Storage
        const response = await fetch(url);
        const blob = await response.blob();

        // Create a temporary URL for the downloaded file
        const objectUrl = URL.createObjectURL(blob);

        // Use the temporary URL to download the file
        const link = document.createElement("a");
        link.href = objectUrl;
        link.download = name;
        link.click();

        // Revoke the temporary URL
        URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    },

    filterArrayFunc(array, filter) {
      const filterKeys = Object.keys(filter);
      return array.filter((item) =>
        filterKeys.every((key) => filter[key].includes(String(item[key])))
      );
    },

    returnUser(uid) {
      return Object(this.users.find((u) => u.id == uid));
    },

    updateUserDownloaded(usid) {
      let uid = Object(this.users.find((u) => u.userID == usid)).id;
      updateDoc(doc(db, "oceanUsers", uid), {
        downloadedFiles: arrayUnion(this.datasetID),
      })
        .then(() => {
          updateDoc(doc(db, "dataSources", this.dataset.content.id), {
            downloadCount: increment(1),
          });
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
    },

    uploadComment(usID) {
      const currentUser = this.users.find((u) => u.userID == usID);
      const commented = this.$refs.commentArea.value;
      if (!(this.userScore >= 1 && this.userScore <= 10) || !commented.trim()) {
        this.errMsg = "Please enter a comment and choose a rating.";
      } else {
        updateDoc(doc(db, "dataSources", this.dataset.content.id), {
          userVotes: arrayUnion({
            timestamp: Date.now(),
            uComment: commented.trim(),
            uID: currentUser.id,
            uScore: this.userScore,
          }),
        });
        this.errMsg = "Comment submitted!";
        this.$refs.commentArea.value = "";
      }
      setTimeout(() => (this.errMsg = ""), 2000);
    },
    returnUserByUSID(usid) {
      return Object(this.users.find((u) => u.userID == usid));
    },

    menuVisibleToggle() {
      this.menuVisible = this.menuVisible === "none" ? "block" : "none";
      // this.removalArr = []
      //now update checkboxes based on removalArr values
      const allCheckboxes = document.querySelectorAll(
        'input[type="checkbox"].valueCheckbox'
      );
      allCheckboxes.forEach((obj) => {
        if (!this.removalArr.includes(obj.id)) {
          obj.checked = true;
        }
      });
    },
    updateRemovalArr() {
      const allCheckboxes = document.querySelectorAll(
        'input[type="checkbox"].valueCheckbox:not(:checked)'
      );
      this.removalArr = [];
      allCheckboxes.forEach((obj) => {
        if (!this.removalArr.includes(obj.id)) {
          this.removalArr.push(obj.id);
        }
      });
    },
    selectAll(val) {
      const allCheckboxes = document.querySelectorAll(
        'input[type="checkbox"].valueCheckbox'
      );
      allCheckboxes.forEach((obj) => {
        obj.checked = val;
      });
      this.updateRemovalArr();
    },

    removeKeysFromObjects(arr, keys) {
      const modifiedArray = [];

      for (let i = 0; i < arr.length; i++) {
        const obj = arr[i];
        const modifiedObj = {};

        for (const key in obj) {
          if (!keys.includes(key)) {
            modifiedObj[key] = obj[key];
          }
        }

        modifiedArray.push(modifiedObj);
      }

      return modifiedArray;
    },
    hasValueForKey(obj, key, value) {
      return String(obj[key]).includes(value);
    },
    uniqueKeys(arr) {
      const keys = new Set();

      arr.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          keys.add(key);
        });
      });

      return Array.from(keys);
    },

    sanitizeString(inputString) {
      let sanitizedString = inputString.replace(/ /g, "_");
      sanitizedString = sanitizedString.replace(/'/g, "");
      sanitizedString = sanitizedString.toLowerCase();

      return sanitizedString;
    },

    getUniqueKeys(objects) {
      const keys = new Set();

      for (let i = 0; i < objects.length; i++) {
        const objectKeys = Object.keys(objects[i]);

        for (let j = 0; j < objectKeys.length; j++) {
          keys.add(objectKeys[j]);
        }
      }

      return Array.from(keys);
    },
  },
  computed: {
    isTabletView() {
      return window.innerWidth >= 1024;
    },
    computedSimilarProvider() {
      const sourceComp = this.dataset?.content?.sourceCompany;
      if (sourceComp) {
        return this.dataSources.filter(
          (ds) =>
            ds.content?.sourceCompany == sourceComp &&
            ds.content?.id != this.datasetID
        );
      } else {
        return [];
      }
    },

    setID() {
      let id = String(this.datasetID).split("_")[0];
      return id;
    },
    sortedDataDictionary() {
      if (!this.dataset.content?.dataDictionary) return;
      const dataDictionary = this.dataset.content?.dataDictionary;
      return Object.keys(dataDictionary)
        .sort()
        .reduce((result, key) => {
          result[key] = dataDictionary[key];
          return result;
        }, {});
    },
    orgURL() {
      // console.log("Decoded URI: " + decodeURIComponent(this.datasetID))
      return decodeURIComponent(this.datasetID);
    },
    dataset() {
      return Object(this.dataSources[0]);
    },
    table() {
      const tableSet = Object(
        this.dataSources.find((ds) => ds.content.sourceHeader == this.orgURL)
      );
      return this.removeKeysFromObjects(
        Object(tableSet.content?.table),
        this.removalArr
      ).slice(0, 10);
    },
    tableClone() {
      const tableSet = Object(
        this.dataSources.find((ds) => ds.content.sourceHeader == this.orgURL)
      );
      return tableSet.content?.table.slice(0, 10);
    },
    tableLength() {
      const tableSet = Object(
        this.dataSources.find((ds) => ds.content.sourceHeader == this.orgURL)
      );
      return tableSet.content?.table.length;
    },
  },
  async mounted() {
    // if (this.$store.state.storeModuleName.dataSourceTableBucketStore) {
    //   this.dataSources = this.$store.state.storeModuleName.dataSourceTableBucketStore
    // }
    // else {
    try {
      const bucketName = "siftee_datasets";
      let nameClear = decodeURIComponent(String(this.datasetID));
      const apiEndpoint = `https://www.googleapis.com/storage/v1/b/${bucketName}/o/${nameClear}.json`;
      console.log("API ENDPOINT: ", apiEndpoint);
      console.log("dataset on load keys " + this.orgURL);
      const response = await fetch(apiEndpoint);
      const data = await response.json();

      // Process the single object directly
      const item = data;
      const fileResponse = await fetch(item.mediaLink);
      const fileContent = await fileResponse.json();
      this.downloadURL = apiEndpoint;
      const result = {
        name: item.name,
        content: fileContent,
      };

      this.dataSources.push(result);
      console.log(this.dataSources);
      document.title = await result.content?.sourceHeader;
      this.$store.commit(
        "storeModuleName/setdataSourceTableBucket",
        this.dataSources
      );

      const datasetInfo = {
        name: this.dataset?.content?.sourceHeader,
        description: this.dataset?.content?.description,
        distribution: [
          {
            contentUrl: window.location.href,
          },
        ],
      };
      datasetInfo;
      const jsonLdContent = {
        "@context": "https://schema.org",
        "@type": "Dataset",
        name: datasetInfo.name,
        description: datasetInfo.description,
        distribution: datasetInfo.distribution.map((d) => ({
          "@type": "DataDownload",
          contentUrl: d.contentUrl,
        })),
      };

      useSeoMeta({
        title: `Data Preview: ${datasetInfo.name}- Find Data Faster | Siftee.io`,
        description: `${datasetInfo.description}`,
        ogDescription: `${datasetInfo.description}`,
        ogTitle: `Data Preview: ${datasetInfo.name}- Find Data Faster | Siftee.io`,
        ogImage: window.location.href + "img/sifteeDarkMobile.c964311a.png",
      });

      const scriptElement = document.createElement("script");
      scriptElement.type = "application/ld+json";
      scriptElement.textContent = JSON.stringify(jsonLdContent);
      document.head.appendChild(scriptElement);
    } catch (error) {
      console.log("Error fetching files:", error);
    }
    // }

    const userQuery = query(collection(db, "oceanUsers"));
    const liveUsers = onSnapshot(userQuery, (snapshot) => {
      this.users = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          emailRef: doc.data().emailRef,
          userID: doc.data().userID,
          username: doc.data().username,
          planRegistered: doc.data().planRegistered,
          referralKey: doc.data().referralKey,
          downloadedFiles: doc.data().downloadedFiles,
          photoURL: doc.data().photoURL,
        };
      });
    });

    onUnmounted(liveUsers);
  },
  props: ["datasetID", "datasetCompany"],
};
</script>

<template>
  <div
    @click="expandData()"
    :style="{ display: expandedView == true ? 'block' : 'none' }"
    style="
      position: fixed;
      top: 0;
      z-index: 2;
      width: 100%;
      height: 100vh;
      background-color: #0f0f0f;
    "
  ></div>
  <div
    :style="{ display: menuVisible }"
    class="menuBlackout"
    style="
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      position: fixed;
      top: 0;
      z-index: 2;
    "
    @click="menuVisibleToggle()"
  ></div>

  <div
    :style="{ display: menuVisible }"
    class="animate__animated animate__fadeInRight hideScrollbar"
    style="
      animation-duration: 0.3s;
      width: 20vw;
      min-width: 300px;
      padding-left: 1vw;
      position: fixed;
      right: 0;
      top: 0;
      background-color: white;
      border-left: 1px;
      height: 100vh;
      overflow-y: scroll;
      z-index: 3;
    "
  >
    <div
      style="
        padding-top: 4vh;
        width: 100%;
        height: fit-content;
        padding-left: 1vw;
        line-height: 1;
      "
    >
      <p
        class="infoMinute"
        style="text-transform: capitalize; font-weight: 500"
      >
        Prepare your data layout
      </p>
      <p class="second">
        Use the checkboxes below to customise your report layout.
      </p>
      <div style="width: 100%; padding-top: 6vh">
        <p class="infoMinute" style="font-weight: 500">Add & Remove Columns</p>
        <div
          style="display: flex; justify-content: left; gap: 2vw; width: 100%"
        >
          <p
            class="mh"
            v-on:click="selectAll(true)"
            style="width: fit-content; color: #0645ad"
          >
            Select All
          </p>
          <p
            class="mh"
            v-on:click="selectAll(false)"
            style="width: fit-content; color: gray"
          >
            Unselect All
          </p>
        </div>

        <div style="height: 30vh; overflow-y: scroll">
          <div v-if="!isTabletView" style="height: 2vh"></div>
          <div
            v-for="(val, index) in getUniqueKeys(Object(tableClone)).sort()"
            :key="index"
          >
            <div
              class="checkbox-wrapper animate__animated animate__slideInDown"
              style="
                padding-left: 1vw;
                display: flex;
                animation-duration: 0.3s;
                gap: 2vw;
              "
            >
              <input
                type="checkbox"
                :id="val"
                :value="val"
                style="transform: scale(1.5)"
                class="valueCheckbox"
                @click="updateRemovalArr()"
              />
              <label
                :for="val"
                style="
                  padding-top: 1vh;
                  text-transform: capitalize;
                  font-weight: 500;
                "
                >{{ val }}</label
              >
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%; height: fit-content; margin-top: 6vh">
        <div>
          <!-- <table style="font-size:.8em;display:none" id="tableValues" ref="hiddenTable">
            <thead>
              <tr style="filter:blur(0px);position:sticky;top:0;background-color: #f8f6f9">
                <th style="border:none;;text-align:center;background-color:darkgray;color:white">#</th>
                <th class="nw"
                  v-for="(i, index) in Object.keys(Object((dataset.content)?.table[0])).filter(key => !removalArr.includes(key))"
                  :key="index"
                  style="text-align:left;text-transform:capitalize;color:rgba(0, 0, 0, 0.9);padding:1vh 1vw;border:none;border-bottom:1px solid lightgray">
                  <span v-if="!removalArr.includes(i)">{{ i }}</span>
                </th>
              </tr>
            </thead>

            <tbody>
              <template v-for="(row, index) in removeKeysFromObjects(table, removalArr)">
                <tr class="perRow" v-if="index < table.length" :key="index">
                  <td class="nw"
                    style="padding:1vh 1vw;overflow:hidden;border:none;background-color:darkgray;color:white;text-align:center">
                    {{ index + 1 }}</td>
                  <td class="nw"
                    v-for="header in Object.keys(Object((dataset.content)?.table[0])).filter(key => !removalArr.includes(key))"
                    :key="header" style="padding:1vh 1vw;overflow:hidden;border:none">{{ row[header] }}</td>
                </tr>
              </template>
            </tbody>
          </table> -->
        </div>

        <p
          v-if="!dataset.content?.premiumData"
          class="infoMinute"
          style="font-weight: 500"
        >
          Download Table As Shown<br /><span class="second"
            >(All {{ tableLength }} Rows)</span
          >
        </p>
        <p v-else class="infoMinute" style="font-weight: 500">
          Download Dataset<br />
        </p>
        <p v-if="!dataset.content?.premiumData" class="second">
          When you have your table just as you like it.
        </p>
        <!-- <button class="download-button" style="width:fit-content;padding:2vh"
          @click="download_table_as_csv('tableValues'); updateUserDownloaded(usID)">Download</button> -->

        <button
          v-if="!dataset.content?.premiumData"
          class="download-button"
          style="width: fit-content; padding: 2vh"
          @click="
            download_table_as_csv('tableValues');
            updateUserDownloaded(usID);
          "
        >
          Download Dataset (.csv)
        </button>

        <div>
          <button
            v-if="dataset.content?.premiumData && tableLength > 5"
            class="download-button"
            style="width: fit-content; padding: 2vh"
            @click="
              download_table_as_csvSample('tableValues');
              updateUserDownloaded(usID);
            "
          >
            Download Sample
          </button>
          <router-link
            to="/Pricing"
            v-else-if="dataset.content?.premiumData && tableLength <= 5"
            style="
              padding: 1vh;
              text-decoration: none;
              text-transform: capitalize;
            "
            class="download-button"
            >Sign up now to get access</router-link
          >
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="!isTabletView && dataset.content?.premiumData"
    style="
      position: fixed;
      bottom: 0;
      width: 100%;
      height: fit-content;
      padding-top: 3vh;
      background-color: white;
      box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: center;
      gap: 1vw;
      z-index: 5;
    "
  >
    <div
      style="
        width: fit-content;
        height: fit-content;
        border-radius: 10px;
        padding: 1vh;
        position: fixed;
        bottom: 14vh;
        left: 2vw;
        z-index: 4;
        background-color: #ffcc00;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 1vw;
      "
    >
      <p
        class="qs"
        style="
          font-weight: 700;
          vertical-align: middle;
          color: white;
          font-weight: bold;
          font-size: 0.9em;
          height: fit-content;
          margin-top: auto;
          margin-bottom: auto;
        "
      >
        Premium
      </p>
      <i class="fa-solid fa-star" style="color: white; font-size: 0.9em"></i>
    </div>
    <div id="selectRows" style="height: fit-content; width: 30%">
      <div
        class="it input-field nw"
        style="
          text-align: center;
          width: 90%;
          font-weight: 400;
          font-size: 0.8em;
          margin-bottom: 1vh;
        "
        data-label="Per Row"
      >
        ${{ dataset.content?.costPerRow }} /row
      </div>
      <p class="it" style="height: fit-content; font-size: 0.8em">
        <i class="fa-solid fa-box-archive" style="color: #709dd8"></i> Select
        your rows
      </p>
    </div>
    <div style="height: fit-content; width: 30%">
      <div
        class="it input-field nw"
        style="
          font-weight: 400;
          text-align: center;
          font-size: 0.8em;
          width: 90%;
          margin-bottom: 1vh;
        "
        data-label="Full Download"
      >
        ${{ dataset.content?.costPerRow * tableLength }}
      </div>
      <p class="it" style="height: fit-content; font-size: 0.8em">
        <i class="fa-solid fa-cloud-arrow-down" style="color: #709dd8"></i>
        Instant Download
      </p>
    </div>

    <div style="width: 30%">
      <router-link :to="{ path: $route.path + '/Configure' }">
        <button
          class="qs mh"
          style="
            text-decoration: none;
            font-weight: 700;
            width: 100%;
            height: 50%;
            border-radius: 10px;
            background-color: #709dd8;
            border: none;
            color: white;
            padding: 1vh;
          "
        >
          Configure
        </button>
      </router-link>
    </div>
  </div>

  <div
    style="display: flex; justify-content: space-between; padding: 0 5% 0 5%"
  >
    <div
      class="qs selectDisable"
      style="
        width: fit-content;
        padding-top: 4vh;
        margin-bottom: 6vh;
        overflow-x: hidden;
      "
      :style="{ width: isTabletView ? '70%' : '100%' }"
    >
      <!-- <div class="mobileView" style="height:7vh;width:100vh"></div> -->
      <!-- <div v-on:click="menuVisibleToggle()"
      style="position:fixed;right:1vw;width:50px;height:50px;z-index:4;text-align:center;padding:1vh;background: rgba(255, 255, 255, 0.18);backdrop-filter: blur(2px);border:1px solid lightgray;border-radius:50%;display:flex;justify-content: center;align-items: center;">
      <i class="menuBtn fa-solid fa-bars mh"></i>
    </div> -->

      <div style="display: flex; flex-direction: column">
        <div>
          <div
            style="
              display: flex;
              justify-content: left;
              gap: 2vw;
              margin-bottom: 2vh;
            "
          >
            <div style="display: flex; flex-direction: column; gap: 2vh">
              <div
                style="
                  display: flex;
                  gap: 6vw;
                  height: fit-content;
                  word-wrap: break-word;
                "
                :style="{ paddingTop: isTabletView ? '.5em' : '2em' }"
              >
                <div
                  v-if="brandImg(dataset.content?.sourceCompany) != undefined"
                  style="
                    width: 120px;
                    height: 120px;
                    min-width: 120px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    border: 1px solid #eaeaea;
                  "
                >
                  <img
                    :src="brandImg(dataset.content?.sourceCompany)"
                    style="
                      width: 70%;
                      height: auto;
                      object-fit: contain;
                      min-width: 70%;
                    "
                    alt="brandImg"
                  />
                </div>
                <div
                  class="source-header-container"
                  style="
                    height: fit-content;
                    display: inline;
                    white-space: normal;
                    word-wrap: break-all;
                    flex-wrap: wrap;
                  "
                >
                  <h1
                    class="it"
                    v-if="isTabletView"
                    style="
                      font-weight: 700;
                      margin-top: auto;
                      margin-bottom: auto;
                      text-transform: capitalize;
                      font-size: 28px;
                    "
                  >
                    {{ dataset.content?.sourceHeader }}
                  </h1>

                  <h1
                    v-else
                    style="
                      font-weight: 700;
                      margin-top: auto;
                      margin-bottom: auto;
                      text-transform: capitalize;
                      font-size: 1.2em;
                    "
                  >
                    {{ dataset.content?.sourceHeader }}
                  </h1>

                  <div
                    style="
                      display: flex;
                      gap: 3vw;
                      padding-top: 1vh;
                      align-items: center;
                      flex-wrap: wrap;
                      row-gap: 16px;
                    "
                    :style="{ 'font-size': isTabletView ? '1em' : '.8em' }"
                  >
                    <router-link
                      :to="'/Search/' + dataset.content?.sourceCompany"
                      class="b second"
                      style="
                        text-decoration: underline;
                        margin-top: auto;
                        margin-bottom: auto;
                        color: #709dd8;
                      "
                      >{{ dataset.content?.sourceCompany }}</router-link
                    >
                    <div
                      style="
                        display: flex;
                        height: min-content;
                        gap: 0.5vw;
                        align-items: center;
                      "
                    >
                      <i
                        class="fa-solid fa-calendar-days"
                        style="color: #709dd8"
                      ></i>
                      <p
                        v-if="dataset.content?.lastUpdated"
                        class="it"
                        style="
                          font-weight: 400;
                          margin-top: auto;
                          margin-bottom: auto;
                          color: #797979;
                        "
                      >
                        Updated: {{ formatDate(dataset.content?.lastUpdated) }}
                      </p>
                    </div>

                    <div
                      style="
                        display: flex;
                        gap: 0.5vw;
                        height: fit-content;
                        margin-bottom: auto;
                      "
                    >
                      <img
                        src="../assets/homeImg/tableViewScoreVector.svg"
                        draggable="false"
                        style="margin-top: auto; margin-bottom: auto"
                      />
                      <span class="it" style="font-weight: 400; color: #797979"
                        >Rating:
                        {{
                          Math.round(dataset.content?.score * 100) / 100
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <p>Tablet View {{ isTabletView }}</p> -->
        <div
          v-if="isTabletView"
          id="dataPreview"
          style="
            padding: 1vh;
            padding-bottom: 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: min-content;
            padding-top: 2vw;
          "
        >
          <p
            class="qs"
            style="font-weight: 700; color: #0f0f0f; font-size: 1.5em"
          >
            Data Preview
          </p>
          <p class="qs" style="font-weight: 400; color: #797979">
            ({{
              Object.keys(Object(dataset.content?.table[0])).length
            }}
            Headers, {{ tableLength }} Rows)
          </p>
        </div>

        <!-- Other content on the page -->

        <div
          id="tableContainer"
          :style="{
            border: dataSources.length === 0 ? '0px' : '1px solid #797979',
          }"
          style="
            width: 100%;
            overflow-y: auto;
            padding-top: 0;
            margin-right: auto;
            background-color: white;
            z-index: 3;
          "
        >
          <p
            class="infoMinute"
            v-if="
              getUniqueKeys(Object(table)).length == 0 && dataSources.length > 0
            "
            style="
              text-transform: capitalize;
              text-align: center;
              font-weight: 500;
              padding-top: 6vh;
              padding-bottom: 6vh;
            "
          >
            no headers selected!<br /><span class="second"
              >there are no values to preview.</span
            >
          </p>
          <p
            v-else-if="dataSources.length == 0"
            style="
              padding: 6vh 0 6vh 0;
              text-align: center;
              font-weight: 500;
              font-size: 1.2em;
            "
          >
            Loading Table...<br /><img src="../assets/loading.gif" />
          </p>
          <!-- {{ dataset.content?.table.length }} | shipsdna is 1 -->

          <table
            v-if="dataset.content?.table?.length > 1"
            style="position: relative; width: 100%"
            class="tableTrack it"
          >
            <div
              v-if="!isLoggedin"
              style="
                position: absolute;
                top: 30%;
                left: 30%;
                width: 18vw;
                min-height: 20vh;
                height: fit-content;
                border-radius: 20px;
                z-index: 2;
                background-color: #719ed8;
                text-align: center;
                color: white;
                padding: 1vw 0 1vw 0;
              "
            >
              <img
                src="../assets/homeImg/padLock.svg"
                style="padding-bottom: 2vh"
              />
              <h5 class="qs" style="font-weight: 700; padding-bottom: 1vw">
                Sign In To See The Preview
              </h5>
              <a href="/Login">
                <button
                  class="it mh"
                  style="
                    font-weight: 700;
                    padding: 1vh;
                    background-color: white;
                    border-radius: 10px;
                    color: black;
                    border: none;
                    width: 40%;
                  "
                >
                  Sign In
                </button>
              </a>
            </div>

            <thead>
              <tr
                style="
                  filter: blur(0px);
                  position: sticky;
                  top: 0;
                  background-color: white;
                "
              >
                <!-- Add an empty header cell for the index column -->
                <th
                  style="
                    border: none;
                    text-align: center;
                    min-width: 34px;
                    background-color: white;
                    color: #0f0f0f;
                    border-bottom: 1px solid #797979;
                  "
                >
                  #
                </th>
                <th
                  class="nw qs"
                  v-for="(i, index) in Object.keys(
                    Object(dataset.content?.table[0])
                  ).filter((key) => !removalArr.includes(key))"
                  :key="index"
                  style="
                    text-align: left;
                    border: 1px solid #797979;
                    background-color: white;
                    font-weight: 700;
                    text-transform: capitalize;
                    color: #197ec7;
                    padding: 1vh 1vw;
                    border-top: none;
                  "
                >
                  <!-- <span v-if="!removalArr.includes(i)">{{ i }}</span>  -->
                  <span>{{ i }}</span>
                  <!-- the above: Only show the header if removal Array (the checkbox results) does not contain it -->
                </th>
              </tr>
            </thead>

            <tbody
              :style="{ filter: isLoggedin ? 'none' : 'blur(6px)' }"
              v-if="tableLength > 5"
              :class="{ premiumBlur: dataset.content?.premiumData }"
            >
              <template
                v-for="(row, index) in removeKeysFromObjects(
                  table,
                  removalArr
                ).slice(0, 10)"
              >
                <tr class="perRow" v-if="index < 10" :key="index">
                  <td
                    class="nw it"
                    style="
                      width: 34px;
                      overflow: hidden;
                      width: 34px;
                      border: none;
                      background-color: #e7f1ff;
                      color: black;
                      font-weight: 400;
                      text-align: center;
                      filter: blur(0px) !important;
                    "
                  >
                    {{ index + 1 }}
                  </td>
                  <!-- Add index number cell -->

                  <!-- It's here that's generating empty cells when the column is hidden... Fix this area -->
                  <td
                    class="nw dataHere"
                    v-for="header in Object.keys(
                      Object(dataset.content?.table[0])
                    ).filter((key) => !removalArr.includes(key))"
                    :key="header"
                    style="padding: 1vh 1vw; overflow: hidden; border: none"
                  >
                    {{ row[header] }}
                  </td>
                </tr>
              </template>
            </tbody>
            <!-- <pre>{{ removeKeysFromObjects(table, removalArr) }}</pre> -->

            <tbody
              :style="{ filter: isLoggedin ? 'none' : 'blur(6px)' }"
              v-if="tableLength <= 5 && dataset.content?.premiumData"
              :class="{ LT5Blur: dataset.content?.premiumData }"
            >
              <template
                v-for="(row, index) in removeKeysFromObjects(table, removalArr)"
              >
                <tr class="perRow" v-if="index < 10" :key="index">
                  <td
                    class="nw"
                    style="
                      width: 34px;
                      overflow: hidden;
                      border: none;
                      background-color: #e7f1ff;
                      color: black;
                      filter: blur(0px) !important;
                    "
                  >
                    {{ index + 1 }}
                  </td>
                  <!-- Add index number cell -->
                  <template v-if="dataset.content?.premiumData">
                    <td
                      class="nw dataHere"
                      v-for="header in Object.keys(
                        Object(dataset.content?.table[0])
                      ).filter((key) => !removalArr.includes(key))"
                      :key="header"
                      style="
                        padding: 1vh 1vw;
                        max-width: 600px;
                        overflow: hidden;
                        border: none;
                      "
                    >
                      Hidden Data
                    </td>
                  </template>

                  <template v-else-if="!dataset.content?.premiumData">
                    <td
                      class="nw"
                      v-for="header in Object.keys(
                        Object(dataset.content?.table[0])
                      ).filter((key) => !removalArr.includes(key))"
                      :key="header"
                      style="padding: 1vh 1vw; overflow: hidden; border: none"
                    >
                      {{ row[header] }}
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
            <!-- <pre>{{ removeKeysFromObjects(table, removalArr) }}</pre> -->
            <tbody
              :style="{ filter: isLoggedin ? 'none' : 'blur(6px)' }"
              v-if="tableLength <= 5 && !dataset.content?.premiumData"
            >
              <template
                v-for="(row, index) in removeKeysFromObjects(table, removalArr)"
              >
                <tr class="perRow" v-if="index < 10" :key="index">
                  <td
                    class="nw"
                    style="
                      width: 34px;
                      overflow: hidden;
                      border: none;
                      background-color: darkgray;
                      color: white;
                      text-align: center;
                      filter: blur(0px) !important;
                    "
                  >
                    {{ index + 1 }}
                  </td>
                  <!-- Add index number cell -->
                  <td
                    class="nw"
                    v-for="header in Object.keys(
                      Object(dataset.content?.table[0])
                    ).filter((key) => !removalArr.includes(key))"
                    :key="header"
                    style="
                      padding: 1vh 1vw;
                      max-width: 600px;
                      overflow: hidden;
                      border: none;
                    "
                  >
                    {{ row[header] }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>

          <table
            v-else
            class="qs"
            style="
              font-size: 0.8em;
              position: relative;
              width: 100%;
              border-collapse: collapse;
            "
          >
            <!-- present if table only contains ONE ROW, headers are on the first column and the contents on the right -->
            <thead>
              <tr>
                <th style="width: 50%; padding: 0.5vw; padding-left: 1vw">
                  Header
                </th>
                <th style="width: 50%; padding: 0.5vw; padding-left: 1vw">
                  Value
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="width: fit-content"
                v-for="(i, index) in Object.keys(
                  Object(dataset.content?.table[0])
                ).filter((key) => !removalArr.includes(key))"
                :key="index"
              >
                <td
                  class="b"
                  style="
                    text-align: left;
                    background-color: white;
                    text-transform: capitalize;
                    color: #197ec7;
                    padding: 1vh 1vw;
                    border: none;
                    border-bottom: 1px solid lightgray;
                  "
                >
                  {{ i }}
                </td>
                <td
                  v-if="!dataset.content?.premiumData"
                  style="width: 50%; padding: 0px 1vw 0px 1vw; text-align: left"
                >
                  {{ table[0][i] }}
                </td>
                <td
                  v-if="dataset.content?.premiumData"
                  class="LT5Blur"
                  style="width: 50%; padding: 0px 1vw 0px 1vw; text-align: left"
                >
                  <span v-if="table[0][i]">Hidden Content</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style="
            display: flex;
            gap: 1vh;
            height: fit-content;
            padding-top: 2vh;
            z-index: 3;
            width: fit-content;
            margin-bottom: 2vh;
          "
          :style="{
            'margin-left': isTabletView ? '0' : 'auto',
            'margin-right': isTabletView ? '0' : 'auto',
          }"
        >
          <button
            v-if="!dataset.content?.premiumData"
            @click="fetchDownload(downloadURL)"
            class="qs"
            :class="{ mh: isLoggedin, disabled: !isLoggedin }"
            :disabled="!isLoggedin"
            :style="{
              backgroundColor: isLoggedin ? '#709DD8' : '#FFFFFF',
              color: isLoggedin ? 'white' : '#BCBCBC',
              display: 'flex',
              gap: '1vh',
              width: 'fit-content',
              padding: '1vh',
              borderRadius: '10px',
              border: '1px solid #709DD8',
              fontWeight: '700',
              alignItems: 'center',
            }"
          >
            Download File
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>

          <button
            v-if="!expandedView"
            @click="expandData()"
            class="qs"
            :class="{ mh: isLoggedin, disabled: !isLoggedin }"
            :disabled="!isLoggedin"
            :style="{
              backgroundColor: isLoggedin ? '#709DD8' : '#FFFFFF',
              color: isLoggedin ? 'white' : '#BCBCBC',
            }"
            style="
              display: flex;
              gap: 1vh;
              width: Fit-content;
              padding: 1vh;
              border: 1px solid #709dd8;
              border-radius: 10px;
              font-weight: 700;
              align-items: center;
            "
          >
            Expand Data
            <i class="fa fa-expand" aria-hidden="true"></i>
          </button>
          <button
            v-else
            @click="expandData()"
            class="qs"
            :class="{ mh: isLoggedin, disabled: !isLoggedin }"
            :disabled="!isLoggedin"
            :style="{
              backgroundColor: isLoggedin ? '#709DD8' : '#FFFFFF',
              color: isLoggedin ? 'white' : '#BCBCBC',
            }"
            style="
              display: flex;
              gap: 1vh;
              width: Fit-content;
              padding: 1vh;
              border: 1px solid #709dd8;
              border-radius: 10px;
              font-weight: 700;
              align-items: center;
            "
          >
            Minimize Data
            <i class="fa fa-minimize" aria-hidden="true"></i>
          </button>
        </div>

        <div style="display: flex; gap: 1vw; padding-top: 2vw">
          <div style="display: flex; flex-direction: column">
            <div
              class="it"
              style="
                display: flex;
                justify-content: left;
                color: #797979;
                font-weight: 400;
              "
            >
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  text-align: center;
                  padding: 0 2vw 0 2vw;
                  line-height: 1;
                "
              >
                <p class="it" style="font-size: 0.8em">Volume</p>
                <span class="b qs" style="color: #0f0f0f"
                  >{{ tableLength }}<br /><span style="font-size: 0.8em"
                    >Rows</span
                  ></span
                >
              </div>
              <div style="display: flex" v-if="dataset.content?.premiumData">
                <div
                  v-if="dataset.content?.sourceCompany != 'ShipsDNA'"
                  style="
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    padding: 0 1vw 0 1vw;
                    line-height: 1;
                  "
                >
                  <p class="it" style="font-size: 0.8em">Cost</p>
                  <span class="b qs" style="color: #0f0f0f"
                    >${{ dataset.content?.costPerRow }}<br /><span
                      style="font-size: 0.8em"
                      >per Row</span
                    ></span
                  >
                </div>

                <div
                  v-if="dataset.content?.sourceCompany != 'ShipsDNA'"
                  style="
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    padding: 0 1vw 0 1vw;
                    line-height: 1;
                  "
                >
                  <p class="it" style="font-size: 0.8em">Total Cost</p>
                  <span class="b qs" style="color: #0f0f0f"
                    >${{ (tableLength * dataset.content?.costPerRow).toFixed(2)
                    }}<br /><span style="font-size: 0.8em"
                      >Full Download</span
                    ></span
                  >
                </div>
              </div>
              <!-- <div
                  style="display:flex;flex-direction:column;text-align:center;padding:0 1vw 0 1vw">
                  <p style="font-size:1.2em">Score</p>
                  <div
                    style="width:30px;height:30px;border-radius:50%;display:flex;justify-content: center;align-items: center;margin-left: auto;margin-right: auto;"
                    :style="{ backgroundColor: checkScore((dataset.content)?.score) }">
                    <p class="wt" style="margin: 0; text-align: center;">{{ (dataset.content)?.score }}</p>
                  </div>
                </div> -->
            </div>
            <!-- Separator, don't use hr  so you can modify the padding and margins more consistently throughout the project-->
            <div
              style="
                width: 100%;
                border-bottom: 2px solid #f4f4f4;
                margin: 2vh 0 2vh 0;
              "
            ></div>
            <div
              style="
                height: fit-content;
                text-align: left;
                padding-top: 2vh;
                display: flex;
                flex-direction: column;
                gap: 2vh;
              "
            >
              <p
                id="description"
                style="font-weight: 700; font-size: 1.2em"
                class="b qs"
              >
                Description
              </p>
              <div
                class="it"
                style="width: 100%; height: fit-content; overflow: scroll"
              >
                {{ dataset?.content?.description }}
              </div>
            </div>
            <div
              style="
                width: 100%;
                border-bottom: 2px solid #f4f4f4;
                margin: 2vh 0 2vh 0;
              "
            ></div>

            <div
              id="dictionarySection"
              class="qs"
              style="width: 100%; display: flex; gap: 5vw"
            >
              <div style="width: fit-content">
                <p
                  id="dataDictionary"
                  style="text-align: left; font-weight: 550; font-size: 1.2em"
                  class="b"
                >
                  Data Dictionary
                </p>
                <div
                  style="
                    margin-left: auto;
                    margin-right: auto;
                    width: 100%;
                    height: fit-content;
                  "
                >
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      width: 100%;
                      border: 1px solid #eaeaea;
                      border-radius: 20px;
                    "
                  >
                    <div
                      style="
                        width: 100%;
                        display: flex;
                        flex-wrap: wrap;
                        min-width: 250px;
                      "
                    >
                      <p
                        style="
                          padding: 1vh 1vw 1vh 1vh;
                          font-weight: 550;
                          font-size: 1.2em;
                          text-transform: capitalize;
                        "
                      >
                        {{ dataset.content?.sourceHeader }}
                      </p>
                    </div>
                    <div
                      class="b"
                      style="
                        padding-left: 1vw;
                        padding-right: 1vw;
                        gap: 6vw;
                        display: flex;
                        justify-content: space-between;
                        background-color: rgba(245, 245, 245, 0.63);
                        border-bottom: 1px solid lightgray;
                        padding: 1vh;
                      "
                    >
                      <p style="margin-top: auto; margin-bottom: auto">
                        Header
                      </p>
                      <p style="margin-top: auto; margin-bottom: auto">
                        Attribute
                      </p>
                    </div>
                    <div
                      style="
                        width: 100%;
                        height: fit-content;
                        min-height: 10vh;
                        overflow-y: scroll;
                      "
                    >
                      <div
                        id="attributeTable"
                        class="mh"
                        v-for="(value, key) in sortedDataDictionary"
                        :key="key"
                        style="
                          gap: 6vw;
                          padding: 1vh;
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          border-bottom: 1px solid lightgray;
                        "
                      >
                        <p style="margin-top: auto; margin-bottom: auto">
                          {{ key }}
                        </p>
                        <p style="margin-top: auto; margin-bottom: auto">
                          {{ value }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div style="width:30vw;height:50vh;background-color:red">
                </div> -->
            </div>

            <div
              style="
                width: 100%;
                border-bottom: 2px solid #f4f4f4;
                margin: 2vh 0 2vh 0;
              "
            ></div>
            <div
              v-if="
                String(dataset.content?.sourceHeader)
                  .split(' ')
                  .includes('Singapore')
              "
            >
              <p
                style="text-align: left; font-weight: 550; font-size: 1.2em"
                class="b"
              >
                Disclaimer
              </p>
              <p style="font-size: 0.8em; word-wrap: normal">
                [Contains information from
                {{ dataset.content?.sourceHeader }} accessed on
                {{ Date(dataset.content?.lastUpdated).toLocaleString() }}
                from {{ dataset.content?.sourceCompany }}
                which is made available under the terms of the Singapore Open
                Data Licence version 1.0
                https://beta.data.gov.sg/open-data-license]*
              </p>
            </div>
          </div>
        </div>

        <!-- above here -->
      </div>
    </div>

    <div
      v-if="isTabletView"
      style="
        width: 20%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 2vw;
        position: sticky;
        top: 2vw;
      "
    >
      <div style="height: fit-content; padding-top: 5vh">
        <div
          class="qs"
          style="font-weight: 700; width: 100%; height: 6vh; font-size: 1.2em"
        >
          List of Contents
        </div>
        <div
          class="qs"
          style="line-height: 0.7; color: #0f0f0f; padding-left: 1vw"
        >
          <p
            class="mh"
            @click="scrollToID(content.idAttributed)"
            v-for="(content, index) in listOfContents"
            :key="index"
          >
            {{ content.name }}
          </p>
        </div>
      </div>
      <div
        style="
          height: fit-content;
          border-radius: 20px;
          background-color: white;
          padding: 1vw;
          display: flex;
          flex-direction: column;
          gap: 1vw;
        "
      >
        <div v-if="dataset.content?.premiumData == 0 && isLoggedin">
          <img
            src="../assets/homeImg/siftBanner.svg"
            style="width: 50%; height: auto"
          />
          <p class="it" style="color: #4f4f4f; font-weight: 400">
            This is a free data set available under creative commons licensing
          </p>
          <button
            class="it mh"
            @click="fetchDownload(downloadURL)"
            style="
              width: 100%;
              padding: 1vh;
              background-color: #709dd8;
              color: white;
              border: none;
              border-radius: 10px;
              font-weight: 700;
            "
          >
            Free Download
          </button>
        </div>
        <div v-else-if="dataset.content?.premiumData == 1 && isLoggedin">
          <div
            style="
              color: white;
              width: fit-content;
              height: fit-content;
              border-radius: 10px;
              padding: 1vh;
              background-color: #ffcc00;
              display: flex;
              align-items: center;
              gap: 1vh;
            "
          >
            <i class="fa fa-star" aria-hidden="true"></i>
            <p
              class="qs"
              style="font-weight: 700; margin-top: auto; margin-bottom: auto"
            >
              Premium
            </p>
          </div>
          <div
            class="it"
            style="
              font-weight: 400;
              display: flex;
              align-items: center;
              gap: 1vh;
              padding-top: 2vh;
            "
          >
            <i class="fa-solid fa-table" style="color: #709dd8"></i>
            <p
              style="height: fit-content; margin-top: auto; margin-bottom: auto"
            >
              Select your rows
            </p>
          </div>
          <div
            class="it"
            style="
              font-weight: 400;
              display: flex;
              align-items: center;
              gap: 1vh;
              padding-top: 2vh;
            "
          >
            <i class="fa-solid fa-download" style="color: #709dd8"></i>
            <p
              style="height: fit-content; margin-top: auto; margin-bottom: auto"
            >
              Instant Download
            </p>
          </div>
          <div style="display: flex; gap: 1vh; padding-top: 2vh">
            <div
              class="it input-field nw"
              style="font-weight: 400; font-size: 0.8em"
              data-label="Price"
            >
              ${{ dataset.content?.costPerRow }} /row
            </div>
            <div
              class="it input-field nw"
              style="font-weight: 400; font-size: 0.8em"
              data-label="Price"
            >
              ${{ dataset.content?.costPerRow * tableLength }} /full download
            </div>
          </div>
          <router-link :to="{ path: $route.path + '/Configure' }">
            <button
              class="qs mh"
              style="
                text-decoration: none;
                font-weight: 700;
                width: 100%;
                border-radius: 10px;
                background-color: #709dd8;
                border: none;
                color: white;
                margin-top: 2vh;
                padding: 1vh;
              "
            >
              Start Now!
            </button>
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div
    style="
      display: flex;
      justify-content: space-between;
      padding: 1vw;
      margin-left: auto;
      margin-right: auto;
    "
  ></div>
</template>
