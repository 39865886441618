<script setup>
import { defineProps, watch } from 'vue';
import { onSnapshot, collection, query } from 'firebase/firestore';
import { getAuth } from '@firebase/auth';
import { db } from '@/configs.js';
import { onMounted, onUnmounted, ref } from 'vue';

const props = defineProps({
    type: Number
})

const auth = getAuth()
const users = ref([]);
const currentUser = ref([])
const currentUserSubscriptions = ref([])
const currentUserPlan = ref([])

const getCurrentUserData = (userID) => {
    return users.value.find(u => u.userID === userID)
}

onMounted(async () => {
    const userQuery = query(collection(db, "oceanUsers"));
    const liveUsers = onSnapshot(userQuery, (snapshot) => {
        users.value = snapshot.docs.map((doc) => {
            const docID = {id: doc.id}
            const docData = doc.data()
            const data = {...docData, ...docID}
            return data
        })
    onUnmounted(liveUsers);
    })
})

watch(users, () => {
    currentUser.value = getCurrentUserData(auth.currentUser.uid);
    currentUserSubscriptions.value = currentUser.value["subscriptionPlan"]
    currentUserPlan.value = currentUser.value["planRegistered"]
})
</script>

<template>
    <!-- Subscribed to Siftee only -->
    <div v-if="props.type == 1">
        <h2 class="profile-sub-heading qs"> You are currently subscribed to Siftee </h2>
        <p class="profile-card-para" style="color: #4F4F4F; font-weight: 400;"> 
            Enjoy our premium content as much as you like </p>
    </div>
    <!-- Not subscribed to Siftee, but premium vendors -->
    <div v-if="props.type == 2">
        <h2 class="profile-sub-heading qs"> Current Subscriptions: </h2>
        <li v-for="(subscription, i) in currentUserSubscriptions" :key=i class="profile-card-para">
            {{ subscription }}
        </li>
    </div>
    <!-- Subscribed to both Siftee and other vendors -->
    <div v-if="props.type == 3">
        <h2 class="profile-sub-heading qs"> Current Subscriptions: </h2>
        <li class="profile-card-para"> Siftee - {{ currentUserPlan }}</li>
        <li v-for="(subscription, i) in currentUserSubscriptions" :key=i class="profile-card-para">
            {{ subscription }}
        </li>
    </div>
</template>