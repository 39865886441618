<template>
  <footer class="footer os">
    <div
      style="
        width: 89vw;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        line-height: 4;
        padding-top: 2vh;
      "
    >
      <img
        loading="lazy"
        src="../assets/logos/sifteeLightTrimmed.webp"
        alt="Siftee Logo"
        style="width: 165px; height: auto; padding: 2vw 0 2vw 0"
      />
      <div
        style="width: 100%; height: fit-content; display: flex; flex-wrap: wrap"
      >
        <div
          class="it"
          style="
            font-weight: 400;
            width: fit-content;
            height: 100%;
            padding-left: 1vw;
            padding-right: 2vw;
            color: white;
            line-height: 1;
            display: flex;
            flex-direction: column;
            justify-content: left;
          "
        >
          <p>+65 9772 3852</p>
          <address>
            <p>@ 15D Balmoral Road,</p>
            <p>#01-16 Belmond Green SINGAPORE 259819</p>
          </address>

          <div
            style="
              display: flex;
              gap: 1vh;
              align-items: center;
              padding-bottom: 6vh;
            "
          >
            <a
              href="https://www.youtube.com/@Siftee"
              target="_blank"
              aria-label="Visit Siftee on YouTube, opens in a new tab"
            >
              <img
                loading="lazy"
                src="../assets/homeImg/logo-youtube.svg"
                alt="Youtube Logo"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/siftee/"
              target="_blank"
              aria-label="Visit Siftee on LinkedIn, opens in a new tab"
            >
              <img
                loading="lazy"
                src="../assets/homeImg/logo-linkedin.svg"
                alt="LinkedIn Logo"
              />
            </a>
            <a
              href="https://twitter.com/Siftee_io/"
              target="_blank"
              aria-label="Visit Siftee on Twitter, opens in a new tab"
            >
              <img
                loading="lazy"
                src="../assets/homeImg/logo-twitter.svg"
                alt="Twitter Logo"
              />
            </a>
          </div>
        </div>
        <div
          style="
            width: 70%;
            height: 100%;
            display: flex;
            align-items: stretch;
            justify-content: left;
            flex-wrap: wrap;
            padding-left: 1vw;
          "
        >
          <div
            style="
              line-height: 1.5;
              display: flex;
              flex-direction: column;
              padding: 0 2vw 0 0vw;
              gap: 1vh;
            "
          >
            <p
              style="font-size: 20px; font-weight: 700; color: #709dd8"
              class="qs"
            >
              About
            </p>
            <a href="/PrivacyPolicy" target="_blank" style="color: inherit"
              >Privacy Policy</a
            >
            <a href="/BookADemo" target="_blank" style="color: inherit"
              >Book a Demo</a
            >
          </div>

          <div
            style="
              line-height: 1.5;
              display: flex;
              flex-direction: column;
              padding: 0 2vw 0 2vw;
              gap: 1vh;
            "
          >
            <p
              style="font-size: 20px; font-weight: 700; color: #709dd8"
              class="qs"
            >
              Pricing
            </p>
            <a
              href="/Referred_Plans/shipsDNA2309"
              target="_blank"
              style="color: inherit"
              >ShipsDNA</a
            >
          </div>
          <!-- <div style="width:20%;line-height:1">
            <p style="font-size:20px;font-weight:700;color:#709DD8" class="qs">Siftee</p>
            <p>Lorem Ipsum</p>
            <p>Lorem Ipsum</p>
            <p>Lorem Ipsum</p>
            <p>Lorem Ipsum</p>
        </div>  -->
        </div>
      </div>
      <div
        style="width: 100%; border-bottom: 1px solid white; margin-top: 2vh"
      ></div>
      <div style="display: flex; justify-content: right">
        <p style="color: white; text-align: right">
          Copyright © 2023, All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
      companyName: "siftee",
    };
  },
  computed: {
    isTabletView() {
      return window.innerWidth >= 1024;
    },
  },
};
</script>

<style scoped>
footer {
  background-color: #333333;
  color: #fff;
}

p {
  font-size: 14px;
}
</style>
