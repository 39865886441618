<script setup>
var usID = ref('');
var usEmail = ref('');
let auth;
const isLoggedin = ref(false);
var emailVerified = ref('');
var loggedUser = ref('')
const hideDropdownMenu = ref(false)

onMounted(() => {
  auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      loggedUser.value = user
      isLoggedin.value = true;
      usID.value = user.uid;
      usEmail.value = user.email;
      emailVerified.value = user.emailVerified;

    }
    else {
      isLoggedin.value = false;
    }
    return {
      usID,

    }
  })
})

const handleSignOut = () => {
  signOut(auth).then(() => {
    this.$router.push('/Login')
  });
}

const closeDropdownMenu = () => {
    hideDropdownMenu.value = true;
}

</script>

<script>
import { onMounted, onUnmounted, ref } from 'vue'
import { getAuth, onAuthStateChanged, signOut, sendEmailVerification } from '@firebase/auth';
import { getFirestore, onSnapshot, collection, query, doc, updateDoc } from 'firebase/firestore';
import { app } from '@/configs.js';

const db = getFirestore(app)
export default {

  data() {
    return {
      menuVisible: 'none',
      mobileMenuVisible: 'none',
      users: ref([]),
      verificationCodes: ref([]),
      user: null,
      referralKeyContent: ref(''),
      usernameContent: ref(''),
      textVisible: 'none',
      pricingPlans: [
        { id: 1, name: "Free", discount: "None", price: "0", filters: "A few free searches", features: ["Search", "10 Filters", "Download Public Data"] },
        { id: 2, name: "Basic", discount: "Or $144 / year at 70% off", price: 39.99, filters: "20 Filters", features: ["Search", "20 Filters", "Download Public Data", "Download Paid Data"] },
        { id: 3, name: "Premium", discount: "Or $360 / year at 70% off", price: 99.99, filters: "100 Filters", promoted: true, features: ["Search", "100 Filters", "Download Public Data", "Download Paid Data"] },
        { id: 4, name: "Enterprise", discount: "Or $1,800 / year at 70% off", price: 499.99, filters: "Unlimited", features: ["Search", "Unlimited Filters", "Download Public Data", "Download Paid Data", "Unlimited Filters", "Save to favourites", "Custom alerts when data is updated"] }
      ],
      errMsg: null
    }
  },

  methods: {
    sendEmailVerification(user) {
      sendEmailVerification(user);
    },
    sentTextVisible() {
      this.textVisible = this.textVisible == 'none' ? 'block' : 'none'
      setTimeout(() => {
        this.textVisible = 'none';
      }, 3000);
    },
    go(val) {
      this.$router.push({ path: val })
    },

    toggleMenu(usid) {
      this.menuVisible = this.menuVisible == 'none' ? 'block' : 'none';
      this.referralKeyContent = this.returnUser(usid).referralKey
      this.usernameContent = this.returnUser(usid).username
    },
    toggleMobileMenu() {
      this.mobileMenuVisible = this.mobileMenuVisible == 'none' ? 'block' : 'none'
    },
    closeMenu() {
      this.menuVisible = 'none'
    },
    closeMobileMenu() {
      this.mobileMenuVisible = 'none'
    },

    checkGo(val, isLoggedin) {
      if (isLoggedin) {
        this.$router.push({ path: val })
      }
      else {
        this.$router.push({ path: '/Register' })
      }
    },
    returnUser(userID) {
      return Object(this.users.find(u => u.userID === userID))
    },

    updateProfile(referralCode, id) {
      if (this.returnPositiveCode().includes(referralCode)) {
        updateDoc(doc(db, 'oceanUsers', id), {
          referralKey: referralCode
        })
        this.errMsg = 'Successfully updated key'
      }
      else {
        this.errMsg = 'Invalid referral key'

      }
      setTimeout(() => {
        this.errMsg = '';
      }, 2000);
    },
    returnPositiveCode() {
      return this.verificationCodes.filter(obj => Object.prototype.hasOwnProperty.call(obj, 'code')).map(obj => obj.code);
    }


  },

  async mounted() {
    // const userQuery = query(collection(db, "oceanUsers"), where("userID", "==", 'dIrVxFNtyqhrqjJoOK3PXqecRG92'));
    const userQuery = query(collection(db, "oceanUsers"));
    const liveUsers = onSnapshot(userQuery, (snapshot) => {
      this.users = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          emailRef: doc.data().emailRef,
          userID: doc.data().userID,
          username: doc.data().username,
          planRegistered: doc.data().planRegistered,
          referralKey: doc.data().referralKey,
          photoURL: doc.data().photoURL
        }
      });
    })

    const codesQuery = query(collection(db, "oceanVerificationCodes"));
    const liveCodes = onSnapshot(codesQuery, (snapshot) => {
      this.verificationCodes = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          code: doc.data().code,
          description: doc.data().description,
          usageCount: doc.data().usageCount


        }
      });
    })

    onUnmounted(liveCodes, liveUsers)
  }

}

</script>

<template style="z-index:2">
  <div v-show="$route.name !== 'loading'">

    <div class="tabletView nw selectDisable" :style="{
      width: '100%',
      position: 'relative',
      height: '90px',
      color: $route.name == 'oceanDatasets' || $route.name == 'Search' ? 'white' : 'black',
      backgroundColor: $route.name == 'oceanDatasets' || $route.name == 'Search' || $route.name == 'settings' ? '#709DD8' : '',
    }">

      <div class="flex-menu qs snipcss-YHFZE style-OdFay" id="style-OdFay"
        style="width:88%;margin-left: auto;margin-right: auto;">
        <div class="menu style-ajFrL" id="style-ajFrL" style="padding-top:.1em;margin-left:.1vw">
          <a class="logo" href="/" style="text-decoration: none;" draggable="false">
            <div id="style-ZtbLL" class="style-ZtbLL" style="width:160px;height:60px;margin-right: 3.4vw">
              <img v-show="$route.name !== 'oceanDatasets' && $route.name !== 'Search' && $route.name !== 'settings'" alt="logo"
                class="logo-img style-dUVKd" style="width:q80%;height:auto" src="../assets/logos/sifteeDark.png"
                id="style-dUVKd">
            </div>
          </a>
          <div id="style-JoVKj" class="style-JoVKj">
            <div data-id="asde5" class="style-yHg6y" id="style-yHg6y">
              <a class="textHeader" href="/" target="_self" style="text-decoration: none;" draggable="false" :style="{color: $route.name == 'oceanDatasets' || $route.name == 'Search' || $route.name == 'settings' ? 'white' : 'black'}">
                <div class="font-semibold style-PZnwq" data-key="text" data-id="asde5" id="style-PZnwq">
                  <span :style="{color: $route.name == 'oceanDatasets' || $route.name == 'Search' || $route.name == 'settings' ? 'white' : 'black',}">Home</span>
                </div>
              </a>
            </div>
          </div>

          <div id="style-tHtbg" class="style-tHtbg">
            <div data-id="746832" class="style-WKAtt" id="style-WKAtt">
              <a class="textHeader" href="/#graphicSection" target="_self" style="text-decoration: none;"
                draggable="false" :style="{color: $route.name == 'oceanDatasets' || $route.name == 'Search' ? 'white' : 'black'}">
                <div class="font-semibold style-si5yD" data-key="text" data-id="746832" id="style-si5yD">
                  <span :style="{color: $route.name == 'oceanDatasets' || $route.name == 'Search' || $route.name == 'settings' ? 'white' : 'black',}">Features</span>
                </div>
              </a>
            </div>
          </div>


          <div id="style-eRCoR" class="style-eRCoR">
            <div data-id="27357" class="style-a9Sky" id="style-a9Sky">
              <a class="textHeader" href="/#mc_embed_signup" target="_self" style="text-decoration: none;"
                draggable="false" :style="{color: $route.name == 'oceanDatasets' || $route.name == 'Search' ? 'white' : 'black'}">
                <div class="font-semibold style-fomm5" data-key="text" data-id="27357" id="style-fomm5">
                  <span :style="{color: $route.name == 'oceanDatasets' || $route.name == 'Search' || $route.name == 'settings' ? 'white' : 'black',}">Contact Us</span>
                </div>
              </a>
            </div>
          </div>
          <div id="style-Jrg5o" class="style-Jrg5o">
            <div data-id="869454" class="style-8X676" id="style-8X676">
              <a class="textHeader" href="/Pricing" target="_self" style="text-decoration: none;" draggable="false" :style="{color: $route.name == 'oceanDatasets' || $route.name == 'Search' ? 'white' : 'black'}">
                <div class="font-semibold style-o917n" data-key="text" data-id="869454" id="style-o917n">
                  <span :style="{color: $route.name == 'oceanDatasets' || $route.name == 'Search' || $route.name == 'settings' ? 'white' : 'black',}">Pricing</span>
                </div>
              </a>
            </div>
          </div>

        
        </div>
        <div>
          <div class="flex" style="margin-top:.2vh">
            <div v-if="!isLoggedin && $route.name != 'l'" data-id="bksn5" data-key="ComboBox" label="Name"
              class="button button-extra" style="background-color:#4573c4;color:white;height:fit-content"
              @click="(go('/Register'))">
              <div class="qs"
                style="font-weight:700;color:white;width:fit-content;margin-top: auto;margin-bottom: auto;padding:1vh;display:flex;gap:.5vw;align-items: center;">
                <p style="height:fit-content;margin-top: auto;margin-bottom: auto">Sign Up</p>

              </div>


            </div>

            <div v-if="isLoggedin" data-id="bksn5" data-key="ComboBox" label="Name" class="button button-extra loggedIn">
              <div @click="go('/settings')" class="qs"
                style="font-weight:700;color:#709DD8;background-color:white;height:70%;align-items:center;width:fit-content;margin-top: auto;margin-bottom: auto;border-radius:20px;border:1px solid #709DD8;padding:2vh;display:flex;gap:.5vw;">
                <div style="width:20px;height:20px;border-radius:50%;overflow:hidden">
                  <img v-if="returnUser(usID)?.photoURL && String(returnUser(usID)?.photoURL) != 'undefined'"
                    :src="String(returnUser(usID)?.photoURL)" referrerpolicy="no-referrer"
                    style="width:100%;height:100%" />
                  <img v-else src="https://p7.hiclipart.com/preview/698/411/670/computer-icons-user-profile-yak.jpg"
                    style="width:100%;height:100%" />
                </div>
                <ruby>{{ returnUser(usID)?.username }}<rt>My<span style="visibility: hidden">.</span>Account</rt></ruby>

                </div>
                  <div :class="{hidden : hideDropdownMenu}" class="dropdown-content">
                    <div class="dropdown-content-flex">
                      <div class="dropdown-content-wrapper">
                        <i class="fa fa-gear"></i>
                          <router-link to="/settings" @click="closeDropdownMenu()" style="color: #000"> Settings </router-link>
                      </div>
                      <div class="dropdown-content-wrapper" @click="handleSignOut()">    
                          <i class="fa fa-sign-out"></i>
                          <router-link to="/login" @click="closeDropdownMenu()" style="color: #000"> Sign Out</router-link>
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="mobileView">
      <div class="headerContainer ms"
        style="width:100vw;height:8vh;display:flex;align-items:center;padding:1vh;justify-content:space-between;gap:2vw;background-color: white;box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.10);backdrop-filter:blur(2px);z-index:5;position:fixed;top:0">
        <a href="/" style="text-decoration:none;display:flex;gap:2vw;width:fit-content">

          <div style="width: fit-content;height:30px;">
            <img alt="logo" class="logo-img style-dUVKd" style="height:100%;width:auto"
              src="../assets/logos/sifteeDarkMobile.png" id="style-dUVKd">
          </div>
        </a>
        <div class="rotateMobileMenu" :style="{ 'transform': mobileMenuVisible == 'block' ? 'rotate(-90deg)' : '' }"
          style="width:min-content;height:min-content;margin-right:5vw">
          <i @click="toggleMobileMenu()" class="infoTitle fa-solid fa-bars"
            style="color:gray;transform:scale(1.2);height:fit-content;justify-content: right;font-size:1em"></i>
        </div>
      </div>
    </div>

  </div>

  <div style="width:100%;height:100%;position:fixed;top:0;z-index:5;background-color:rgba(0, 0, 0, 0.3)"
    :style="{ display: menuVisible }" @click="toggleMenu()">
  </div>
  <div :style="{ display: menuVisible }">
    <div class="tabletView">

      <div class="animate__animated animate__zoomIn menuProfile"
        style="animation-duration:.3s;display:flex;flex-direction:column;border-radius:4px;overflow:hidden;min-width:510px;height:fit-content;border:1px solid lightgray;background-color:rgb(238, 238, 238);z-index: 6;">
        <div style="width:100%;display:flex;flex-direction:column;height:fit-content" class="qs font-semibold">
          <div style="color:white;display:flex;justify-content:space-between;height:fit-content">
            <p class="font-semibold" style="color:#292B2C;padding-top:2vh;padding-left:1vw">Profile</p>
            <i class="fa-solid fa-x mh" @click="toggleMenu" style="padding:1vh;color:#292B2C"></i>
          </div>
          <div v-for="(user, index) in users.filter(u => u.userID === usID)" :key="index">
            <div v-if="user.userID == usID"
              style="display:flex;flex-direction: column;gap:2vh;width:100%;height:100%;background-color:white;padding:2vh 0 0 0">

              <div style="display:flex;padding:0px 1vw 0px 1vw;gap:2vw;margin-top:auto;margin-bottom:auto">

                <div
                  style="display:flex;flex-direction:column;height:min-content;margin-top:auto;margin-bottom:auto;line-height:.7">
                  <p>{{ user?.username }}</p>
                  <p class="second" style="font-size:.8em">{{ user?.emailRef }}</p>


                </div>
              </div>

              <div class="mh"
                style="padding:0px 1vw 0px 1vw;width:100%;height:6vh;background-color:#fafafa;text-align:left;display:flex;align-items:center"
                @click="go('/Pricing'); toggleMenu()">
                <p style="width:fit-content;margin:auto;height:min-content;margin-top:auto;margin-bottom:auto"
                  class="second">Current Plan: <span style="color:#0064d4"> {{ user?.planRegistered }}</span></p>
                <i class="fa-solid fa-chevron-right"></i>
              </div>

              <div class="mh"
                style="padding:0px 1vw 0px 1vw;width:100%;height:6vh;border-bottom:2px solid lightgray;text-align:left;display:flex;align-items:center"
                @click="go('/MyDatasets'); toggleMenu()">
                <p style="width:fit-content;margin:auto;height:min-content;margin-top:auto;margin-bottom:auto"
                  class="second">My Purchased Datasets </p>
                <i class="fa-solid fa-chevron-right"></i>
              </div>

              <!-- 
              <div style="padding:0px 1vw 0px 1vw;width:100%;height:6vh;border-bottom:2px solid lightgray;text-align:left;display:flex">
                <p style="width:fit-content;margin:auto;height:min-content;margin-top:auto;margin-bottom:auto" class="second">Billing Account </p>
                <router-link to="/Pricing" style="margin-top:auto;margin-bottom:auto"><i class="fa-solid fa-chevron-right"></i></router-link> 
              </div>

              <div style="padding:0px 1vw 0px 1vw;width:100%;height:6vh;border-bottom:2px solid lightgray;text-align:left;display:flex">
                <p style="width:fit-content;margin:auto;height:min-content;margin-top:auto;margin-bottom:auto" class="second">Contact Support</p>
                <router-link to="/Pricing" style="margin-top:auto;margin-bottom:auto"><i class="fa-solid fa-chevron-right"></i></router-link> 
              </div>
         -->

              <!-- 
              <div style="display:flex;flex-direction: column;">
                <div style="display:flex; width:100%;height:4vh;padding-left:1vw;gap:1vw">
                  <p class="second" style="width:30%;padding-top:.3em;font-weight:bold">Registered Key <i
                      class="fa-solid fa-circle-info mh" :title="`Insert your provided referral code to gain access to special permissions and pages.` "></i></p>
                  <input type="text" class="inpClear" style="width:50%;border-bottom:1px solid black"
                    :disabled="returnUser(usID).referralKey != ''" v-model="referralKeyContent">
                  <button v-if="String(returnUser(usID).referralKey).trim() === ''" class="mh"
                    style="background-color:#4472c4;color:white;height:4vh;border-radius:4px;border:none;margin-right:1vw"
                    @click="updateProfile(referralKeyContent, returnUser(usID).id)">Update</button>
                </div>

                <div style="width:100%;height:4vh;margin-top:2vh;text-align:center">
                  <p v-if="errMsg != null" class="errMsg">{{ errMsg }}</p>
                </div>
              </div> -->
              <div class="qs"
                style="display:flex;flex-direction:column; width:100%;height:fit-content;padding-left:1vw;gap:1vw"
                v-if="!emailVerified">
                <p style="font-weight:bold;color:crimson">You need to verify your email before you can access all of
                  Siftee.</p>
                <p style="font-size:.9em">Cannot find your email? Try the spam folder, otherwise, <span class="mh"
                    style="color:#4472c4" @click="sendEmailVerification(loggedUser); sentTextVisible()">click here to
                    resend email.</span></p>
                <p :style="{ display: textVisible }" id="textSent">A verification email has been sent!</p>

              </div>
              <div style="display:flex;justify-content: right;padding:1vw;padding-top:2vh">
                <p class="wt mh" @click="handleSignOut(), go('/Login')"
                style="background-color:gray;padding:1vh 1vw 1vh 1vw;border-radius:4px;border:none">Sign Out</p>
              </div>

            </div>



          </div>




        </div>


      </div>
    </div>

    <div class="mobileView">
      <div style="width:100vw;height:100vh;background-color:white;position:fixed;top:8vh;z-index:4">
        <div style="display:flex;width:100%;justify-content: right;">
          <i class="fa-solid fa-x mh" @click="toggleMenu()" style="padding:2vh;color:black"></i>

        </div>

        <div v-for="(user, index) in users.filter(u => u.userID === usID)" :key="index">
          <div v-if="user.userID == usID"
            style="display:flex;flex-direction: column;gap:6vh;width:100%;height:100%;background-color:white;padding:2vh 0 2vh 2vw">


            <div style="display:flex;flex-direction:column; width:100%;height:4vh;padding-left:1vw">
              <p class="second" style="width:100%;padding-top:.3em">Email</p>
              <input type="text" class="inpClear" style="width:80%" :disabled="true" v-model="user.emailRef">
            </div>
            <div style="display:flex;flex-direction:column; width:100%;height:4vh;padding-left:1vw">
              <p class="second" style="width:100%;padding-top:.3em">Username</p>
              <input type="text" class="inpClear" style="width:80%" :disabled="true" v-model="usernameContent">
            </div>
            <div style="display:flex;flex-direction:column; width:100%;height:4vh;padding-left:1vw">
              <p class="second" style="width:100%;padding-top:.3em">Your Current Plan</p>
              <input type="text" class="inpClear" style="width:80%" :disabled="true" v-model="user.planRegistered">
            </div>
            <div style="display:flex;flex-direction:column; width:100%;height:4vh;padding-left:1vw">
              <div style="display:flex; flex-direction:column;width:100%;height:4vh;padding-left:1vw">
                <p class="second" style="padding-top:.3em;font-weight:bold">Registered Key <i
                    class="fa-solid fa-circle-info mh"></i></p>
                <input type="text" class="inpClear" style="width:80%;border-bottom:1px solid black"
                  :disabled="returnUser(usID).referralKey != ''" v-model="referralKeyContent">
                <button v-if="String(returnUser(usID).referralKey).trim() === ''" class="mh"
                  style="background-color:#4472c4;color:white;height:4vh;border-radius:4px;border:none"
                  @click="updateProfile(referralKeyContent, returnUser(usID).id)">Update</button>
              </div>

              <div style="width:100%;height:4vh;margin-top:2vh;text-align:center">
                <p v-if="errMsg != null" class="errMsg">{{ errMsg }}</p>
              </div>
            </div>
            
            <div
            style="padding-right:1vw;width:100%;height:fit-content;text-align:right;line-height:1;padding-top:6vh;padding-right:2vw">
            <p class="second" style="font-size:.9em">Want to upgrade your plan?</p>
              <div class="second mh" @click="toggleMenu(), go('/Pricing')" style="text-decoration: none">View
                More Details</div>
            </div>
            <div
              style="display:flex;justify-content: right;padding:1vw;padding-top:2vh;padding-right:2vw;border-top:1px solid lightgray">
              <p class="wt mh" @click="handleSignOut(), go('/Login')"
                style="background-color:gray;padding:1vh 1vw 1vh 1vw;border-radius:4px;border:none">Sign Out</p>
            </div>

          </div>



        </div>

      </div>

    </div>
  </div>


  <!-- <div v-if="isLoggedin"
          style="width:30vw;display:flex;justify-content: right;gap:3vw;font-family:Arial, Helvetica, sans-serif">
          <div style="display:flex;width:20vw;justify-content: right;">
            <div class="wt mh" @click="toggleMenu(usID)"
              style="width:fit-content;padding-left:1vw;padding-top:.5em;display:flex;gap:.5vw;color:gray;padding-right:1vw;border-radius:4px">
              <i class="fa-solid fa-user" style="padding-top:.3em"></i>
              <p style="padding-top:.1em">View Profile</p>
            </div>
          </div>style
        </div>
        <div v-if="!isLoggedin" style="width:fit-content;padding-top:.3em;margin-left:.7vw">
          <button class="signIn mh qs" @click="go('/Register')"
            style="position:absolute;right:13vw;font-family:Arial, Helvetica, sans-serif;font-weight:bold;background-color:#4472c4;color:white;border-radius:4px;outline:none;border:none;padding:0.3vh 1vw 0.3vh 1vw;height:47.33px;width:124px">Sign
            Up</button>
        </div> -->




  <router-view />

  <div class="mobileView qs">
    <div :style="{ display: mobileMenuVisible }" class="animate__animated animate__slideInDown"
      style="animation-duration:.3s;position:fixed;top:6vh;width:100vw;height:100vh;padding-bottom:4vh;padding-top:4vh;background-color:white;color:#000000;z-index:4">
      <div style="height:fit-content;">
        <div class="qs"
          style="font-size:700;width:90vw;margin-left:auto;margin-right:auto;display:flex;flex-direction:column;gap:2vh;padding-left:2vw;">
          <a href="/" style="display:flex;justify-content:right">
            <p>Home</p>
          </a>
          <a href="/#graphicSection" class="mobile-anchor" @click="toggleMobileMenu()">
            <p>Features</p>
          </a>
          <a href="/#mc_embed_signup" class="mobile-anchor" @click="toggleMobileMenu()">
            <p>Contact Us</p>
          </a>
          <a href="/Pricing" class="mobile-anchor" @click="closeMobileMenu">
            <p>Pricing</p>
          </a>
          <a href="/Search" class="mobile-anchor" @click="closeMobileMenu">
            <p>Search</p>
          </a>
          

          <div v-if="!isLoggedin" class="qs" style="font-weight:700;display:flex; align-items:center; justify-content:right">
            <!-- user not logged in show login button -->
            <a href="/Login" @click="closeMobileMenu" class="mobileMenuBtn">
              Login
            </a>
          </div>
            <div v-if="isLoggedin" style="display:flex; justify-content:right;">
              <button class="mobileMenuBtn">
                <router-link to="/settings" @click="closeMobileMenu" style="color: #fff;"> Profile </router-link>
              </button>
            </div>
            <div v-if="isLoggedin" style="display:flex; justify-content:right;">
              <button class="mobileMenuBtn">
                <router-link to="/Login" @click="handleSignOut(), closeMobileMenu()" style="color: #fff;"> Sign Out </router-link>
              </button> 
            </div>



        </div>
      </div>
      <!-- <div style="margin-top:6vh;width:100vw;justify-content: center;gap:2vw;display:flex">
        <button v-if="isLoggedin" class="download-button mh" @click="toggleMenu(usID), closeMobileMenu()">Profile</button>
        <button v-else class="download-button mh" @click="go('/Register'), closeMobileMenu()">Sign Up</button>
        <button v-if="isLoggedin" class="download-button mh" style="background-color:lightgray"
          @click="handleSignOut(), go('/Login'), closeMobileMenu()">Sign Out</button>
      </div> -->
    </div>

  </div>
</template>