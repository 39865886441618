<template>
  <div
    class="qs"
    style="
      width: 100%;
      min-height: 100vh;
      height: fit-content;
      padding: 11vh 6vw 11vh 6vw;
      text-align: center;
    "
  >
    <div
      style="
        padding-left: 2vw;
        padding-right: 2vw;
        display: flex;
        justify-content: center;
        gap: 1vw;
        height: fit-content;
        border-bottom: 2px solid rgba(128, 128, 128, 0.5);
      "
    >
      <p style="font-size: 1.5em; font-weight: bold; color: #4f4f4f">
        Purchased Datasets
      </p>
      <button
        @click="fetchPurchasedDatasets(usID)"
        class="brMobile mh"
        style="width: fit-content"
      >
        Fetch Purchased Datasets
      </button>
    </div>
    <pre v-for="(purchasedSet, index) in purchasedDatasets" :key="index">
            {{ JSON.parse(purchasedSet) }}
        </pre
    >
  </div>
</template>

<script setup>
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { onMounted, ref } from "vue";

var usID = ref("");
var usEmail = ref("");
let auth;
const isLoggedin = ref(false);
var emailVerified = ref("");

onMounted(async () => {
  auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      isLoggedin.value = true;
      usID.value = user.uid;
      usEmail.value = user.email;
      emailVerified.value = user.emailVerified;
    } else {
      isLoggedin.value = false;
    }
  });
});
</script>

<script>
export default {
  data() {
    return {
      purchasedDatasets: ref([]),
      allDatasets: ref([]),
    };
  },
  methods: {
    async fetchPurchasedDatasets(usID) {
      try {
        // Check if usID is available
        if (usID) {
          const requestOptions = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `${usID}`,
            },
          };
          // Make a GET request to your backend API to retrieve purchased datasets
          const response = await fetch(
            "http://localhost:3000/gcs/retrievepurchased",
            requestOptions
          );
          if (response.ok) {
            const data = await response.json();
            this.purchasedDatasets = data;

            // Assign the response data to the purchasedDatasets variable or do something with it
          } else {
            console.error(
              "Error fetching purchased datasets:",
              response.statusText
            );
          }
        } else {
          console.error("usID is not available yet.");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
  },
  watch: {
    usID(newValue) {
      console.log("USID:" + newValue);
      this.fetchPurchasedDatasets(newValue);
    },
  },
};
</script>

<style></style>