<template>
  <div
    class="os"
    style="
      width: 100%;
      min-height: 100vh;
      height: fit-content;
      background-color: white;
      text-align: center;
    "
  >
    <p style="font-size: 1.5em; font-weight: bold; text-transform: capitalize">
      Company Focused Pricing Plan
    </p>

    <div v-if="referral == 'shipsDNA2309'">
      <p style="font-weight: bold">ShipsDNA</p>
      <stripe-pricing-table
        pricing-table-id="prctbl_1NplbcA1UYuDWXkQ0pOePtBz"
        publishable-key="pk_live_51NfZuVA1UYuDWXkQLHgpuL8MgmXkue6nXNZR2EtXbSRVvu2fbCKzvN08Qa4WxO5G4a7RH7XxnGnZRtURvbFB0Fnd00uZqWwFOu"
      >
      </stripe-pricing-table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["referral"],
};
</script>

<script setup>
</script>



<style></style>