<script>
const db = getFirestore(app);
window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}
gtag("js", new Date());

gtag("config", "G-1YCCRVSJTM");
export default {
  data() {
    return {
      users: ref([]),
      verificationCodes: ref([]),
    };
  },
  computed: {
    isTabletView() {
      return window.innerWidth >= 1024;
    },
  },
  methods: {
    returnPositiveCode() {
      return this.verificationCodes
        .filter((obj) => Object.prototype.hasOwnProperty.call(obj, "code"))
        .map((obj) => obj.code);
    },
  },
  mounted() {
    const userQuery = query(collection(db, "oceanUsers"));
    const liveOISSUsers = onSnapshot(userQuery, (snapshot) => {
      this.users = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          userID: doc.data().userID,
          username: doc.data().username,
          emailRef: doc.data().emailRef,
        };
      });
    });

    const codesQuery = query(collection(db, "oceanVerificationCodes"));
    const liveCodes = onSnapshot(codesQuery, (snapshot) => {
      this.verificationCodes = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          code: doc.data().code,
          description: doc.data().description,
          usageCount: doc.data().usageCount,
        };
      });
    });

    onUnmounted(liveCodes, liveOISSUsers);
  },
  props: ["referralID"],
};
</script>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import {
  signInWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
} from "@firebase/auth";
import {
  onSnapshot,
  query,
  addDoc,
  collection,
  getFirestore,
  updateDoc,
  doc,
} from "firebase/firestore";
import { app } from "@/configs";
import { onUnmounted } from "vue";
import { useHead, useSeoMeta } from "@unhead/vue";
useHead({
  link: [
    {
      rel: "canonical",
      href: "https://siftee.io/login",
    },
  ],
});

useSeoMeta({
  title: "Login to your account - Find Data Faster | Siftee.io",
  description:
    "Streamline Your Data Search Effortlessly | Siftee Login - Access our powerful data consolidation and filtering tool to find the data you need in seconds. Say goodbye to hours of manual searching. Try Siftee today!",
  ogDescription:
    "Streamline Your Data Search Effortlessly | Siftee Login - Access our powerful data consolidation and filtering tool to find the data you need in seconds. Say goodbye to hours of manual searching. Try Siftee today!",
  ogTitle: "Login to your account - Find Data Faster | Siftee.io",
  ogImage: window.location.href + "img/sifteeDarkMobile.c964311a.png",
});

const email = ref("");
const password = ref("");
const router = useRouter();
const errMsg = ref();

const signInWithGoogle = (users, referralID, pass) => {
  const provider = new GoogleAuthProvider();
  if (!pass) {
    referralID = ""; //if verification code inputted does not exist in database, set the code ot nothing.
  }
  signInWithPopup(getAuth(), provider)
    .then((userCredentials) => {
      // console.log(userCredentials.user.photoURL);
      console.log("Connection Established");
      const user = userCredentials.user;
      const UCuserID = user.uid;
      const UCemail = user.email;
      const UCusername = user.displayName;

      if (users.find((u) => u.emailRef === UCemail) === undefined) {
        addDoc(collection(db, "oceanUsers"), {
          emailRef: UCemail,
          userID: UCuserID,
          username: UCusername,
          referralKey: referralID,
          planRegistered: "Free",
          photoURL: userCredentials.user.photoURL,
          searchUse: 1,
        });
      } else {
        updateDoc(
          doc(db, "oceanUsers", users.find((u) => u.emailRef === UCemail).id),
          {
            photoURL: userCredentials.user.photoURL,
          }
        );
      }
      router.push("/Search");
    })
    .catch((error) => {
      console.log(error);
      if (error.response.status === 422) {
        this.$router.push("/");
      }
    });
};

const login = () => {
  const auth = getAuth();
  signInWithEmailAndPassword(auth, email.value, password.value)
    .then(() => {
      console.log("Successfully signed in");
      console.log(auth.currentUser);
      router.push("/");
    })
    .catch((error) => {
      console.log(error.code);
      switch (error.code) {
        case "auth/invalid-email":
          errMsg.value = "Invalid Email";
          break;
        case "auth/user-not-found":
          errMsg.value = "No account with that email was found";
          break;
        case "auth/wrong-password":
          errMsg.value = "Invalid Password";
          break;
        default:
          errMsg.value = "Email / Password is incorrect";
          break;
      }
    });
  setTimeout(() => {
    errMsg.value = "";
  }, 2000);
};
</script>

<template>
  <div class="selectDisable">
    <div
      v-if="isTabletView"
      style="width: 100%; min-height: 100vh; height: fit-content"
    >
      <div
        class="qs"
        style="
          display: flex;
          width: 100%;
          height: fit-content;
          min-height: 300px;
          overflow: hidden;
          justify-content: center;
        "
      >
        <div style="width: 40%; padding: 1vw">
          <img
            src="../assets/homeImg/signInTablet.svg"
            style="width: 100%; margin: auto"
          />
          <div
            style="
              width: 0%;
              display: flex;
              flex-direction: column;
              display: none;
            "
          >
            <div
              style="
                height: 100%;
                display: flex;
                flex-direction: column;
                text-align: left;
              "
            >
              <p class="nw primary" style="color: gray; font-weight: 400">
                Referral Link
              </p>
              <input
                class=""
                type="text"
                placeholder="Verification Code"
                name=""
                :disabled="true"
                :value="referralID"
              />
            </div>
          </div>
        </div>
        <div style="width: 50%; display: flex; justify-content: center">
          <form
            style="height: fit-content; padding-top: 1vw"
            @submit.prevent="login"
          >
            <div
              style="
                height: 80%;
                margin: auto;
                text-align: center;
                border-radius: 4px;
              "
            >
              <p style="font-weight: 700; font-size: 30px" class="qs">
                Sign In
              </p>
              <div
                style="
                  height: fit-content;
                  margin: auto;
                  display: flex;
                  flex-direction: column;
                  margin-top: 3vh;
                "
              >
                <div
                  style="
                    width: 100%;
                    height: 30%;
                    display: flex;
                    flex-direction: column;
                    text-align: left;
                    margin-left: auto;
                    margin-right: auto;
                    padding-top: 2vh;
                    line-height: 0;
                  "
                >
                  <p class="primary" style="color: #0f0f0f; font-weight: 700">
                    Work Email
                  </p>
                  <input
                    class=""
                    type="text"
                    placeholder="Email"
                    name="email"
                    v-model="email"
                  />
                </div>

                <div style="width: 100%">
                  <div
                    style="
                      width: 100%;
                      height: 100%;
                      display: flex;
                      flex-direction: column;
                      margin-top: 6vh;
                    "
                  >
                    <div
                      style="
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        margin-left: auto;
                        margin-right: auto;
                        line-height: 0;
                      "
                    >
                      <p
                        class="primary"
                        style="color: #0f0f0f; font-weight: 700"
                      >
                        Password (min. 10 characters)
                      </p>
                      <input
                        class=""
                        type="password"
                        placeholder="Password"
                        name="password"
                        v-model="password"
                      />
                    </div>
                  </div>
                </div>
                <a href="/ForgotPassword">
                  <p style="text-align: right; color: #709dd8; margin-top: 2vh">
                    Forgot your password?
                  </p>
                </a>
                <div style="width: 100%; height: fit-content">
                  <button
                    v-on:click="login()"
                    class="qs b"
                    style="
                      width: 100%;
                      color: white;
                      text-decoration: none;
                      border-radius: 10px;
                      background-color: #709dd8;
                      padding: 1vh;
                      border: none;
                    "
                  >
                    Login
                  </button>
                </div>
                <div
                  style="
                    width: 100%;
                    height: 5vh;
                    min-height: 20px;
                    padding-top: 1vh;
                  "
                >
                  <p
                    class="errMsg l animate__animated animate__fadeIn"
                    style="animation-duration: 0.2s"
                    v-if="errMsg"
                  >
                    {{ errMsg }}
                  </p>
                </div>
                <div
                  style="
                    width: 100%;
                    height: fit-content;
                    margin: auto;
                    display: flex;
                    flex-direction: column;
                  "
                >
                  <p><span style="color: #4f4f4f">Or Sign In with</span></p>
                  <a
                    href="#"
                    class="google-login-button"
                    @click="
                      signInWithGoogle(
                        users,
                        referralID,
                        returnPositiveCode().includes(referralID)
                      )
                    "
                  >
                    <img
                      src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                      alt="Google Logo"
                      width="18"
                      height="18"
                    />
                    <span>Sign in with Google</span>
                  </a>
                  <p class="it" style="padding-top: 2vw">
                    <span style="font-weight: 400">Not a member yet? </span
                    ><span style="color: #709dd8; font-weight: 700"
                      ><router-link to="/Register"
                        >Create Account</router-link
                      ></span
                    >
                  </p>
                </div>

                <img
                  src="../assets/homeImg/signUpBrands.svg"
                  style="margin-top: 3vh"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div v-else style="padding-top: 6vh">
      <div
        style="
          width: 90%;
          min-height: 100vh;
          height: fit-content;
          margin-left: auto;
          margin-right: auto;
        "
      >
        <p
          class="qs"
          style="
            font-size: 2em;
            color: #0f0f0f;
            font-weight: 700;
            text-align: center;
            padding-top: 6vh;
          "
        >
          Sign In
        </p>
        <div>
          <p class="qs" style="font-weight: 700">Work Email</p>
          <div style="display: flex; align-items: center">
            <input
              style="width: 100%; padding: 2vh 0 2vh 2vw; height: 6vh"
              placeholder="your@email.com"
              name="email"
              v-model="email"
              type="email"
            />
          </div>
        </div>

        <div style="margin-top: 4vh">
          <p class="qs" style="font-weight: 700">
            Password
            <span class="it" style="font-weight: 100"
              >(min. 10 characters)</span
            >
          </p>
          <div style="display: flex; align-items: center">
            <input
              style="width: 100%; padding: 2vh 0 2vh 2vw; height: 6vh"
              placeholder="**********"
              name="password"
              v-model="password"
              type="password"
            />
          </div>
        </div>
        <a href="/ForgotPassword">
          <p style="text-align: right; color: #709dd8; margin-top: 2vh">
            Forgot your password?
          </p>
        </a>

        <div style="margin-top: 6vh">
          <button
            class="qs"
            v-on:click="login()"
            style="
              width: 100%;
              padding: 1vh 0 1vh 0;
              height: fit-content;
              background-color: #709dd8;
              color: white;
              font-weight: 700;
              border: none;
              border-radius: 10px;
            "
          >
            Login
          </button>
        </div>
        <p
          class="it"
          style="
            text-align: center;
            color: #0f0f0f;
            font-size: 0.8em;
            margin-top: 2vh;
          "
        >
          Or Sign in with
        </p>
        <div style="width: fit-content; margin-left: auto; margin-right: auto">
          <a
            href="#"
            class="google-login-button"
            style="width: fit-content; margin-left: auto; margin-right: auto"
            @click="
              signInWithGoogle(
                users,
                referralID,
                returnPositiveCode().includes(referralID)
              )
            "
          >
            <img
              src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
              alt="Google Logo"
              width="18"
              height="18"
            />
            <span>Sign in with Google</span>
          </a>
        </div>

        <div style="text-align: center; margin-top: 6vh">
          <p class="it" style="font-size: 0.8em; color: #0f0f0f">
            Not a member yet?
            <span><a href="/Register">Create an account</a></span>
          </p>
        </div>
        <div
          style="
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            margin-top: 2vh;
          "
        >
          <img
            src="../assets/homeImg/signUpBrands.svg"
            style="width: 100%; height: auto"
          />
        </div>
      </div>
    </div>
  </div>
</template>
