<template>
  <div
    style="
      width: 100%;
      height: fit-content;
      min-height: 100vh;
      background-color: transparent;
      padding: 0 5% 0 5%;
    "
  >
    <div
      style="
        display: flex;
        justify-content: left;
        gap: 2vw;
        margin-bottom: 2vh;
        padding-top: 4vh;
      "
    >
      <div style="display: flex; flex-direction: column; gap: 2vh">
        <div
          style="
            display: flex;
            gap: 6vw;
            height: fit-content;
            word-wrap: break-word;
          "
          :style="{ paddingTop: isTabletView ? '.5em' : '2em' }"
        >
          <div
            v-if="brandImg(dataset.content?.sourceCompany) != undefined"
            style="
              width: 120px;
              height: 120px;
              min-width: 120px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 10px;
              border: 1px solid #eaeaea;
            "
          >
            <img
              :src="brandImg(dataset.content?.sourceCompany)"
              style="
                width: 70%;
                height: auto;
                object-fit: contain;
                min-width: 70%;
              "
              alt="brandImg"
            />
          </div>
          <div
            class="source-header-container"
            style="
              height: fit-content;
              display: inline;
              white-space: normal;
              word-wrap: break-all;
              flex-wrap: wrap;
            "
          >
            <h1
              class="it"
              v-if="isTabletView"
              style="
                font-weight: 700;
                margin-top: auto;
                margin-bottom: auto;
                text-transform: capitalize;
                font-size: 28px;
              "
            >
              {{ dataset.content?.sourceHeader }}
            </h1>

            <h1
              v-else
              style="
                font-weight: 700;
                margin-top: auto;
                margin-bottom: auto;
                text-transform: capitalize;
                font-size: 1.2em;
              "
            >
              {{ dataset.content?.sourceHeader }}
            </h1>

            <div
              style="
                display: flex;
                gap: 3vw;
                padding-top: 1vh;
                align-items: center;
                flex-wrap: wrap;
                row-gap: 16px;
              "
              :style="{ 'font-size': isTabletView ? '1em' : '.8em' }"
            >
              <router-link
                :to="'/Search/' + dataset.content?.sourceCompany"
                class="b second qs"
                style="
                  text-decoration: underline;
                  margin-top: auto;
                  margin-bottom: auto;
                  color: #709dd8;
                  font-weight: 700;
                "
                >{{ dataset.content?.sourceCompany }}</router-link
              >
              <div
                style="
                  display: flex;
                  height: min-content;
                  gap: 0.5vw;
                  align-items: center;
                "
              >
                <i class="fa-solid fa-calendar-days" style="color: #709dd8"></i>
                <p
                  v-if="dataset.content?.lastUpdated"
                  class="it"
                  style="
                    font-weight: 400;
                    margin-top: auto;
                    margin-bottom: auto;
                    color: #797979;
                  "
                >
                  Updated:
                  {{ formatDate(dataset.content?.lastUpdated) }}
                </p>
              </div>

              <div
                style="
                  display: flex;
                  gap: 0.5vw;
                  height: fit-content;
                  margin-bottom: auto;
                "
              >
                <img
                  src="../assets/homeImg/tableViewScoreVector.svg"
                  draggable="false"
                  style="margin-top: auto; margin-bottom: auto"
                />
                <span class="it" style="font-weight: 400; color: #797979"
                  >Rating:
                  {{ Math.round(dataset.content?.score * 100) / 100 }}</span
                >
              </div>
              <div
                style="
                  display: flex;
                  height: min-content;
                  gap: 0.5vw;
                  align-items: center;
                "
              >
                <i class="fa-solid fa-star" style="color: #709dd8"></i>
                <p
                  v-if="dataset.content?.premiumData"
                  class="it"
                  style="
                    font-weight: 400;
                    margin-top: auto;
                    margin-bottom: auto;
                    color: #797979;
                  "
                >
                  Premium Content
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        min-height: 100vh;
      "
    >
      <div
        style="
          width: 70%;
          background-color: #f8f8f8;
          padding: 1vw;
          border-radius: 20px;
          height: fit-content;
        "
      >
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #d9d9d9;
          "
        >
          <p class="qs" style="font-weight: 700; font-size: 1.5em">
            <span><i class="fa-solid fa-gear"></i> </span> Configure
          </p>
          <p class="it" style="color: #797979">
            Use Custom Filters for your more specific data
          </p>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 2vh;
            padding-bottom: 2vh;
          "
        >
          <p class="it" style="margin-top: auto; margin-bottom: auto">
            {{ pushedHeaders.length }} Custom Filters
          </p>
          <button
            @click="editTableVisible = !editTableVisible"
            style="
              color: white;
              background-color: #709dd8;
              border: none;
              border-radius: 10px;
              padding: 1vh;
            "
          >
            Edit <i class="fa fa-plus" aria-hidden="true"></i>
          </button>
        </div>
        <div
          style="
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            row-gap: 2vh;
          "
        >
          <div
            v-for="(premiumColumn, index) in pushedHeaders"
            :key="index"
            style="
              height: fit-content;
              display: flex;
              flex-direction: column;
              width: 48%;
            "
          >
            <div
              style="
                width: 100%;
                padding: 1vw;
                background-color: white;
                border: 1px solid #eaeaea;
                border-radius: 20px;
              "
            >
              <p
                class="it"
                style="
                  font-weight: 700;
                  font-size: 1.2em;
                  text-transform: capitalize;
                "
              >
                {{ premiumColumn }}
              </p>
              <div
                class="mh"
                @click="toggleBlueBox(index)"
                :style="{
                  backgroundColor: 'white',
                  fontWeight: 'bold',
                  padding: '1vh',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  border: '1px solid #EAEAEA',
                }"
              >
                <div
                  class="holdPremium"
                  style="
                    display: flex;
                    justify-content: space-between;
                    width: 90%;
                    margin-left: auto;
                    margin-right: auto;
                  "
                >
                  <p
                    style="
                      margin-top: auto;
                      margin-bottom: auto;
                      text-transform: capitalize;
                    "
                  >
                    {{ premiumColumn }}
                  </p>
                  <i
                    style="
                      height: fit-content;
                      margin-top: auto;
                      margin-bottom: auto;
                    "
                    class="fa-solid fa-chevron-down"
                  ></i>
                </div>
              </div>

              <div
                :style="{ display: visibleBlueBoxes[index] ? 'block' : 'none' }"
                style="height: fit-content"
              >
                <div
                  id="holdValues"
                  style="
                    border-radius: 4px;
                    height: fit-content;
                    padding: 2vh 1vw 2vh 0.5vw;
                    height: 30vh;
                    overflow-y: scroll;
                    background-color: white;
                  "
                >
                  <div
                    v-for="(uniqueColumnHeader, index) in getUniqueValues(
                      table,
                      premiumColumn
                    ).sort()"
                    :key="index"
                    class="checkbox-wrapper nw"
                    style="
                      width: fit-content;
                      padding-right: 1vw;
                      padding-left: 1vw;
                      display: flex;
                      animation-duration: 0.3s;
                      gap: 2vw;
                      width: 100%;
                      border-bottom: 1px solid lightgray;
                      overflow: hidden;
                    "
                  >
                    <input
                      type="checkbox"
                      class="filterSelect"
                      :id="uniqueColumnHeader"
                      :value="uniqueColumnHeader"
                      :name="premiumColumn"
                      style="transform: scale(1.5)"
                      @change="filterUpdateTrigger"
                    />
                    <label
                      :for="uniqueColumnHeader"
                      style="
                        padding-top: 1vh;
                        text-transform: capitalize;
                        font-weight: 500;
                      "
                      >{{ uniqueColumnHeader }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style="
          width: 25%;
          height: fit-content;
          border: 1.5px solid #709dd8;
          border-radius: 20px;
          position: sticky;
          top: 1vh;
          padding: 1vw;
        "
      >
        <p class="qs" style="font-size: 1.5em; font-weight: 700">
          <i class="fa-solid fa-tag"></i> Pricing
        </p>
        <p class="it" style="color: #797979">
          You are filtering this dataset with the following headers
        </p>
        <div style="padding: 2vh 0 2vh 0; border-bottom: 2px solid #d9d9d9">
          <div style="display: flex; justify-content: space-between">
            <p>Rows to Order</p>
            <p class="it" style="font-size: 1.2em; font-weight: 700">
              {{ filterArrayFunc(table, storeFilterArray).length }}
            </p>
          </div>
          <div style="display: flex; justify-content: space-between">
            <p>Cost per Row</p>
            <p class="it" style="font-size: 1.2em; font-weight: 700">
              ${{ dataset.content?.costPerRow }}
            </p>
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            padding: 2vh 0 2vh 0;
          "
        >
          <p style="width: 40%">Expected Cost</p>
          <p class="it" style="font-size: 1.2em; font-weight: 700">
            <span v-if="dataset.content?.premiumData"
              >${{
                dataset.content?.costPerRow *
                filterArrayFunc(table, storeFilterArray).length
              }}</span
            >
            <span v-else style="font-weight: 700">$0</span>
          </p>
        </div>
        <div style="display: flex; justify-content: space-between">
          <button
            class="it"
            style="
              color: #709dd8;
              background-color: white;
              font-weight: 700;
              width: 48%;
              border-radius: 10px;
              border: 1px solid #709dd8;
              padding: 1vh 0 1vh 0;
            "
            @click="passIntoStoreFA()"
          >
            Preview
          </button>
          <button
            class="it"
            style="
              color: white;
              background-color: #709dd8;
              font-weight: 700;
              width: 48%;
              border-radius: 10px;
              border: 1px solid #709dd8;
            "
          >
            Buy Now
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="editTableVisible"
      style="
        width: 100%;
        height: 100%;
        background-color: white;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgb(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
      "
    >
      <div
        style="
          margin: auto;
          width: 50vw;
          height: 70vh;
          background-color: white;
          border-radius: 20px;
          overflow: hidden;
          position: relative;
        "
      >
        <div
          class="qs"
          style="
            background-color: #709dd8;
            color: white;
            font-weight: 700;
            text-align: center;
            font-size: 1.5em;
            height: fit-content;
            padding: 1vh 0 1vh 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2vw;
            position: relative;
          "
        >
          <p
            class="qs"
            style="height: min-content; margin-top: auto; margin-bottom: auto"
          >
            <span><i class="fa-solid fa-gear"></i> </span> Custom Configure
          </p>
          <span class="it" style="font-weight: 400; color: #d9d9d9"
            >({{
              Object.keys(Object(dataset?.content?.table[0])).length
            }}
            Filter)</span
          >
          <div @click="editTableVisible = false" style="padding: 1vw">
            <i
              class="fa-solid fa-xmark"
              style="color: #d9d9d9; position: absolute; right: 1vw; top: 1vw"
            ></i>
          </div>
        </div>
        <div
          style="
            padding: 2vh 1vw 1vh 1vw;
            display: flex;
            gap: 1vw;
            flex-wrap: wrap;
            height: 70%;
            overflow-y: scroll;
          "
        >
          <div
            v-for="(header, index) in Object.keys(
              Object(dataset?.content?.table[0])
            )"
            :key="index"
          >
            <div
              @click="pushToPremium(header)"
              class="it"
              :style="{
                backgroundColor: pushedHeaders.includes(header)
                  ? '#eaf1f9'
                  : 'white',
              }"
              style="
                font-weight: 700;
                border-radius: 10px;
                border: 2px solid #eaeaea;
                padding: 1vh;
                padding-top: 0px;
                padding-bottom: 0px;
                width: 23vw;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <p
                style="
                  height: fit-content;
                  margin-top: auto;
                  margin-bottom: auto;
                "
              >
                {{ header }}
              </p>
              <div style="padding-right: 1vh">
                <span style="font-size: 2em">
                  <i
                    v-if="!pushedHeaders.includes(header)"
                    class="fa-solid fa-square-plus"
                    style="color: #709dd8"
                  ></i>
                  <i
                    v-if="pushedHeaders.includes(header)"
                    class="fa-solid fa-square-minus"
                    style="color: lightgray"
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="it"
          style="
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            padding: 1vw;
            justify-content: space-between;
            box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
          "
        >
          <div style="line-height: 0.6">
            <p style="font-weight: 700; font-size: 1.2em">
              {{ pushedHeaders.length }}
            </p>
            <p>Filters Has Been Selected</p>
          </div>
          <div
            style="display: flex; justify-content: space-between; width: 30%"
          >
            <button
              class="it"
              style="
                color: #709dd8;
                background-color: white;
                font-weight: 700;
                width: 48%;
                border-radius: 10px;
                border: 1px solid #709dd8;
                padding: 1vh 0 1vh 0;
              "
              @click="pushedHeaders = []"
            >
              Clear
            </button>
            <button
              class="it"
              style="
                color: white;
                background-color: #709dd8;
                font-weight: 700;
                width: 48%;
                border-radius: 10px;
                border: 1px solid #709dd8;
              "
              @click="editTableVisible = false"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div style="display:flex;justify-content: space-between">




            <div class="qs" style="width:30vw;height:fit-content">
                <div style="display:flex;flex-direction:column;line-height:1">
                    <p style="font-size:1.5em;font-weight:bold;padding:1vw;padding-bottom:0px">Configure</p>
                    <p class="second" style="padding:1vw;padding-top:0px">Select the rows you need through filters and
                        only pay for them.
                    </p>
                    <p style="color:crimson;padding:1vw">{{ pushedHeaders.length }}/1 custom filters added.</p>
                </div>
                <div v-if="tableLength > 5"
                    style="width:fit-content;min-width:100%;height:300px;display:flex;flex-direction: column;gap:1vh;overflow-y:scroll">
                    <div v-if="pushedHeaders.length == 0" style="padding:1vw">
                        <p style="text-align:left;font-weight:bold">Click on a column header from the table below to
                            filter by it's contents.</p>
                    </div>
                    <div v-for="(premiumColumn, index) in pushedHeaders" :key="index"
                        style="height:fit-content;display:flex;flex-direction: column;width:100%">
                        <div class="mh" @click="toggleBlueBox(index)" :style="{
                            backgroundColor: 'white',
                            fontWeight: 'bold',
                            padding: '1vh',
                            borderRadius: '2px',
                            width: '100%',
                            borderBottom: '2px solid #1068EB',
                            cursor: 'pointer'
                        }">
                            <div class="holdPremium"
                                style="display:flex;justify-content: space-between;width:90%;margin-left: auto;margin-right: auto">
                                <p style="margin-top: auto;margin-bottom: auto;text-transform:capitalize">{{
                                    premiumColumn }}</p>
                                <i style="height:fit-content;margin-top: auto;margin-bottom: auto"
                                    class="fa-solid fa-chevron-down"></i>
                            </div>
                        </div>
                        <div :style="{ width: '100%', height: 'fit-content', display: visibleBlueBoxes[index] ? 'block' : 'none' }"
                            style="width:100%;height:fit-content">
                            <div id="holdValues" style="border-radius:4px;height:fit-content;padding:2vh 1vw 2vh .5vw">
                                <div v-for="(uniqueColumnHeader, index) in getUniqueValues(table, premiumColumn).sort()"
                                    :key="index" class="checkbox-wrapper nw"
                                    style="width:fit-content;padding-right:1vw;padding-left:1vw;display:flex;animation-duration:.3s;gap:2vw;width:100%;border-bottom:1px solid lightgray;overflow:hidden">
                                    <input type="checkbox" class="filterSelect" :id="uniqueColumnHeader"
                                        :value="uniqueColumnHeader" :name="premiumColumn" style="transform:scale(1.5)"
                                        @change="filterUpdateTrigger">
                                    <label :for="uniqueColumnHeader"
                                        style="padding-top:1vh;text-transform: capitalize;font-weight:500">{{
                                            uniqueColumnHeader }}</label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div v-else>
                    <p class="b" style="text-align:center;color:#ff9494">This dataset does not allow for configuration
                        as it is too small.</p>

                </div>

                <div
                    style="width:100%;height:fit-content;border-top:1px solid #0645AD;display:flex;flex-direction:column;padding:1vw">
                    <div style="display:flex;justify-content:space-between">
                        <p v-if="filterArrayFunc(table, storeFilterArray).length != undefined" style="font-size:1.2em">
                            Selection: <span class="b">{{ filterArrayFunc(table, storeFilterArray).length }}</span>
                            rows.<br /><span v-if="filterArrayFunc(table, storeFilterArray).length == 0"> <i
                                    style="color:#ff9494" class="fa-solid fa-circle-exclamation"></i> No rows fit your
                                parameters.</span></p>



                    </div>
                    <div>
                        <button :disabled="tableLength <= 5" class="download-button" @click="passIntoStoreFA()"
                            style="width:fit-content">Refresh
                            Quote</button>


                    </div>


                </div>

            </div>

            <div class="qs" style="width:30vw;height:fit-content">
                <div style="display:flex;flex-direction:column">
                    <p style="font-size:1.5em;font-weight:bold;padding-bottom:0px">Pricing</p>
                    <div style="width:100%">
                        <p style="font-size:.8em;font-weight:lighter">You are filtering this dataset with the following
                            headers</p>
                        <div v-for="(filter, index) in Object.keys(storeFilterArray)" :key="index">
                            <p style="font-weight:lighter;font-size:.8em">- {{ filter }}</p>
                        </div>
                    </div>


                </div>
                <div style="display:flex;flex-direction:column;text-transform:capitalize;padding-bottom:1vh">
                    <div style="display:flex;justify-content:space-between;width:50%;">
                        <p class="b">Rows to Order</p>
                        {{ filterArrayFunc(table, storeFilterArray).length }}/{{ table.length }}
                    </div>
                    <div style="display:flex;justify-content:space-between;width:50%;"
                        v-if="(dataset.content)?.premiumData">
                        <p class="b">Cost per row</p>
                        ${{ (dataset.content)?.costPerRow }}
                    </div>

                    <div
                        style="display:flex;justify-content:space-between;width:50%;border-top:1px solid #1068EB;padding-top:1vh">
                        <p class="b">Expected Cost</p>
                        <span v-if="(dataset.content)?.premiumData">${{ (dataset.content)?.costPerRow *
                            filterArrayFunc(table, storeFilterArray).length }}</span>
                        <span v-else style="font-weight:700">$0</span>
                    </div>
                    <div style="display:flex;justify-content:left;gap:.5vw">
                        <button class="download-button mh" :disabled="filterArrayFunc(table, storeFilterArray).length === 0"
                            style="width:fit-content;padding-left:2vw;padding-right:2vw" @click="commitCart()"><span
                                v-if="(dataset.content)?.premiumData">Pay Now</span><span v-else>Download</span></button>
                    </div>
                </div>
            </div>
        </div> -->

    <!-- <div v-if="tableLength > 5" style="width:100%;height:fit-content;padding:1vw">
            <p style="text-align:left"><span style="font-size:1.5em;font-weight:bold">Preview</span> <span>(Limited to
                    max of 10 rows)</span></p>
            <div class="scroll" style="width:100%;overflow-x:scroll">
                <table style="font-size:.8em;width:100%">
                    <thead>
                        <tr style="position:sticky;top:0;background-color: #f8f6f9">
                            <th class="nw headerHover" @click="pushToPremium(i)"
                                v-for="(i, index) in Object.keys(filterArrayFunc(table, storeFilterArray)[0]).sort()"
                                :key="index"
                                style="text-align:left;text-transform:capitalize;color:rgba(0, 0, 0, 0.9);padding:1vh 1vw;border:none;border-bottom:1px solid lightgray">
                                {{ i }}</th>
                        </tr>
                    </thead>
                    <tbody v-if="filterArrayFunc(table, storeFilterArray).length > 5"
                        :class="{ 'premiumBlur': (dataset.content)?.premiumData }">
                        <template v-for="(row, index) in filterArrayFunc(table, storeFilterArray).sort()">
                            <tr class="perRow" v-if="index < 10" :key="index">
                                <td class="nw" v-for="header in headers().sort()" :key="header"
                                    style="padding:1vh 1vw;max-width:600px;overflow:hidden;border:none">{{ row[header]
                                    }}</td>
                            </tr>
                        </template>
                    </tbody>
                    <tbody v-if="filterArrayFunc(table, storeFilterArray).length <= 5"
                        :class="{ 'LT5Blur': (dataset.content)?.premiumData }">
                        <template v-for="(row, index) in filterArrayFunc(table, storeFilterArray).sort()">
                            <tr class="perRow" v-if="index < 10" :key="index">
                                <td class="nw" v-for="header in headers().sort()" :key="header"
                                    style="padding:1vh 1vw;max-width:600px;overflow:hidden;border:none">{{ row[header]
                                    }}</td>
                            </tr>
                        </template>
                    </tbody>




                </table>
            </div>

        </div>
        <div v-else style="width:100%;height:fit-content;padding:1vw">
            <p style="text-align:left"><span style="font-size:1.5em;font-weight:bold">Preview</span> <span>(Limited to
                    max of 10 rows)</span></p>

            <p style="color:#ff9494;text-align:center" class="b">This dataset does not allow for previews as it is too
                small.</p>

        </div> -->
  </div>

  <!-- <div v-else-if="retrievalSuccess && !(dataset.content)?.premiumData"
        style="width:100%;height:100vh;font-size: 1.5em;font-weight:bold;display:flex;justify-content: center;padding-top:11vh;">
        <div style="margin-left: auto;margin-right: auto;width:fit-content">
            <img src="../assets/logos/sifteeDark.png"
                style="width:200px;height:200px;margin-left: auto;margin-right: auto;">
            <p style="text-align:left">You have encountered an error.<br />This dataset is not registered as premium, If
                this is an error, please report this to us.</p>
            <router-link :to="'/Dataset/' + datasetID" class="download-button mh" style="text-decoration: none;">Go
                Back</router-link>

        </div>


    </div>

    <div v-else style="width:100%;height:100vh;background-color:transparent">

    </div> -->
</template>


<script setup>
import { getAuth, onAuthStateChanged } from "@firebase/auth";
import { onMounted } from "vue";
import {
  getFirestore,
  onSnapshot,
  collection,
  query,
} from "firebase/firestore";
import { ref, onUnmounted } from "vue";
import { app, brandImg, formatDate } from "@/configs.js";

var usID = ref("");
var usEmail = ref("");
let auth;
const isLoggedin = ref(false);
var emailVerified = ref("");
onMounted(() => {
  auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      isLoggedin.value = true;
      usID.value = user.uid;
      usEmail.value = user.email;
      emailVerified.value = user.emailVerified;
    } else {
      isLoggedin.value = false;
    }
    return {
      usID,
    };
  });
});
</script>


<script>
import { computed } from "vue";
import { useStore } from "vuex";

const db = getFirestore(app);

export default {
  setup() {
    const store = useStore();
    const cartStore = computed(() => store.state.storeModuleName.cartStore);
    const setCartStore = (value) => {
      store.commit("storeModuleName/setCartStore", value);
    };

    return {
      cartStore,
      setCartStore,
    };
  },
  data() {
    return {
      dataSources: ref([]),
      dataDetails: ref([]),
      users: ref([]),
      removalArr: [],
      storeFilterArray: {},
      filterArray: {},
      visibleBlueBoxes: {},
      previewVisible: false,
      retrievalSuccess: false,
      pushedHeaders: [],
      finalProduct: ref([]),
      editTableVisible: false,
    };
  },
  methods: {
    pushToPremium(i) {
      const index = this.pushedHeaders.indexOf(i);

      if (index === -1) {
        this.pushedHeaders.push(i);
      } else if (index !== -1) {
        this.pushedHeaders.splice(index, 1);
        delete this.filterArray[i];
        this.passIntoStoreFA();
      }
    },
    getUniqueValues(arr, key) {
      let uniqueValues = new Set();
      for (let i = 0; i < arr.length; i++) {
        uniqueValues.add(arr[i][key]);
      }
      return [...uniqueValues];
    },
    filterUpdateTrigger() {
      let result = {};
      let selectedUnderCode = document.querySelectorAll(
        'input[type="checkbox"].filterSelect:checked'
      );

      for (let select of selectedUnderCode) {
        if (result[select.name]) {
          result[select.name].push(select.value);
        } else {
          result[select.name] = [select.value];
        }
      }
      this.filterArray = result;
    },
    headers() {
      const headerSet = new Set();
      for (const row of this.table) {
        for (const key in row) {
          headerSet.add(key);
        }
      }
      return Array.from(headerSet);
    },
    filterArrayFunc(array, filter) {
      const filterKeys = Object.keys(filter);
      return array.filter((item) =>
        filterKeys.every((key) => filter[key].includes(String(item[key])))
      );
    },

    passIntoStoreFA() {
      this.storeFilterArray = this.filterArray;
    },
    toggleBlueBox(index) {
      // Toggle the visibility of the blue box for the clicked option
      this.visibleBlueBoxes[index] = !this.visibleBlueBoxes[index];
    },
    removeKeysFromObjects(arr, keys) {
      const modifiedArray = [];

      for (let i = 0; i < arr.length; i++) {
        const obj = arr[i];
        const modifiedObj = {};

        for (const key in obj) {
          if (!keys.includes(key)) {
            modifiedObj[key] = obj[key];
          }
        }

        modifiedArray.push(modifiedObj);
      }

      return modifiedArray;
    },

    async commitCart() {
      if (this.dataset.content?.premiumData) {
        try {
          await this.$store.commit(
            "storeModuleName/setCartStore",
            this.prepFinalProduct
          );
          this.$router.push("/Checkout");
        } catch (error) {
          console.log(error);
        }
      } else {
        alert("Feature not added yet...");
      }
    },
  },
  async mounted() {
    try {
      const bucketName = "siftee_datasets";
      let nameClear = decodeURIComponent(String(this.datasetID))
        .replaceAll("|", "_")
        .replaceAll(":", "_")
        .replaceAll("'", "_")
        .replaceAll("/", "_");
      const apiEndpoint = `https://www.googleapis.com/storage/v1/b/${bucketName}/o/${nameClear}.json`;
      console.log("API ENDPOINT: ", apiEndpoint);
      // console.log("dataset on load keys " + nameClear);
      const response = await fetch(apiEndpoint);
      const data = await response.json();

      // Process the single object directly
      const item = data;
      const fileResponse = await fetch(item.mediaLink);
      const fileContent = await fileResponse.json();
      const result = {
        name: item.name,
        content: fileContent,
      };

      this.dataSources = result;
      document.title = await result.content?.sourceHeader;
      // this.$store.commit('storeModuleName/setdataSourceTableBucket', this.dataSources);
    } catch (error) {
      console.log("Error fetching files:", error);
    }

    const userQuery = query(collection(db, "oceanUsers"));
    const liveUsers = onSnapshot(userQuery, (snapshot) => {
      this.users = snapshot.docs.map((doc) => {
        return {
          id: doc.id,
          emailRef: doc.data().emailRef,
          userID: doc.data().userID,
          username: doc.data().username,
          planRegistered: doc.data().planRegistered,
          referralKey: doc.data().referralKey,
          downloadedFiles: doc.data().downloadedFiles,
        };
      });
    });

    // const dataSourceQuery = query(collection(db, "dataSources"));
    // const livedataSources = onSnapshot(dataSourceQuery, (snapshot) => {
    //     this.dataDetails = snapshot.docs.map((doc) => {
    //         return {
    //             id: doc.id,
    //             score: doc.data().score,
    //             sourceCompany: doc.data().sourceCompany,
    //             sourceHeader: doc.data().sourceHeader,
    //             lastUpdated: doc.data().lastUpdated,
    //             downloadCount: doc.data().downloadCount,
    //             userVotes: doc.data().userVotes,
    //             costPerRow: doc.data().costPerRow,
    //             headersDelimited: doc.data().headersDelimited
    //         }
    //     });
    // })
    onUnmounted(liveUsers);
  },
  computed: {
    isTabletView() {
      return window.innerWidth >= 1024;
    },
    prepFinalProduct() {
      const obj = {};

      try {
        (obj["datasetID"] = this.datasetID),
          (obj["sourceCompany"] = this.dataset.content?.sourceCompany),
          (obj["sourceHeader"] = this.dataset.content?.sourceHeader),
          (obj["filtersApplied"] = this.storeFilterArray),
          (obj["costPerRow"] = this.dataset.content?.costPerRow),
          (obj["rowsPurchased"] = this.filterArrayFunc(
            this.table,
            this.storeFilterArray
          ).length);
      } catch (error) {
        console.log(`Prepping Object Failed With Error: `, error);
      }

      return obj;
    },
    mergedPremiumColumns() {
      const mArray = new Set([
        ...this.pushedHeaders,
        this.dataset.content?.premiumColumns,
      ]);
      return mArray;
    },
    setID() {
      let id = String(this.datasetID).split("_")[0];
      return id;
    },
    dataset() {
      return Object(this.dataSources);
    },
    table() {
      return this.removeKeysFromObjects(
        Object(this.dataset.content?.table),
        this.removalArr
      );
    },
    tableClone() {
      const tableSet = Object(this.dataSources[0]);
      return tableSet.content?.table;
    },
    tableLength() {
      const tableSet = Object(this.dataSources[0]);
      return tableSet.content?.table.length;
    },
  },
  props: ["datasetCompany", "datasetID"],
};
</script>

<style scoped>
.headerHover:hover {
  background-color: lightgray;
  color: white !important;
  cursor: pointer;
}
</style>
